import { telehealthTranscriptionGate } from "../../statsig/gates";
import { useAtomValue } from "jotai";
import { isRecordingAtom, participantCountAtom } from "../state";
import { useSendDataTrackMessageCallback } from "../messages/useSendDataTrackMessageCallback";
import { DataTrackMessageTypeEnum } from "../types";
import { useGate } from "statsig-react";
import { useGetTelehealthSessionInfo } from "../../hooks/useGetTelehealthSessionInfo";

export function useRecordingControl(
  providerShortId?: string,
  patientShortId?: string,
) {
  const { value: telehealthTranscriptionIsEnabled } = useGate(
    telehealthTranscriptionGate,
  );
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const appointmentShortId = data?.telehealthSessionInfo?.appointment?.shortId;
  const isRecording = useAtomValue(isRecordingAtom);
  const sendMessage = useSendDataTrackMessageCallback();
  const inSession = !!useAtomValue(participantCountAtom);
  const sendRecordingStatusUpdate = async (shouldRecord: boolean) => {
    sendMessage(DataTrackMessageTypeEnum.RECORDING_STATUS_UPDATED, {
      isRecording: shouldRecord,
    });
  };
  const isVisible =
    inSession && appointmentShortId && telehealthTranscriptionIsEnabled;

  return {
    isVisible,
    isRecording,
    sendRecordingStatusUpdate,
  };
}
