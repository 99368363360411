import { EndSessionButton } from "../components";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { useCompleteTwilioSessionCallback } from "./useCompleteTwilioSessionCallback";

export function CompleteSessionButton() {
  const completeSession = useCompleteTwilioSessionCallback();

  return (
    <EndSessionButton
      onClick={() => {
        sendLoggingEvents(TrackingEvents.PROVIDER_SESSION_COMPLETE);
        completeSession();
      }}
      aria-label="Complete session"
    >
      Complete Session
    </EndSessionButton>
  );
}
