import { twMerge } from "tailwind-merge";
import { Card } from "@grow-therapy-team/sprout-ui";
import { InputControlsWrapper as InputControls } from "../InputControls";
import { ProviderAvatarSectionWrapper as ProviderAvatarSection } from "../ProviderAvatarSection";
import { ProviderLoginWrapper as ProviderLogin } from "../ProviderLogin";

export function MinimalPageContent({ className }: { className?: string }) {
  return (
    <Card
      className={twMerge(
        "self-center sm:w-[30rem] z-10 sm:p-0 flex flex-col items-center w-full px-6 py-6 sm:px-12 sm:py-12 gap-8",
        className,
      )}
      data-testid="client-intro-state--control"
    >
      <ProviderAvatarSection />
      <InputControls className="flex flex-col items-center gap-8" />
      <ProviderLogin />
    </Card>
  );
}
