import { Page as IntroRoomPage } from "./introduction-room";
import { Page as WaitingRoomPage } from "./waiting-room";
import { Page as MeetingRoomPage } from "./meeting-room";
import { useAtomValue } from "jotai";
import {
  VisitorState,
  shouldShowStaleVersionToastAtom,
  visitorStateAtom,
} from "./state";
import { useInitVisitorGlobalDDLoggerContext } from "./useInitVisitorGlobalDDLoggerContext";
import { useInitVisitorUserTracking } from "./useInitVisitorUserTracking";
import { useDetectStaleVersionInterval } from "../components";
import { useAnnouncementToast } from "../components/announcements/useAnnouncementToast";
import { AppState, UserType } from "../types";
import { useParams } from "react-router-dom";
import { useGetPublicPatient } from "../useGetPublicPatientByShortId";
import { useGetPatientFormsByCustomerId } from "./useGetFormsByCustomerId";
import { useGetClientUserPaperwork } from "./measures/useGetClientUserPaperwork";
import { useShouldUseMICInfra } from "../hooks/measures/useShouldUseMICInfra";

function visitorStateToAppState(
  visitorState: VisitorState,
): AppState | undefined {
  switch (visitorState) {
    case VisitorState.LOADING:
    case VisitorState.WAITING:
      return AppState.WAITING;
    case VisitorState.MEETING:
      return AppState.MEETING;
    case VisitorState.INTRO:
      return AppState.INTRO;
    default:
      return undefined;
  }
}

function FormsPageEffect() {
  useGetPatientFormsByCustomerId();
  return null;
}

function MeasuresPageEffect() {
  useGetClientUserPaperwork();
  return null;
}

function PageEffects({ visitorState }: { visitorState: VisitorState }) {
  const { patientShortId } = useParams<{ patientShortId: string }>();
  useInitVisitorGlobalDDLoggerContext();
  useInitVisitorUserTracking();
  useDetectStaleVersionInterval(useAtomValue(shouldShowStaleVersionToastAtom));
  useAnnouncementToast({
    userType: UserType.CLIENT,
    appState: visitorStateToAppState(visitorState),
    userId: patientShortId,
  });
  useGetPublicPatient(patientShortId);

  const shouldUseMICInfra = useShouldUseMICInfra();
  return shouldUseMICInfra ? <MeasuresPageEffect /> : <FormsPageEffect />;
}

function PageContent({ visitorState }: { visitorState: VisitorState }) {
  switch (visitorState) {
    case VisitorState.WAITING:
    case VisitorState.LOADING:
      return <WaitingRoomPage />;
    case VisitorState.MEETING:
      return <MeetingRoomPage />;
    default:
      return <IntroRoomPage />;
  }
}

export function Page() {
  const visitorState = useAtomValue(visitorStateAtom);

  return (
    <>
      <PageEffects visitorState={visitorState} />
      <PageContent visitorState={visitorState} />
    </>
  );
}
