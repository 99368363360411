import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLayerWithExposureLoggingDisabled } from "statsig-react";
import {
  Navbar,
  PageContainer,
  PushDrawerContainer,
  StageContainer,
  ThemeProvider,
} from "../../components";
import { logger } from "../../datadog";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { useGetTelehealthSessionInfo } from "../../hooks";
import { useInactiveTabPerformanceOptimization } from "../../hooks/useInactiveTabPerformanceOptimization";
import { useOnInactiveMobileScreenDetected } from "../../hooks/useOnInactiveMobileScreenDetected";
import { useWaitingRoomTrackingEvent } from "../../hooks/useWaitingRoomTrackingEvent";
import { EventTypeEnum } from "../../segment/types";
import { Theme } from "../../state";
import { ExperimentLayers } from "../../statsig/experiments";
import {
  AudioVideoControlsWrapper as VideoControls,
  useHandleDeviceChanges,
  useInitLocalTracks,
} from "../../twilio";
import { DeviceType } from "../../types";
import { getDeviceType } from "../../utils";
import { AdditionalOptionsMenuWrapper as AdditionalOptionsMenu } from "../AdditionalOptionsMenu";
import { HelpModalWrapper as HelpModal } from "../HelpModal";
import { PushDrawerWrapper as PushDrawer } from "../PushDrawer";
import { SelfVideoThumbnail } from "../SelfVideoThumbnail";
import { ChatDrawerButtonWrapper as ChatDrawerButton } from "../chat/ChatDrawerButton";
import {
  VisitorDrawerState,
  visitorDrawerStateAtom,
  visitorIsHelpModalOpenAtom,
  visitorFormData,
  visitorMeasuresAtom,
} from "../state";
import { useInitTwilioSyncClient } from "../sync/useInitTwilioSyncClient";
import { BackgroundAnimation } from "./BackgroundAnimation";
import { ProviderInfoSectionWrapper as ProviderInfoSection } from "./ProviderInfoSection";
import { ProviderNotHereModalWrapper as ProviderNotHereModal } from "./ProviderNotHereModal";
import { RoomLoaderWrapper as RoomLoader } from "./RoomLoader";
import { SessionInfoSectionWrapper as SessionInfoSection } from "./SessionInfoSection";
import { useInitVisitorChatAccess, useWaitingRoomConversation } from "./chat";
import { useClearProviderNoShowTabTitleCallback } from "./useClearProviderNoShowTabTitle";
import { useResetUserStateCallback } from "./useResetUserStateCallback";
import { useUpdateTitleOnProviderPastNoShowThreshold } from "./useUpdateTitleOnProviderPastNoShowThreshold";
import { useUpdateVisitorPresenceInterval } from "./useUpdateVisitorPresenceInterval";
import { useWaitingRoomTimeout } from "./useWaitingRoomTimeout";
import { useWatchForTwilioRoomToken } from "./useWatchForTwilioRoomToken";
import { usePreSessionPromptState } from "./usePreSessionPromptState";
import { useShouldUseMICInfra } from "../../hooks/measures/useShouldUseMICInfra";

function PageEffects() {
  const [layerName, layerConfig] = ExperimentLayers.ClientWaitingRoom;
  const { layer } = useLayerWithExposureLoggingDisabled(layerName);
  const shouldShowAVSetup = layer.get<boolean>(
    layerConfig.ShouldShowAvSetup,
    false,
  );

  const resetUserState = useResetUserStateCallback();
  useUpdateVisitorPresenceInterval();
  useInitTwilioSyncClient();
  const { clearPreSessionPrompt } = usePreSessionPromptState();
  useWatchForTwilioRoomToken({ onDone: clearPreSessionPrompt });
  useInitLocalTracks(!shouldShowAVSetup);
  useHandleDeviceChanges();
  useWaitingRoomTimeout();

  useInitVisitorChatAccess();
  useWaitingRoomConversation();
  useOnInactiveMobileScreenDetected(() => {
    resetUserState();
    logger.info("Returned to intro room due to inactive screen");
  });
  useUpdateTitleOnProviderPastNoShowThreshold();
  useInactiveTabPerformanceOptimization();
  const { providerShortId, patientShortId } = useParams();

  useWaitingRoomTrackingEvent(
    EventTypeEnum.CLIENT_WAITING_ROOM,
    providerShortId,
    patientShortId,
  );

  return null;
}

export function Page() {
  const [tabSwitchingDisabled, setTabSwitchingDisabled] = useState(false);

  const [isHelpModalOpen, setIsHelpModalOpen] = useAtom(
    visitorIsHelpModalOpenAtom,
  );

  const clearProviderNoShowTabTitle = useClearProviderNoShowTabTitleCallback();
  const { providerShortId, patientShortId } = useParams();
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);

  const appointment = data?.telehealthSessionInfo?.appointment;
  const [visitorDrawerState, setVisitorDrawerState] = useAtom(
    visitorDrawerStateAtom,
  );

  const visitorForms = useAtomValue(visitorFormData);
  const visitorMeasures = useAtomValue(visitorMeasuresAtom);
  const shouldUseMICInfra = useShouldUseMICInfra();

  const hasFormData = shouldUseMICInfra
    ? !!visitorMeasures?.length
    : !!visitorForms?.providerForms?.length;

  const toggleDrawerState = (drawer: VisitorDrawerState): void => {
    if (visitorDrawerState === drawer) {
      setVisitorDrawerState(null);
      return;
    }
    setVisitorDrawerState(drawer);
  };

  useEffect(() => {
    // it's a logic leap here to assume that device type mobile means we are
    // changing the tab switching behavior, so please keep an eye on:
    // useOnInactiveMobileScreenDetected, specifically, "visibilitychange" event
    // handler
    setTabSwitchingDisabled(getDeviceType() === DeviceType.Mobile);
  }, []);

  return (
    <>
      <PageEffects />
      <ThemeProvider theme={Theme.LIGHT}>
        <HelpModal
          isOpen={isHelpModalOpen}
          onClose={() => {
            setIsHelpModalOpen(false);
            clearProviderNoShowTabTitle();
          }}
          shouldShowProviderNoShowSection
          appointment={appointment}
        />
        <ProviderNotHereModal />
        <PageContainer>
          <Navbar
            onClickSettingsButton={() =>
              toggleDrawerState(VisitorDrawerState.SETTINGS)
            }
          />
          <PushDrawerContainer drawer={<PushDrawer />}>
            <StageContainer className="gap-y-0 @container">
              <BackgroundAnimation isVisibleOnMobile={false}>
                <ProviderInfoSection />
                <div className="flex flex-col gap-y-8 justify-center items-center md:h-full">
                  <SessionInfoSection
                    appointment={appointment}
                    toggleHelpfulTipsDrawer={(drawerState) =>
                      toggleDrawerState(drawerState)
                    }
                    setIsHelpModalOpen={setIsHelpModalOpen}
                    showTabSwitchWarning={tabSwitchingDisabled}
                    hasForms={hasFormData}
                  />
                  <SelfVideoThumbnail
                    className="@sm:hidden relative self-center mt-2 mb-6 sm:mt-10 sm:mb-10"
                    videoClassName="min-h-[12rem] sm:min-h-[14rem] max-h-[18rem] min-w-[calc(100vw-2rem)] sm:min-w-[25rem] max-w-screen-sm"
                  />
                </div>
              </BackgroundAnimation>
              <SelfVideoThumbnail className="hidden @sm:flex absolute top-4 right-0" />
            </StageContainer>
          </PushDrawerContainer>
          <VideoControls
            chatButtonComponent={<ChatDrawerButton />}
            onOpenHelpModal={() => {
              sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
              sendLoggingEvents(TrackingEvents.CLIENT_HELP_REQUEST);
              setIsHelpModalOpen(true);
            }}
            additionalOptionsComponent={
              <AdditionalOptionsMenu
                onClickSettingsButton={() =>
                  toggleDrawerState(VisitorDrawerState.SETTINGS)
                }
              />
            }
          />
          <RoomLoader />
        </PageContainer>
      </ThemeProvider>
    </>
  );
}
