import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import BrowserHelp from "../assets/browser-help.svg?react";
import BrowserHelpMobile from "../assets/browser-help-mobile.svg?react";
import { NoAccessVariant } from "../twilio/audio-video-controls/NoAccessToast";

export function BlockedPermissionDescription({
  isMobile,
  noAccessVariant,
}: {
  isMobile: boolean;
  noAccessVariant?: NoAccessVariant;
}) {
  return isMobile ? (
    <>
      <Text
        className="font-semibold rebrand:font-normal text-neutral-600 rebrand:text-neutral-800"
        data-testid="blocked-permissions.mobile-instructions-section"
        variant="md"
      >
        Your browser doesn&apos;t have access to your{" "}
        {noAccessVariant ? noAccessVariant : "camera and microphone"}. Allow
        access by selecting the{" "}
        <Icon name="video" size="xs" aria-label="video" variant="solid" /> icon
        or <Icon name="lock" size="xs" aria-label="lock" variant="solid" /> icon
        in the corner.
      </Text>
      <BrowserHelpMobile
        className="shrink-0 justify-self-center w-full h-fit"
        data-testid="blocked-permissions.mobile-help-img"
        title="Mobile Browser Permissions"
      />
      <section data-testid="blocked-permissions.additional-info-section">
        <Text as="h3" className="font-semibold rebrand:font-medium">
          If that didn&apos;t work:
        </Text>
        <ul className="list-disc ml-4">
          <li>
            <Text
              variant="md"
              className="font-semibold rebrand:font-normal text-neutral-600 mb-4 rebrand:text-neutral-800"
            >
              <Text
                className="text-ivy-500 rebrand:font-medium font-semibold"
                as="span"
                variant="md"
              >
                For iPhone:
              </Text>{" "}
              open your device&apos;s Settings {">"} Privacy & Security {">"}{" "}
              Camera and/or Microphone {">"} turn on access for your browser.
            </Text>
          </li>
          <li>
            <Text
              variant="md"
              className="font-semibold rebrand:font-normal text-neutral-600 rebrand:text-neutral-800"
            >
              <Text
                className="text-ivy-500 inline rebrand:font-medium font-semibold"
                as="span"
                variant="md"
              >
                For Android:
              </Text>{" "}
              open your device&apos;s Settings {">"} Security & Privacy {">"}{" "}
              Privacy {">"} Permission manager {">"} allow camera and microphone
              permissions to the app.
            </Text>
          </li>
        </ul>
      </section>
    </>
  ) : (
    <>
      <Text
        className="font-semibold rebrand:font-normal text-neutral-600 rebrand:text-medium rebrand:text-neutral-800"
        data-testid="blocked-permissions.instructions-section"
        variant="md"
      >
        Your browser doesn’t have access to your{" "}
        {noAccessVariant ? noAccessVariant : "camera and microphone"}. Allow
        access by selecting the <Icon name="lock" size="xs" aria-label="lock" />{" "}
        icon next to the address bar:
      </Text>
      <BrowserHelp
        className="shrink-0 self-center w-full h-fit"
        data-testid="blocked-permissions.help-img"
        title="Browser Permissions"
      />
    </>
  );
}
