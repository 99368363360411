import { useCallback } from "react";
import {
  DataTrackMessageTypeEnum,
  DataTrackMessage,
  ConsentStatus,
} from "../../twilio/types";
import { Mutex } from "async-mutex";
import { useSetAtom } from "jotai";
import { isRecordingAtom } from "../../twilio";
import {
  patientTranscriptionConsentAtom,
  providerTranscriptionConsentAtom,
  showTranscriptionConsentModalAtom,
} from "../session-recording/state";

export type MessageEventListeners = {
  [DataTrackMessageTypeEnum.RECORDING_STATUS_UPDATED]: (
    message: DataTrackMessage,
  ) => void;
};

const handleReceivedMessageMutex = new Mutex();

/**
 * Returns a callback that handles received messages on the data track for the
 * client
 */
export function useHandleReceivedMessageCallback() {
  const setIsRecording = useSetAtom(isRecordingAtom);
  const setIsTranscriptionConsentModalOpen = useSetAtom(
    showTranscriptionConsentModalAtom,
  );
  const setProviderTranscriptionConsent = useSetAtom(
    providerTranscriptionConsentAtom,
  );
  const setClientTranscriptionConsent = useSetAtom(
    patientTranscriptionConsentAtom,
  );

  return useCallback(
    (message: DataTrackMessage) => {
      return handleReceivedMessageMutex.runExclusive(async () => {
        switch (message.messageType) {
          case DataTrackMessageTypeEnum.RECORDING_STATUS_UPDATED:
            if (message.details.isRecording) {
              setIsRecording(true);
            } else {
              setIsRecording(false);
            }
            break;
          case DataTrackMessageTypeEnum.PROVIDER_TRANSCRIPTION_CONSENT_UPDATED:
            if (
              message.details.providerConsent === ConsentStatus.OPTED_IN &&
              message.details.requestClientConsent
            ) {
              setIsTranscriptionConsentModalOpen(true);
            }
            setProviderTranscriptionConsent(message.details.providerConsent);
            break;
          case DataTrackMessageTypeEnum.CLIENT_TRANSCRIPTION_CONSENT_UPDATED:
            setClientTranscriptionConsent(message.details.clientConsent);
            break;
          default:
            break;
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
