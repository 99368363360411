import { Button, ButtonSize, Icon, Text } from "@grow-therapy-team/sprout-ui";
import { noop } from "../utils";
import { Toast } from "../components";
import { useAtomValue } from "jotai";
import {
  inSessionPatientInformationAtom,
  joiningVisitorIdFamily,
  providerShortIdAtom,
  waitingVisitorsAtom,
} from "./state";
import { useEffect } from "react";
import { VisitorPresence } from "../types";
import { useGetTelehealthSessionInfo } from "../hooks";
import {
  AppointmentTime,
  type AppointmentTimeProps,
} from "../components/AppointmentTime";
import { ClientMoodWrapper as ClientMood } from "./client-information/ClientMood";

type ClientQueueToastProps = {
  appointment?: AppointmentTimeProps["appointment"];
  buttonIsLoading?: boolean;
  className?: string;
  clientMoodComponent?: typeof ClientMood;
  currentSessionPatientShortId: VisitorPresence["patientShortId"] | null;
  isAppointmentLoading?: AppointmentTimeProps["isLoading"];
  onAdmit?: () => void;
  onClose: () => void;
  onOpenQueue: () => void;
  /**
   * unlike onAdmit, this callback is required to avoid a funky edge-case when a
   * client with a different shortId joins the waiting room while the provider
   * is in a session with another client. if this becomes too cumbersome,
   * consider using onOpenQueue when the client is in a session with another
   * client
   */
  onShowStartNewSessionConfirmation: () => void;
  participantName: string;
  patientShortId: VisitorPresence["patientShortId"];
  visitorUuid?: string;
};

export function ClientQueueToast({
  appointment,
  isAppointmentLoading,
  buttonIsLoading,
  className,
  clientMoodComponent: ClientMoodComponent,
  currentSessionPatientShortId,
  onAdmit,
  onClose = noop,
  onOpenQueue = noop,
  onShowStartNewSessionConfirmation,
  participantName,
  patientShortId,
  visitorUuid,
}: ClientQueueToastProps) {
  const verbVariant = onAdmit ? "has" : "have";
  const isRequestForNewSession =
    !!currentSessionPatientShortId &&
    currentSessionPatientShortId !== patientShortId;

  const openQueue = () => {
    onOpenQueue();
    onClose();
  };

  const onButtonClick = () => {
    // if a new session has been requested (e.g., patientShortId is not the
    // same as the current session patientShortId), show a confirmation
    if (isRequestForNewSession) {
      onShowStartNewSessionConfirmation();
      return;
    }

    if (onAdmit) {
      onAdmit();
      return;
    }

    openQueue();
  };

  return (
    <Toast
      data-testid="client-queue-toast"
      className={className}
      onClose={onClose}
      isBanner
    >
      <div className="flex justify-between items-center w-full">
        <button
          className="flex items-center"
          onClick={openQueue}
          aria-label="Open client queue"
        >
          <Icon
            variant="solid"
            name="user"
            className="text-neutral-800 p-4 bg-lilac-100 rounded-full mr-4"
          />

          <div className="flex flex-col">
            <Text
              variant="sm"
              className="text-left fs-exclude"
              data-dd-privacy="mask"
              data-dd-action-name="Click on queue toast title"
            >
              {participantName} {verbVariant} joined your waiting room
            </Text>
            {isRequestForNewSession && (
              <AppointmentTime
                appointment={appointment}
                data-dd-action-name="appointment time"
                isLoading={isAppointmentLoading}
              />
            )}
            {!!ClientMoodComponent && (
              <ClientMoodComponent visitorUuid={visitorUuid} />
            )}
          </div>
        </button>
        <Button
          loading={buttonIsLoading}
          size={ButtonSize.Small}
          onClick={onButtonClick}
          className="max-h-[2.25rem]"
        >
          {onAdmit ? "Admit" : "View"}
        </Button>
      </div>
    </Toast>
  );
}

type WrapperProps = Omit<
  ClientQueueToastProps,
  | "appointment"
  | "isAppointmentLoading"
  | "buttonIsLoading"
  | "clientNameAnnotation"
  | "currentSessionPatientShortId"
>;
export function ClientQueueToastWrapper(props: WrapperProps) {
  const { patientShortId, visitorUuid, onClose } = props;
  const isWaitingForClientToJoin = useAtomValue(
    joiningVisitorIdFamily(visitorUuid ?? ""),
  );
  const visitorPresenceMap = useAtomValue(waitingVisitorsAtom);
  const providerShortId = useAtomValue(providerShortIdAtom);
  const { patientShortId: currentSessionPatientShortId = null } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};

  const { data, loading } = useGetTelehealthSessionInfo(
    providerShortId,
    patientShortId,
  );
  const appointment = data?.telehealthSessionInfo?.appointment;

  useEffect(() => {
    // Dismiss the toast if the visitor has left the waiting room OR in the case of a multi-visitor generic toast, if all visitors have left
    if (
      (visitorUuid && !visitorPresenceMap[visitorUuid]) ||
      (!visitorUuid && !Object.keys(visitorPresenceMap).length)
    ) {
      onClose();
    }
  }, [onClose, visitorPresenceMap, visitorUuid]);

  return (
    <ClientQueueToast
      {...props}
      buttonIsLoading={isWaitingForClientToJoin}
      clientMoodComponent={ClientMood}
      currentSessionPatientShortId={currentSessionPatientShortId}
      appointment={appointment}
      isAppointmentLoading={loading}
    />
  );
}
