import { Button, ButtonUse, MICUtils } from "@grow-therapy-team/sprout-ui";
import { PageComponentProps } from "../../components";
import { useState } from "react";
import { MeasureSchemaAndAnswers } from "./types";

const SUBMIT_BUTTON = "Submit";
const NEXT_BUTTON = "Next";
const PREVIOUS_BUTTON = "Previous";

export function MeasuresActions({
  submitMeasure,
  ...props
}: PageComponentProps<MeasureSchemaAndAnswers> & {
  submitMeasure: (
    props: PageComponentProps<MeasureSchemaAndAnswers>,
  ) => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);

  const onNextPage = async () => {
    // First validate the form
    const { answers, schema } = props.page;
    const errors = MICUtils.validateMeasure(answers, schema);
    if (errors && errors.length > 0) {
      props.setPage((prev) => ({
        ...prev,
        errors,
      }));

      const firstError = props.page.errors[0];
      document
        .getElementById(firstError)
        ?.scrollIntoView({ behavior: "smooth" });

      return;
    }

    // Submit the form and update loading state
    setLoading(true);
    await submitMeasure(props);
    setLoading(false);

    // Advance to the next form
    if (props.isLastPage) {
      return;
    }

    props.setPage((prev) => ({ ...prev, errors: [], hasSubmitted: true }));
    props.nextPage();
    props.scrollToTop();
  };

  return (
    <>
      {!props.isFirstPage && (
        <Button
          onClick={props.prevPage}
          use={ButtonUse.Secondary}
          disabled={loading}
        >
          {PREVIOUS_BUTTON}
        </Button>
      )}
      <Button onClick={onNextPage} loading={loading} disabled={loading}>
        {props.isLastPage ? SUBMIT_BUTTON : NEXT_BUTTON}
      </Button>
    </>
  );
}
