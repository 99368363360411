import { PaginatedDrawer, PaginatedDrawerProps } from "..";
import { type MeasureSchemaAndAnswers } from "./types";

const HEADER = "Completing measure";

export function MeasuresDrawer(
  props: Omit<
    PaginatedDrawerProps<MeasureSchemaAndAnswers>,
    "headerComponent" | "contentOnly"
  >,
) {
  return (
    <PaginatedDrawer {...props} headerComponent={() => HEADER} contentOnly />
  );
}
