import {
  StatsigProvider as BaseStatsigProvider,
  StatsigProviderProps as BaseStatsigProviderProps,
} from "../statsig/StatsigProvider";
import { useCallback, useEffect } from "react";
import { providerShortIdAtom } from "./state";
import { useAtomCallback } from "jotai/utils";
import { logger } from "../datadog/logger";
import { UserType } from "../types";
import { loadSegment } from "../segment/segment";

export function StatsigProvider({
  children,
}: Pick<BaseStatsigProviderProps, "children">) {
  // Callback used here to enforce that the provider short ID should be stable
  // before this component is rendered
  const providerShortId = useAtomCallback(
    useCallback((get) => get(providerShortIdAtom), []),
  )();

  useEffect(() => {
    if (!providerShortId) {
      logger.warn("Provider short ID is missing");
    } else {
      loadSegment(providerShortId);
    }
  }, [providerShortId]);

  return (
    <BaseStatsigProvider
      user={{
        userID: providerShortId,
        custom: {
          userType: UserType.PROVIDER,
        },
      }}
    >
      {children}
    </BaseStatsigProvider>
  );
}
