import { AdditionalOptionsMenu } from "../components/AdditionalOptionsMenu";
import { useChatDrawerButtonUtils } from "./chat/useChatDrawerButtonUtils";

export function AdditionalOptionsMenuWrapper({
  onClickSettingsButton,
}: {
  onClickSettingsButton: () => void;
}) {
  const { unreadMessagesCount, toggleChatDrawer, isChatDrawerDisabled } =
    useChatDrawerButtonUtils();

  return (
    <AdditionalOptionsMenu
      onClickSettingsButton={onClickSettingsButton}
      unreadMessageCount={unreadMessagesCount}
      isChatButtonDisabled={isChatDrawerDisabled}
      onClickChatButton={toggleChatDrawer}
    />
  );
}
