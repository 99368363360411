import { Tabs, Tab, Icon, DrawerBody } from "@grow-therapy-team/sprout-ui";
import { useState } from "react";
import { DrawerHeader } from "../DrawerHeader";

export enum SettingsDrawerTab {
  VIDEO = "video",
  AUDIO = "audio",
  BACKGROUND = "background",
}

export function SettingsDrawer({
  tabMap,
}: {
  tabMap: Record<SettingsDrawerTab, JSX.Element>;
}) {
  const [selectedTab, setSelectedTab] = useState<SettingsDrawerTab>(
    SettingsDrawerTab.VIDEO,
  );

  return (
    <>
      <DrawerHeader>Settings</DrawerHeader>
      <DrawerBody>
        <Tabs
          selectedTabId={selectedTab}
          onSelectTab={(newTab: SettingsDrawerTab) => {
            setSelectedTab(newTab);
          }}
          className="w-full overflow-x-scroll no-scrollbar flex gap-2"
        >
          <Tab tabId="video" className="whitespace-nowrap">
            <Icon variant="solid" name="video" className="mr-2" />
            Video
          </Tab>
          <Tab tabId="audio" className="whitespace-nowrap">
            <Icon variant="solid" name="microphone" className="mr-2" />
            Audio
          </Tab>
          <Tab tabId="background" className="whitespace-nowrap">
            <Icon variant="solid" name="image-user" className="mr-2" />
            Background
          </Tab>
        </Tabs>
        {tabMap[selectedTab]}
      </DrawerBody>
    </>
  );
}
