import { Icon, IconButton, tailwindMerge } from "@grow-therapy-team/sprout-ui";
import { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import { usePrevious } from "react-use";
import {
  ErrorBoundary as BaseErrorBoundary,
  FallbackProps,
} from "react-error-boundary";
import { logger } from "../datadog/logger";
import { Toast, ToastVariant } from "./Toast";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useTheme } from "../hooks";
import { twMerge } from "tailwind-merge";
import { Theme } from "../state";

function FallbackComponent({ error }: FallbackProps) {
  useEffect(
    function logError() {
      logger.error(
        "The push drawer crashed after encountering an unexpected error; displaying fallback",
        error,
      );
    },
    [error],
  );

  return (
    <div
      className="pointer-events-none relative overflow-hidden flex flex-col w-full h-full pt-24 items-center"
      data-testid="push-drawer.fallback"
    >
      <Toast
        className="bg-transparent rebrand:bg-transparent mb-4"
        data-testid="push-drawer.fallback.error-message"
        variant={ToastVariant.Neutral}
      >
        <span>
          <Icon
            className="text-coral-600 mr-4"
            variant="solid"
            name="warning"
          />
          Something went wrong. Please try again later.
        </span>
      </Toast>
    </div>
  );
}

function ErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <BaseErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </BaseErrorBoundary>
  );
}

export function PushDrawer({
  isOpen = true,
  onClose,
  children,
  placement = "right",
  className,
}: {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  className?: string;
  placement?: "left" | "right";
}) {
  const [shouldRender, setShouldRender] = useState(isOpen);
  const prevChildren = usePrevious(children);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(isOpen);
    }
  }, [isOpen]);

  if (!shouldRender) {
    return <></>;
  }

  return (
    <div
      className={tailwindMerge(
        classNames(
          "shrink border rebrand:border-neutral_rebrand-800 border-neutral-400 rebrand:bg-neutral-300 bg-neutral-000 flex flex-col", // general styling regardless of screen size
          "max-sm:z-20 max-sm:absolute top-0 bottom-0 max-h-full h-screen w-screen", // mobile styling
          "rebrand:sm:top-3 rebrand:sm:bottom-3 sm:rounded-xl sm:h-full sm:max-w-[30rem]", // larger than mobile styling
          {
            "animate-slide-up sm:animate-slide-in-right right-0 rebrand:sm:right-3":
              isOpen && placement === "right",
            "animate-slide-down sm:animate-slide-out-left":
              !isOpen && placement === "right",
            "animate-slide-up sm:animate-slide-in-left left-0 rebrand:left-3":
              isOpen && placement === "left",
            "animate-slide-down sm:animate-slide-out-right":
              !isOpen && placement === "left",
            "transition-all max-sm:opacity-5 sm:w-0": !isOpen,
          },
          className,
        ),
      )}
      onAnimationEnd={(): void => {
        if (!isOpen) {
          setShouldRender(false);
        }
      }}
    >
      {onClose && (
        <IconButton
          aria-label="Close drawer"
          iconDefinition={faXmark}
          onClick={onClose}
          className={classNames(
            "absolute right-0 my-4 mx-6 h-8 w-8 flex-none items-center justify-center sm:my-6 sm:mx-8 md:mx-10 md:my-8",
            {
              hidden: !isOpen,
            },
          )}
        />
      )}
      <ErrorBoundary>{isOpen ? children : prevChildren}</ErrorBoundary>
    </div>
  );
}

export function PushDrawerContainer({
  drawer,
  children,
  className,
}: {
  drawer: ReactNode;
  children?: ReactNode;
  className?: string;
}) {
  const { theme } = useTheme();
  return (
    <div
      className={classNames(
        { "border-t border-b": theme === Theme.LIGHT },
        "flex flex-row overflow-hidden h-full w-full",
        className,
      )}
    >
      {children}
      <div
        className={twMerge(
          classNames({
            "sm:m-4": theme === Theme.LIGHT,
            "sm:mx-2": theme === Theme.DARK,
          }),
        )}
      >
        {drawer}
      </div>
    </div>
  );
}
