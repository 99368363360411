import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { Toast, ToastProps, ToastVariant } from "../../components";

export function ConnectionErrorToast(props: Omit<ToastProps, "children">) {
  return (
    <Toast
      className="top-center-toast sm:max-w-[41.5rem]"
      variant={ToastVariant.Neutral}
      data-testid="connection-error-toast"
      {...props}
    >
      <Icon className="mr-3 text-coral-600" variant="solid" name="warning" />
      <Text variant="sm">
        Unfortunately, we can&apos;t connect you. Please try refreshing. If the
        issue persists, please contact support via the provider portal.
      </Text>
    </Toast>
  );
}
