import {
  Button,
  ButtonSize,
  ButtonUse,
  Icon,
} from "@grow-therapy-team/sprout-ui";
import { BackgroundAnimationOverlay } from "../../components";
import { LottieRef } from "lottie-react";
import { PropsWithChildren, useReducer, useRef } from "react";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

enum ActionType {
  PLAY = "play",
  PAUSE = "pause",
}

function animationStateReducer(
  state: {
    nextAction: ActionType;
    lottieRef: LottieRef;
  },
  action: ActionType,
) {
  const { lottieRef } = state;
  if (!lottieRef.current || !lottieRef.current?.animationItem) return state;
  const animationItem = lottieRef.current.animationItem;
  switch (action) {
    case ActionType.PLAY:
      animationItem.play();
      return { ...state, nextAction: ActionType.PAUSE };
    case ActionType.PAUSE:
      animationItem.pause();
      return { ...state, nextAction: ActionType.PLAY };
  }
}

export function PausePlayAnimationButton({
  className,
  nextAction,
  dispatch,
}: {
  className?: string;
  nextAction: ActionType;
  dispatch: (action: ActionType) => void;
}): JSX.Element {
  const capitalizedNextAction = `${nextAction
    .charAt(0)
    .toUpperCase()}${nextAction.substring(1)}`;
  return (
    <div
      className={twMerge(
        "flex justify-center m-3 sm:fixed sm:bottom-0 sm:right-0",
        className,
      )}
    >
      <Button
        use={ButtonUse.Link}
        size={ButtonSize.Small}
        className="flex gap-x-2 items-center text-marigold-700 no-underline hover:brightness-75 hover:text-marigold-700 "
        onClick={() => dispatch(nextAction)}
      >
        <Icon variant="solid" name={nextAction} />
        {capitalizedNextAction} animation
      </Button>
    </div>
  );
}

export function BackgroundAnimation({
  children,
  isVisibleOnMobile = true, // Mobile === xs breakpoints in this case
}: PropsWithChildren<{ isVisibleOnMobile?: boolean }>) {
  const lottieRef = useRef(null);
  const [{ nextAction }, dispatch] = useReducer(animationStateReducer, {
    lottieRef,
    nextAction: ActionType.PAUSE,
  });
  const currentAnimationState =
    nextAction === ActionType.PLAY ? "paused" : "playing";

  const visibilityClassName = classNames({
    "invisible sm:visible": isVisibleOnMobile,
  });

  return (
    <>
      <BackgroundAnimationOverlay
        className={visibilityClassName}
        data-animation-state={currentAnimationState}
        lottieRef={lottieRef}
      />
      {/* "relative" to ensure the content is rendered above the animation */}
      <div className="relative flex flex-col overflow-y-auto h-full w-[calc(100%+0.2rem)]">
        {children}
        <PausePlayAnimationButton
          nextAction={nextAction}
          dispatch={dispatch}
          className={visibilityClassName}
        />
      </div>
    </>
  );
}
