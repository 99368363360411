import {
  Button,
  ButtonUse,
  Heading,
  Menu,
  MenuItem,
  MenuToggleButton,
  Tag,
  TagUse,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { useAtomValue, useSetAtom } from "jotai";
import { ClientActionsMenu } from "./ClientActionsMenu";
import {
  inSessionPatientInformationAtom,
  joiningVisitorIdFamily,
  newSessionConfirmationDataAtom,
  providerShortIdAtom,
  waitingRoomConversationSidsByVisitorIdAtom,
  waitingRoomConversationSidsByVisitorIdFamily,
  waitingVisitorsAtom,
} from "../state";
import { openWaitingRoomConversation } from "./openWaitingRoomConversation";
import { useOpenClientInformationCallback } from "../client-information/useOpenClientInformationCallback";
import { VisitorPresence, VisitorPresenceMap } from "../../types";
import { ClientActions } from "./ClientActions";
import { participantCountAtom, unreadMessagesCountFamily } from "../../twilio";
import { useAdmitClientCallback } from "./useAdmitClientCallback";
import { useAtomCallback } from "jotai/utils";
import { ReactNode, useCallback } from "react";

import { useOpenConversationCallback } from "../chat/useOpenConversationCallback";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import {
  AppointmentTime,
  type AppointmentTimeProps,
} from "../../components/AppointmentTime";
import { useGetTelehealthSessionInfo } from "../../hooks";
import { AutomaticallyTransferringTextWrapper as AutomaticallyTransferringText } from "../delayed-entry/AutomaticallyTransferringText";
import { useGate } from "statsig-react";
import { delayedEntryGate } from "../../statsig/gates";
import { hasNewFormsAtomFamily } from "../client-information/state";
import { ClientMoodWrapper as ClientMood } from "../client-information/ClientMood";

type ClientQueueActionsProps = {
  appointment?: AppointmentTimeProps["appointment"];
  clientNameAnnotation?: ReactNode;
  currentSessionPatientShortId: VisitorPresence["patientShortId"] | null;
  inSession?: boolean;
  isAppointmentLoading?: AppointmentTimeProps["isLoading"];
  isConversationLoading?: boolean;
  isClientInformationLoading?: boolean;
  loading?: boolean;
  onChat?: () => void;
  onOpenClientInformationDrawer: () => void;
  onAdmit: () => void;
  onShowStartNewSessionConfirmation: () => void;
  unreadMessagesCount?: number;
  visitorName: string;
  visitorUuid: string;
  patientShortId: string;
  hideAdmitButton: boolean;
};

type ClientQueueActionButtonsProps = Pick<
  ClientQueueActionsProps,
  | "currentSessionPatientShortId"
  | "inSession"
  | "loading"
  | "onAdmit"
  | "onShowStartNewSessionConfirmation"
  | "patientShortId"
  | "visitorName"
>;
function ClientQueueActionButtons({
  currentSessionPatientShortId,
  inSession,
  loading,
  onAdmit,
  onShowStartNewSessionConfirmation,
  patientShortId,
  visitorName,
}: ClientQueueActionButtonsProps) {
  const isRequestForNewSession =
    !!currentSessionPatientShortId &&
    currentSessionPatientShortId !== patientShortId;

  if (isRequestForNewSession) {
    return (
      <Button
        data-testid={`client-actions.admit:${visitorName}.end-and-start-new-session`}
        loading={loading}
        onClick={onShowStartNewSessionConfirmation}
      >
        Admit
      </Button>
    );
  }

  if (inSession) {
    return (
      <Menu
        placement="bottom-left"
        buttonClassName="py-3 h-12"
        toggleButton={
          <MenuToggleButton
            data-testid={`client-actions.menu:${visitorName}`}
            use={ButtonUse.Primary}
            loading={loading}
          >
            Admit
          </MenuToggleButton>
        }
      >
        <MenuItem
          data-testid={`client-actions.menu:${visitorName}.admit-to-current-session`}
          onClick={() => {
            onAdmit();
            sendLoggingEvents(TrackingEvents.PROVIDER_ADMIT_CLIENT);
          }}
        >
          Admit to current session
        </MenuItem>
        <MenuItem
          data-testid={`client-actions.menu:${visitorName}.end-and-start-new-session`}
          onClick={() => onShowStartNewSessionConfirmation()}
        >
          End and start new session
        </MenuItem>
      </Menu>
    );
  }

  return (
    <Button
      data-testid={`client-actions.admit:${visitorName}`}
      loading={loading}
      onClick={onAdmit}
    >
      Admit
    </Button>
  );
}

export function ClientQueueActions({
  appointment,
  clientNameAnnotation: providedClientNameAnnotation,
  currentSessionPatientShortId,
  inSession,
  isAppointmentLoading,
  isConversationLoading,
  loading,
  onChat,
  onOpenClientInformationDrawer,
  onAdmit,
  onShowStartNewSessionConfirmation,
  patientShortId,
  unreadMessagesCount,
  visitorName,
  visitorUuid,
  hideAdmitButton,
}: ClientQueueActionsProps) {
  const isRequestForNewSession =
    !!currentSessionPatientShortId &&
    currentSessionPatientShortId !== patientShortId;

  const clientNameAnnotation = (
    <>
      {isRequestForNewSession ? (
        <AppointmentTime
          appointment={appointment}
          isLoading={isAppointmentLoading}
          variant="xs"
        />
      ) : (
        <AutomaticallyTransferringText visitorId={visitorUuid} variant="xs" />
      )}

      {providedClientNameAnnotation}
    </>
  );

  const hasNewForms = useAtomValue(hasNewFormsAtomFamily(patientShortId));

  return (
    <ClientActions
      clientName={visitorName}
      clientNameAnnotation={clientNameAnnotation}
      actions={
        <div className="flex gap-2 shrink-0">
          {!hideAdmitButton && (
            <ClientQueueActionButtons
              currentSessionPatientShortId={currentSessionPatientShortId}
              inSession={inSession}
              loading={loading}
              onAdmit={onAdmit}
              onShowStartNewSessionConfirmation={
                onShowStartNewSessionConfirmation
              }
              patientShortId={patientShortId}
              visitorName={visitorName}
            />
          )}
          {/* TODO: JRNY-1299 */}
          {/* <Button use={ButtonUse.Destructive}>Deny</Button> */}
          {onChat && (
            <ClientActionsMenu
              visitorName={visitorName}
              loading={loading}
              isConversationLoading={isConversationLoading}
              patientShortId={patientShortId}
              unreadMessagesCount={unreadMessagesCount}
              onChat={onChat}
              onOpenClientInformationDrawer={onOpenClientInformationDrawer}
              hasNewClientInfo={hasNewForms}
            />
          )}
        </div>
      }
    />
  );
}

export function ClientQueueActionsWrapper({
  visitorName,
  visitorUuid,
  patientShortId,
}: Pick<ClientQueueActionsProps, "visitorName" | "patientShortId"> & {
  visitorUuid: string;
}) {
  const inSession = !!useAtomValue(participantCountAtom);
  const [admitClient] = useAdmitClientCallback();
  const setNewSessionConfirmationData = useSetAtom(
    newSessionConfirmationDataAtom,
  );

  const waitingRoomConversationSid = useAtomValue(
    waitingRoomConversationSidsByVisitorIdFamily(visitorUuid),
  );
  const { patientShortId: currentSessionPatientShortId = null } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};
  const getWaitingRoomConversationSids = useAtomCallback(
    useCallback((get) => get(waitingRoomConversationSidsByVisitorIdAtom), []),
  );

  const isWaitingForClientToJoin = useAtomValue(
    joiningVisitorIdFamily(visitorUuid),
  );
  const { value: isDelayedEntry } = useGate(delayedEntryGate);
  const loading = isDelayedEntry ? false : isWaitingForClientToJoin;
  const openConversation = useOpenConversationCallback();

  const openClientInformation = useOpenClientInformationCallback();

  const openClientInformationSection = () => {
    openClientInformation(visitorUuid);
  };

  const unreadMessagesCount = useAtomValue(
    unreadMessagesCountFamily(waitingRoomConversationSid),
  );

  const providerShortId = useAtomValue(providerShortIdAtom);
  const { data, loading: isAppointmentLoading } = useGetTelehealthSessionInfo(
    providerShortId,
    patientShortId,
  );
  const appointment = data?.telehealthSessionInfo?.appointment;

  const clientNameAnnotation = (
    <ClientMood variant="xs" visitorUuid={visitorUuid} />
  );

  return (
    <ClientQueueActions
      {...{
        appointment,
        clientNameAnnotation,
        currentSessionPatientShortId,
        inSession,
        isAppointmentLoading,
        isConversationLoading: !waitingRoomConversationSid,
        loading,
        onOpenClientInformationDrawer: openClientInformationSection,
        onChat: () => {
          openWaitingRoomConversation(
            visitorUuid,
            getWaitingRoomConversationSids,
            openConversation,
          );
        },
        onAdmit: () => {
          admitClient(visitorUuid, visitorName, patientShortId);
        },
        onShowStartNewSessionConfirmation: () => {
          setNewSessionConfirmationData({
            visitorName,
            visitorUuid,
            patientShortId,
          });
        },
        unreadMessagesCount,
        visitorName,
        visitorUuid,
        patientShortId,
        hideAdmitButton: isDelayedEntry && isWaitingForClientToJoin,
      }}
    />
  );
}

type ClientQueueProps = {
  clients: VisitorPresenceMap;
  clientActionsComponent: typeof ClientQueueActionsWrapper;
  onAdmit?: (uuid: string, name: string) => void;
  loading?: boolean;
};

export function ClientQueue({
  clients,
  clientActionsComponent: ClientActionsComponent,
}: ClientQueueProps) {
  const hasWaitingClients = Object.keys(clients).length;
  return (
    <>
      <div className="flex items-center gap-2">
        <Heading variant="md">Client queue</Heading>
        <Tag use={TagUse.Mauve} data-testid="client-queue.count">
          {Object.keys(clients).length ?? 0}
        </Tag>
      </div>
      {hasWaitingClients ? (
        Object.entries(clients).map(
          ([visitorUuid, { name: visitorName = "", patientShortId }]) => (
            <ClientActionsComponent
              key={visitorUuid}
              {...{
                visitorName,
                visitorUuid,
                patientShortId,
              }}
            />
          ),
        )
      ) : (
        <Text>Clients who join your waiting room will appear here.</Text>
      )}
    </>
  );
}

export function ClientQueueWrapper() {
  const waitingClients = useAtomValue(waitingVisitorsAtom);

  return (
    <ClientQueue
      clients={waitingClients}
      clientActionsComponent={ClientQueueActionsWrapper}
    />
  );
}
