import { LoadingScreen, Modal, Text } from "@grow-therapy-team/sprout-ui";
import { useAtomValue } from "jotai";
import { VisitorState, visitorStateAtom } from "../state";

export function RoomLoader({ shouldShow }: { shouldShow?: boolean }) {
  return (
    <Modal
      isOpen={shouldShow}
      data-testid="room-loader"
      // The modal is pretty wonky; it's not centered and works weirdly on
      // mobile. Resorting to hacks here.
      className="rounded-lg bottom-[calc(50%-10rem)] h-[20rem] left-[calc(50%-9rem)] w-[18rem] p-12 flex-col items-center justify-center sm:self-center sm:mb-[10%] sm:w-[24rem] sm:left-auto"
    >
      <Text
        variant="xl"
        className="font-semibold rebrand:font-medium mb-8"
        as="h2"
      >
        Starting session...
      </Text>
      <LoadingScreen className="flex flex-grow w-full h-full scale-[0.65]" />
    </Modal>
  );
}

export function RoomLoaderWrapper() {
  const visitorState = useAtomValue(visitorStateAtom);
  return <RoomLoader shouldShow={visitorState === VisitorState.LOADING} />;
}
