import { Button, ButtonUse, Icon } from "@grow-therapy-team/sprout-ui";
import { Toast, ToastVariant } from "../Toast";
import { useEffect } from "react";

export function StaleVersionToast({ onClose }: { onClose: () => void }) {
  useEffect(
    function closeOnUnmount() {
      return () => onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Toast
      variant={ToastVariant.Success}
      data-testid="stale-version-toast"
      className="flex gap-3"
      onClose={onClose}
    >
      <Icon variant="solid" name="sparkles" />
      <span>
        A telehealth update is available. Refresh your browser or{" "}
        <Button
          onClick={() => location.reload()}
          className="p-0 m-0 font-normal text-neutral-900 rebrand:text-neutral-900"
          use={ButtonUse.Link}
        >
          click here
        </Button>{" "}
        for the latest version.
      </span>
    </Toast>
  );
}
