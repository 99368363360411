import { IconButtonProps, Tooltip } from "@grow-therapy-team/sprout-ui";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { ControlPanelButton } from ".";
import { VisualIndicator } from "./VisualIndicator";

export function ChatDrawerButton({
  messageCount = 0,
  loading,
  ...otherProps
}: {
  messageCount?: number;
} & Omit<
  IconButtonProps,
  "aria-label" | "icon" | "iconName" | "iconDefinition" | "iconVariant"
>) {
  return (
    <div className="hidden sm:block relative">
      <Tooltip text="Chat" className="z-0">
        <>
          <ControlPanelButton
            aria-label="Chat"
            iconDefinition={faMessage}
            loading={loading}
            disabled={loading}
            {...otherProps}
          />
          {!!messageCount && (
            <div data-testid="chat-drawer.unread-messages">
              <VisualIndicator className="absolute -top-1 -right-1" />
            </div>
          )}
        </>
      </Tooltip>
    </div>
  );
}
