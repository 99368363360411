import { SyncMapItem } from "twilio-sync";
import { olderThan } from "../utils";
import { getVisitorPresenceUpdateIntervalMs } from "../config";
import {
  ParticipantStatus,
  VisitorPresence,
  VisitorPresenceMap,
} from "../types";
import { format, millisecondsToHours } from "date-fns";
import { RemoteParticipant } from "twilio-video";
import { SelectedVisitor } from "./state";

export function filterActive(v: VisitorPresenceMap) {
  const filtered = Object.entries(v).filter(
    ([_, { timestamp }]) =>
      // Allow a single missed heartbeat
      !olderThan(timestamp, getVisitorPresenceUpdateIntervalMs() * 2),
  );
  return Object.fromEntries(filtered);
}

export function mapItemToVisitor(
  item: SyncMapItem,
): VisitorPresenceMap | undefined {
  if (!("name" in item.data) || !("timestamp" in item.data)) return;
  const data = item.data as VisitorPresence;
  const { key: sid } = item;
  const {
    moodCheckinAt,
    emotions,
    name,
    timestamp,
    patientShortId,
    waitingRoomConversationSid,
  } = data;

  return {
    [sid]: {
      moodCheckinAt,
      emotions,
      name,
      timestamp,
      patientShortId,
      waitingRoomConversationSid,
    },
  };
}

export function mapItemsToVisitors(items: SyncMapItem[]) {
  return items
    .filter((s) => "name" in s.data && "timestamp" in s.data)
    .map(mapItemToVisitor)
    .reduce<VisitorPresenceMap>((acc, v) => ({ ...acc, ...v }), {});
}

/**
 * returns the difference between the start and end timestamps in `HH:MM:SS`
 * format, omitting `HH:` if 0.
 *
 * e.g. `timeDifference(new Date("2023-08-24T14:46:30Z").getTime(), new
 * Date("2023-08-24T14:46:31Z").getTime())` returns `00:01`.
 */
export function timeDifference(startTimestamp: number, endTimestamp: number) {
  const differenceMs = endTimestamp - startTimestamp;
  const hours = millisecondsToHours(differenceMs);
  const minutesAndSeconds = format(differenceMs, "mm:ss");

  return `${hours ? `${hours}:` : ""}${minutesAndSeconds}`;
}

export function getSelectedClientInfo(
  selectedClientId: string | undefined,
  waitingVisitors: VisitorPresenceMap,
  participants: Record<string, RemoteParticipant>,
  inSessionParticipant: SelectedVisitor | undefined,
  scheduledPatientInformation: SelectedVisitor | undefined,
) {
  const visitor = waitingVisitors[selectedClientId || ""];
  const sessionIsInProgress = Object.values(participants).length > 0;
  const participantIsInSession = selectedClientId
    ? participants[selectedClientId]
    : false;
  const visitorIsWaiting = !!visitor;
  const selectedClientMatchesScheduledPatient =
    scheduledPatientInformation?.patientShortId === selectedClientId;

  if (visitorIsWaiting) {
    return {
      ...visitor,
      status: ParticipantStatus.WAITING,
      visitorUuid: selectedClientId,
    };
    // In the case of multi-participant sessions where the participant whose clientId is selected leaves but other participants remain, still show the saved participant information
  } else if (participantIsInSession || sessionIsInProgress) {
    return { ...inSessionParticipant, status: ParticipantStatus.IN_SESSION };
  } else if (selectedClientMatchesScheduledPatient) {
    return {
      ...scheduledPatientInformation,
      status: ParticipantStatus.SCHEDULED,
    };
  } else {
    return undefined;
  }
}
