import { ChatDrawerButton } from "../../components";
import { useChatDrawerButtonUtils } from "./useChatDrawerButtonUtils";

export function ChatDrawerButtonWrapper() {
  const { unreadMessageCount, toggleChatDrawer } = useChatDrawerButtonUtils();

  return (
    <ChatDrawerButton
      messageCount={unreadMessageCount}
      onClick={toggleChatDrawer}
    />
  );
}
