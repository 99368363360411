import { gql, useQuery } from "@apollo/client";
import { useAtomValue, useSetAtom } from "jotai";
import { visitorClientShortIdAtom, visitorMeasuresAtom } from "../state";
import { useParams } from "react-router-dom";
import { Server } from "../../apollo/types";
import { MICTypes } from "@grow-therapy-team/sprout-ui";

export const GET_CLIENT_USER_PAPERWORK = gql`
  query GetClientUserPaperwork($clientUserShortId: ID!, $providerShortId: ID) {
    outstandingMeasureSchemas(
      clientUserShortId: $clientUserShortId
      providerShortId: $providerShortId
    ) {
      measureSchemaShortId
      type
      isProviderSpecific
      sections {
        key
        visibleConditionName
        fields {
          key
          visibleConditionName
          options {
            score
            key
          }
        }
      }
    }
  }
`;

export type TClientMeasureBundles = {
  outstandingMeasureSchemas: MICTypes.MeasureSchema[];
};

export function useGetClientUserPaperwork() {
  const clientUserShortId = useAtomValue(visitorClientShortIdAtom);
  const { providerShortId } = useParams() as { providerShortId: string };

  const setVisitorMeasures = useSetAtom(visitorMeasuresAtom);

  return useQuery<TClientMeasureBundles>(GET_CLIENT_USER_PAPERWORK, {
    // providerShortId: while not strictly required, omitting will return _all_ measures
    // rather than the ones for a specific patient-provider relationship
    skip: !clientUserShortId || !providerShortId,
    variables: { clientUserShortId, providerShortId },
    context: { server: Server.MONOLITH, skipAuth: true },
    onCompleted(data) {
      setVisitorMeasures(data?.outstandingMeasureSchemas);
    },
  });
}
