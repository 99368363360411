import { twMerge } from "tailwind-merge";
import breathingAnimation from "../assets/lottie/breathing-animation.json";
import Lottie from "lottie-react";

export function BackgroundAnimationOverlay({
  children,
  className,
  ...props
}: Omit<React.ComponentProps<typeof Lottie>, "animationData">) {
  return (
    <Lottie
      data-testid="background-animation-overlay"
      animationData={breathingAnimation}
      className={twMerge(
        "pointer-events-none absolute w-screen h-screen [&>svg]:md:scale-[2] [&>svg]:sm:scale-[3] [&>svg]:scale-[6] [&>svg]:translate-x-16 [&>svg]:sm:translate-x-0 [&>svg]:-translate-y-60 [&>svg]:sm:-translate-y-32 overflow-hidden sm:overflow-auto rebrand:max-h-full",
        className,
      )}
      {...props}
    >
      {children}
    </Lottie>
  );
}
