import {
  Text,
  Menu,
  MenuToggleButton,
  MenuItem,
  Icon,
  MenuDivider,
  IconName,
} from "@grow-therapy-team/sprout-ui";
import { ThumbnailPosition } from "../twilio/state";
import { useState } from "react";

interface PositionAttributes {
  placement: "bottom-left" | "bottom-right";
  icon: IconName;
  label: string;
  menuClassName?: string;
}

const POSITION_ATTRIBUTES_BY_POSITION: Record<
  ThumbnailPosition,
  PositionAttributes
> = {
  [ThumbnailPosition.TOP_RIGHT]: {
    placement: "bottom-left",
    icon: "arrow-up-right",
    label: "Top right corner",
  },
  [ThumbnailPosition.TOP_LEFT]: {
    placement: "bottom-right",
    icon: "arrow-up-left",
    label: "Top left corner",
  },
  [ThumbnailPosition.BOTTOM_LEFT]: {
    placement: "bottom-right",
    icon: "arrow-down-left",
    label: "Bottom left corner",
    menuClassName: "bottom-10",
  },
  [ThumbnailPosition.BOTTOM_RIGHT]: {
    placement: "bottom-left",
    icon: "arrow-down-right",
    label: "Bottom right corner",
    menuClassName: "bottom-10",
  },
};

export function SetPositionMenu({
  position,
  setPosition,
  isMobile,
}: {
  position: ThumbnailPosition;
  setPosition: (thumbnailPosition: ThumbnailPosition) => void;
  isMobile: boolean;
}) {
  const [showButtonTitle, setShowButtonTitle] = useState(false);
  const currentPosition = POSITION_ATTRIBUTES_BY_POSITION[position];
  const positionChoices = Object.entries(
    POSITION_ATTRIBUTES_BY_POSITION,
  ).filter(([thumbnailPosition, _]) => thumbnailPosition != position);

  return (
    <div className="bottom-2 right-2 absolute">
      <Menu
        placement={currentPosition.placement}
        buttonClassName="group-hover:opacity-75 focus:opacity-100 opacity-0 px-2 py-1
        rebrand:shadow-border-none rebrand:bg-neutral-800 rebrand:hover:bg-neutral-800 rebrand:hover:opacity-100 rebrand:active:bg-neutral-800 rebrand:active:text-neutral-300 rebrand:text-neutral-300
        rebrand:focus-visible:outline-none rebrand:focus-visible:outline-lilac-700 focus-visible:shadow-border-none rebrand:focus-visible:shadow-border-none"
        toggleButton={
          <MenuToggleButton
            //@ts-ignore FIXME: (CARE-786) Add intrinsic attributes to sprout Button component
            onMouseEnter={() => setShowButtonTitle(true)}
            onMouseLeave={() => setShowButtonTitle(false)}
            visibleCaret={false}
          >
            {showButtonTitle && !isMobile && (
              <span className="mr-1 font-normal">Move video</span>
            )}
            <Icon
              name="arrows-up-down-left-right"
              aria-label="Change video position"
              variant="solid"
            />
          </MenuToggleButton>
        }
        menuClassName={`${currentPosition.menuClassName} rebrand:bg-neutral-300 rebrand:rounded-lg rebrand:border-none`}
      >
        <Text className="rebrand:font-medium px-4 py-2" variant="sm">
          Move video
        </Text>
        <MenuDivider />
        <>
          {positionChoices.map(([thumbnailPosition, positionAttributes]) => (
            <MenuItem
              key={thumbnailPosition}
              onClick={() =>
                setPosition(thumbnailPosition as ThumbnailPosition)
              }
              className="rebrand:font-normal"
            >
              <Icon
                name={positionAttributes.icon}
                variant="solid"
                className="mr-2"
              />
              {positionAttributes.label}
            </MenuItem>
          ))}
        </>
      </Menu>
    </div>
  );
}
