import { useContext } from "react";
import { ThemeContext } from "../state";

export const useTheme = () => {
  const contextValue = useContext(ThemeContext);

  if (!contextValue) {
    throw new Error(
      "useTheme must be used within a component wrapped in ThemeContext.Provider",
    );
  }

  return contextValue;
};
