import { useCallback } from "react";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { useAtomCallback } from "jotai/utils";
import { ButtonUse, IconButton, Tooltip } from "@grow-therapy-team/sprout-ui";

import speakerTestChimeUri from "../assets/audio/speaker-test-chime.mp3?url";
import { usePlayAudioCallback } from "../hooks";
import { currentOutputDeviceIdAtom } from "../twilio";
import { getSmoothingFunction } from "../twilio/useGetInputVolume";
import { VerticalDivider } from "./VerticalDivider";
import { VolumeMonitor } from "./VolumeMonitor";
import classNames from "classnames";

const VOLUME_INDICATOR_COUNT = 8;
const volumeSmoothingFunction = getSmoothingFunction({
  maxVolume: VOLUME_INDICATOR_COUNT,
});

export function TestSpeakersButtonComponent({
  audioIsPlaying = false,
  onPlayTestAudio,
  outputVolume = 0,
}: {
  audioIsPlaying?: boolean;
  onPlayTestAudio: () => void;
  outputVolume?: number;
}) {
  // TODO: expose getButtonStyling from sprout-ui
  // const buttonClassName = getButtonStyling();
  const buttonClassName =
    "relative ml-2 rounded-4xl font-semibold tracking-tight transition first-of-type:ml-0 rebrand:font-medium rebrand:tracking-normal rebrand:text-neutral-800 rebrand:focus-visible:outline-neutral-800 bg-neutral-000 text-ivy-500 shadow-border-neutral-400 rebrand:bg-neutral_rebrand-300 rebrand:shadow-border-rebrand-neutral-800 rebrand:rounded hover:shadow-border-ivy-500 rebrand:hover:bg-neutral-400 rebrand:active:bg-neutral_rebrand-500 rebrand:active:text-neutral_rebrand-700 active:shadow-border-avocado-500 active:text-avocado-500 focus-visible:shadow-border-ivy-500 focus-visible:outline-ivy-400 focus-visible:outline-2 focus-visible:outline-offset-4 sprout-icon-button flex items-center px-4 py-4 sprout-button";

  return (
    <div
      className={twMerge(
        buttonClassName,
        "flex flex-row items-center m-0 p-0 pr-2",
      )}
    >
      <Tooltip text="Test Speakers" className="z-0">
        <IconButton
          aria-label="Test speakers"
          className={classNames("m-0", { "bg-transparent": audioIsPlaying })}
          iconDefinition={faPlay}
          onClick={onPlayTestAudio}
          disabled={audioIsPlaying}
          use={ButtonUse.Unstyled}
        />
      </Tooltip>

      <VerticalDivider className="mr-3 h-6" />

      <VolumeMonitor
        className="gap-[2px] mr-1"
        levelIndicatorClassName="w-2 sm:w-2 m-0"
        levelIndicatorCount={VOLUME_INDICATOR_COUNT}
        volume={outputVolume}
      />
    </div>
  );
}

export function TestSpeakersButton() {
  const getSelectedOutputDevice = useAtomCallback(
    useCallback((get) => get(currentOutputDeviceIdAtom), []),
  );

  const { playAudio, volume, isPlaying } = usePlayAudioCallback(
    speakerTestChimeUri,
    { smoothingFunction: volumeSmoothingFunction },
  );
  const onPlayTestAudio = useCallback(
    () => playAudio(getSelectedOutputDevice()),
    [playAudio, getSelectedOutputDevice],
  );

  return (
    <TestSpeakersButtonComponent
      audioIsPlaying={isPlaying}
      outputVolume={volume}
      onPlayTestAudio={onPlayTestAudio}
    />
  );
}
