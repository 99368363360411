import {
  Icon,
  Menu,
  MenuItem,
  MenuToggleButton,
  Text,
  Tooltip,
} from "@grow-therapy-team/sprout-ui";
import { useAtomValue } from "jotai";
import {
  localScreenShareTrackAtom,
  participantCountAtom,
  remoteScreenShareParticipantAtom,
} from "../state";
import useScreenShareCallbacks from "./useScreenShareCallbacks";
import { deviceIsMobile } from "../../utils";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { TakeOverScreenShareModal } from "./TakeOverScreenShareModal";
import { ControlPanelButton, Toast, ToastVariant } from "../../components";
import { faAirplay } from "@fortawesome/pro-solid-svg-icons";

export function ScreenShareControl({
  isSharing,
  isSomeoneElseSharing,
  isLoading,
  onShare,
  onStopShare,
}: {
  isSharing: boolean;
  isSomeoneElseSharing: boolean;
  isLoading?: boolean;
  onShare: () => void;
  onStopShare: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ariaLabel = isSharing ? "See screenshare options" : "Share your screen";
  const tooltipText = isSharing ? "You are sharing screen" : "Share screen";
  const onCloseModal = () => setIsModalOpen(false);
  const onClickShare = () =>
    isSomeoneElseSharing ? setIsModalOpen(true) : onShare();
  const onConfirmShare = () => {
    setIsModalOpen(false);
    onShare();
  };

  const shareButton = (
    <Tooltip text={tooltipText}>
      <ControlPanelButton
        aria-label={ariaLabel}
        loading={isLoading}
        iconDefinition={faAirplay}
        data-testid="screen-share-control"
        {...(isSharing && { as: "span" })}
        {...(!isSharing && { onClick: () => onClickShare() })}
      />
    </Tooltip>
  );

  return (
    <div>
      <TakeOverScreenShareModal
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        onConfirmShare={onConfirmShare}
      />
      {isSharing ? (
        <>
          {/* override menu position */}
          <div className="[&>div>div:nth-child(2)]:-translate-x-[90px] [&>div>div:nth-child(2)]:-translate-y-[170%] [&>div>div:nth-child(2)]:z-[1000]">
            <Menu
              placement="top-left"
              buttonClassName="p-0"
              toggleButton={
                <MenuToggleButton visibleCaret={false}>
                  {shareButton}
                </MenuToggleButton>
              }
            >
              <MenuItem onClick={onStopShare}>
                <Text className="text-ruby-500" variant="sm">
                  <Icon
                    name="square-xmark"
                    variant="regular"
                    className="pr-2.5 pl-0.5"
                  />{" "}
                  Stop sharing
                </Text>
              </MenuItem>
              <MenuItem onClick={onShare}>
                <Icon name="airplay" className="text-ivy-500 pr-2" /> Share
                different screen
              </MenuItem>
            </Menu>
          </div>
        </>
      ) : (
        shareButton
      )}
    </div>
  );
}

export function ScreenShareControlWrapper() {
  const inSession = !!useAtomValue(participantCountAtom);
  const isSharing = !!useAtomValue(localScreenShareTrackAtom);
  const isSomeoneElseSharing = !!useAtomValue(remoteScreenShareParticipantAtom);

  const { triggerBrowserScreenShare, stopScreenShare } =
    useScreenShareCallbacks(() => {
      toast.custom(
        ({ id: toastId }) => {
          return (
            <Toast
              variant={ToastVariant.Error}
              onClose={() => {
                toast.remove(toastId);
              }}
            >
              {"There was an error sharing your screen."}
            </Toast>
          );
        },
        {
          position: "bottom-center",
        },
      );
    });

  if (!inSession || deviceIsMobile()) return;

  return (
    <ScreenShareControl
      isSharing={isSharing}
      isSomeoneElseSharing={isSomeoneElseSharing}
      onShare={triggerBrowserScreenShare}
      onStopShare={stopScreenShare}
    />
  );
}
