import { EndSessionButton } from "../../components";
import { TrackingEvents } from "../../events";
import { sendFullStoryEvent } from "../../fullstory/events";
import { useLeaveSessionCallback } from "./useLeaveSessionCallback";

function LeaveSessionButton({
  onClick,
  ...props
}: {
  onClick: () => void;
} & Omit<React.ComponentProps<typeof EndSessionButton>, "aria-label">) {
  return (
    <EndSessionButton onClick={onClick} {...props} aria-label={"Leave session"}>
      Leave Session
    </EndSessionButton>
  );
}

export function LeaveSessionButtonWrapper() {
  const disconnect = useLeaveSessionCallback();
  return (
    <LeaveSessionButton
      onClick={() => {
        sendFullStoryEvent(TrackingEvents.CLIENT_SESSION_LEAVE);
        disconnect();
      }}
    />
  );
}
