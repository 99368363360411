import {
  ButtonUse,
  Icon,
  Menu,
  MenuItem,
  MenuToggleButton,
  Tag,
  TagUse,
} from "@grow-therapy-team/sprout-ui";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";
import { VisualIndicator } from "../../components/VisualIndicator";

type ClientActionsMenuProps = {
  visitorName: string;
  loading?: boolean;
  patientShortId?: string;
  isConversationLoading?: boolean;
  isClientInformationLoading?: boolean;
  unreadMessagesCount?: number;
  onChat?: () => void;
  onOpenClientInformationDrawer: () => void;
  hasNewClientInfo?: boolean;
};

export const ClientActionsMenu = ({
  visitorName,
  loading,
  isConversationLoading,
  patientShortId,
  unreadMessagesCount,
  onChat,
  onOpenClientInformationDrawer,
  hasNewClientInfo,
}: ClientActionsMenuProps) => {
  const { userClicked } = useTrackEvent();

  const handleOpenClientInformationDrawer = () => {
    userClicked(EventTypeEnum.CLIENT_INFO, {
      patientShortId: patientShortId ?? "",
    });
    onOpenClientInformationDrawer();
  };
  return (
    <Menu
      placement="bottom-left"
      buttonClassName="w-12 h-12"
      toggleButton={
        <MenuToggleButton
          aria-label="Additional actions"
          data-testid={`client-actions.additional-menu:${visitorName}`}
          use={ButtonUse.Secondary}
          loading={loading}
          disabled={loading}
          visibleCaret={false}
        >
          <Icon className="text-ivy-500" variant="solid" name="ellipsis" />
          {(!!unreadMessagesCount || hasNewClientInfo) && (
            <VisualIndicator className="absolute -top-1 -right-1" />
          )}
        </MenuToggleButton>
      }
    >
      <MenuItem
        onClick={onChat}
        data-testid={"client-actions.additional-menu.chat"}
        loading={isConversationLoading}
      >
        <Icon className="text-ivy-500 mr-2" variant="solid" name="message" />{" "}
        Chat
        {!!unreadMessagesCount && (
          <Tag className="ml-2" use={TagUse.Lilac}>
            {unreadMessagesCount}
          </Tag>
        )}
      </MenuItem>
      <MenuItem
        onClick={handleOpenClientInformationDrawer}
        data-testid={"client-actions.additional-menu.client-information"}
      >
        <Icon className="text-ivy-500 mr-2" variant="solid" name="user" />
        View Client Info
        {hasNewClientInfo && (
          <Tag use={TagUse.Lilac} className="ml-2">
            New
          </Tag>
        )}
      </MenuItem>
    </Menu>
  );
};
