import { RecordingControl as BaseRecordingControl } from "../../twilio/audio-video-controls/RecordingControl";
import { toast } from "react-hot-toast";
import { useRecordingControl } from "../../twilio/audio-video-controls/useRecordingControl";
import { isRecordingAtom } from "../../twilio";
import { ConsentStatus, DataTrackMessageTypeEnum } from "../../twilio/types";
import { useAtomValue, useSetAtom, useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { ConsentModal } from "./ConsentModal";
import { useSendDataTrackMessageCallback } from "../../twilio/messages/useSendDataTrackMessageCallback";
import {
  patientTranscriptionConsentAtom,
  providerTranscriptionConsentAtom,
  showTranscriptionConsentModalAtom,
} from "./state";
import { RecordingNotice } from "./RecordingNotice";
import { Tooltip } from "@grow-therapy-team/sprout-ui";
import { useEffect, useState } from "react";
import { ConsentDeclinedToast } from "./ConsentDeclinedToast";

export function RecordingControl() {
  const [showRecordingNotice, setShowRecordingNotice] = useState(false);
  const [hasShownRecordingNotice, setHasShownRecordingNotice] = useState(false);
  const { providerShortId, patientShortId } = useParams();

  const { sendRecordingStatusUpdate, isRecording, isVisible } =
    useRecordingControl(providerShortId, patientShortId);
  const setIsRecording = useSetAtom(isRecordingAtom);
  const [isTranscriptionConsentModalOpen, setIsTranscriptionModalOpen] =
    useAtom(showTranscriptionConsentModalAtom);
  const patientConsent = useAtomValue(patientTranscriptionConsentAtom);
  const providerConsent = useAtomValue(providerTranscriptionConsentAtom);
  const toggleRecording = async (shouldRecord: boolean) => {
    sendRecordingStatusUpdate(shouldRecord);
    setIsRecording(shouldRecord);
  };
  const sendMessage = useSendDataTrackMessageCallback();
  const bothPartiesConsented =
    patientConsent === ConsentStatus.OPTED_IN &&
    providerConsent === ConsentStatus.OPTED_IN;

  useEffect(
    function notifyPatientWhenRecordingFirstStarts() {
      if (isRecording && !hasShownRecordingNotice) {
        setShowRecordingNotice(true);
        setHasShownRecordingNotice(true);
      }
    },
    [isRecording, hasShownRecordingNotice],
  );

  if (!isVisible) return null;

  const showConsentToast = () => {
    toast.custom(
      (t) => <ConsentDeclinedToast onClose={() => toast.remove(t.id)} />,
      {
        position: "bottom-center",
        duration: 30_000,
      },
    );
  };

  return (
    <>
      <ConsentModal
        isOpen={isTranscriptionConsentModalOpen}
        declineConsent={() => {
          sendMessage(
            DataTrackMessageTypeEnum.CLIENT_TRANSCRIPTION_CONSENT_UPDATED,
            {
              clientConsent: ConsentStatus.PENDING,
            },
          );
          setIsTranscriptionModalOpen(false);
          showConsentToast();
        }}
        recordConsent={async () => {
          // Send consent to provider
          sendMessage(
            DataTrackMessageTypeEnum.CLIENT_TRANSCRIPTION_CONSENT_UPDATED,
            {
              clientConsent: ConsentStatus.OPTED_IN,
            },
          );
          setIsTranscriptionModalOpen(false);

          // Start recording
          if (providerConsent === ConsentStatus.OPTED_IN) {
            await toggleRecording(true);
          }
        }}
      />
      {(bothPartiesConsented || isRecording) && (
        <Tooltip
          isOpen={showRecordingNotice}
          disabled={!showRecordingNotice}
          clickable
          text={
            <RecordingNotice onClose={() => setShowRecordingNotice(false)} />
          }
          className="bg-transparent opacity-100"
        >
          <BaseRecordingControl
            className="rebrand:bg-lilac-700"
            isRecording={isRecording}
            onRecordingToggle={toggleRecording}
            consentPending={false} // Clients don't see this until they've consented
          />
        </Tooltip>
      )}
    </>
  );
}
