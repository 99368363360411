import {
  Text,
  PhotoAvatar,
  AvatarAccent,
  PhotoAvatarSize,
  PhotoAvatarUse,
  Skeleton,
} from "@grow-therapy-team/sprout-ui";
import { Provider } from "../types";
import { twMerge } from "tailwind-merge";
import { useGetCurrentProviderQuery } from "./useGetCurrentProviderQuery";

type ProviderAvatarProps = {
  className?: string;
  provider: Pick<Provider, "image" | "name" | "pronouns">;
};

export function ProviderAvatar({ className, provider }: ProviderAvatarProps) {
  return (
    <div
      className={twMerge("flex items-center gap-x-3 fs-exclude", className)}
      data-dd-privacy="mask"
      data-testid="provider-avatar"
    >
      <PhotoAvatar
        accent={AvatarAccent.Ivy}
        containerClassName="bg-neutral-000"
        imgAltText="Provider image"
        imgUrl={provider?.image ?? ""}
        size={PhotoAvatarSize.md}
        use={PhotoAvatarUse.Outline}
      />
      <Text
        variant="lg"
        className="font-semibold rebrand:font-medium text-neutral-000"
        data-dd-action-name="Click on provider avatar"
      >
        {provider.name}
        {(provider?.pronouns?.length ?? 0) > 0 && (
          <span className="font-normal"> ({provider.pronouns![0]})</span>
        )}
      </Text>
    </div>
  );
}

export function ProviderAvatarWrapper(props: Partial<ProviderAvatarProps>) {
  const { data, loading } = useGetCurrentProviderQuery();

  if (loading || !data) {
    return (
      <div className="flex gap-3 items-center">
        <Skeleton className="my-0 mx-0 rounded-full w-18 h-18" />
        <Skeleton className="my-0 mx-0 w-28" />
      </div>
    );
  }

  return <ProviderAvatar provider={data.currentProvider} {...props} />;
}
