import { useCallback, useMemo } from "react";
import {
  SubmitMeasureResponseInput,
  useSubmitMeasureResponse,
} from "./useSubmitMeasureResponse";
import { MeasureSchemaAndAnswers } from "../../components/measures/types";
import { PageComponentProps } from "../../components";
import { MICUtils, toast } from "@grow-therapy-team/sprout-ui";
import { logger } from "../../datadog";
import { CallbackFailure, CallbackSuccess } from "../../types";

type SubmitMeasureResponseResult =
  | CallbackSuccess<string, "SUBMITTED">
  | CallbackFailure<Error, "FAILED_TO_SUBMIT" | "MISSING_REQUIRED_DATA">;

export function useSubmitMeasureResponseCallback() {
  const startedAt = useMemo(() => new Date().toISOString(), []);

  const [submitMeasureResponse] = useSubmitMeasureResponse();

  return useCallback(
    async ({
      page,
      clientUserShortId,
      completionCampaign,
    }: PageComponentProps<MeasureSchemaAndAnswers> & {
      clientUserShortId?: string;
      completionCampaign: SubmitMeasureResponseInput["completionCampaign"];
    }): Promise<SubmitMeasureResponseResult> => {
      const { answers, schema, hasSubmitted } = page;
      if (!clientUserShortId || !schema || hasSubmitted) {
        return {
          status: "failure",
          code: "MISSING_REQUIRED_DATA",
          value: Error("Missing required data"),
        };
      }

      try {
        const { data } = await submitMeasureResponse({
          variables: {
            clientUserShortId,
            measureSchemaShortId: schema.measureSchemaShortId,
            values: {
              responseContent: JSON.stringify(
                MICUtils.trimUnusedMeasureValues(answers, schema),
              ),
              startedAt,
              completionCampaign,
            },
          },
        });
        return {
          status: "success",
          code: "SUBMITTED",
          value:
            data?.submitMeasureResponse?.measureResponse
              ?.measureResponseShortId ?? "",
        };
      } catch (err) {
        logger.error(
          "Could not submit measure",
          { clientUserShortId },
          err as Error,
        );
        toast.error("Unable to submit form due to an expected error.");
        return {
          status: "failure",
          code: "FAILED_TO_SUBMIT",
          value: err as Error,
        };
      }
    },
    [startedAt, submitMeasureResponse],
  );
}
