import { Theme } from "../state";
import {
  Navbar,
  PageContainer,
  PushDrawerContainer,
  StageContainer,
  ThemeProvider,
  useDetectStaleVersionInterval,
} from "../components";
import RebrandBackground from "../assets/provider-bg.svg?react";
import { ProviderAvatarWrapper as ProviderAvatar } from "./ProviderAvatar";
import { SelfThumbnailVideo } from "./SelfThumbnailVideo";
import { ClientsDrawerButtonWrapper as ClientsDrawerButton } from "./clients-drawer";
import { useProviderTwilioRoomListeners } from "./useProviderTwilioRoomListeners";
import { ProviderGreetingWrapper as ProviderGreeting } from "./ProviderGreeting";
import { SchedulePreviewWrapper as SchedulePreview } from "./schedule-preview/SchedulePreview";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  AudioVideoControlsWrapper as VideoControls,
  isScreenShareModeAtom,
  participantCountAtom,
  twilioRoomAtom,
  useHandleDeviceChanges,
  useInitLocalTracks,
} from "../twilio";
import { CompleteSessionButton } from "./CompleteSessionButton";
import { useVisitorPresenceMap } from "./useVisitorPresenceMap";
import { StartNewSessionConfirmationModalWrapper as StartNewSessionConfirmationModal } from "./clients-drawer/StartNewSessionConfirmationModal";
import { useRecordUnaccompaniedProviderInterval } from "./useRecordUnaccompaniedProviderInterval";
import { useNetworkQualityLogging } from "../twilio/useNetworkQualityLogging";
import { nonEssentialElementProps, useSessionFocus } from "../hooks";
import { useInitProviderGlobalDDLoggerContext } from "./useInitProviderGlobalDDLoggerContext";
import { useResetVideoOnMobileNavigation } from "../twilio/useResetVideoOnMobileNavigation";
import { useInitProviderUserTracking } from "./useInitProviderUserTracking";
import { InSessionTimerWrapper as InSessionTimer } from "./InSessionTimer";
import { useMaxDurationWarning } from "./useMaxDurationWarning";
import { SessionGrid } from "../twilio/session-grid/SessionGrid";
import { useInitProviderChatClient } from "./chat";
import {
  DrawerState,
  drawerStateAtom,
  inSessionPatientInformationAtom,
  providerShortIdAtom,
} from "./state";
import { ChatDrawerButtonWrapper as ChatDrawerButton } from "./chat/ChatDrawerButton";
import { PushDrawerWrapper as PushDrawer } from "./PushDrawer";
import { useEffect, useMemo, useState } from "react";
import { HelpModalWrapper as HelpModal } from "./HelpModal";
import { useShowNotificationInFaviconAndTitle } from "./useUpdateFaviconAndTitle";
import { AdditionalOptionsMenuWrapper as AdditionalOptionsMenu } from "./AdditionalOptionsMenu";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { useInitTwilioSyncClient } from "./sync";
import classNames from "classnames";
import { useUpdateTrackStatsInterval } from "../twilio/stats/useUpdateTrackStatsInterval";
import { ClientInformationButtonWrapper as ClientInformationButton } from "./client-information/ClientInformationButton";
import { useOpenClientInformationCallback } from "./client-information";
import { useGate } from "statsig-react";
import { telehealthTranscriptionGate } from "../statsig/gates";
import { useAnnouncementToast } from "./useAnnouncementToast";
import { useInactiveTabPerformanceOptimization } from "../hooks/useInactiveTabPerformanceOptimization";
import { usePromptFollowUpToast } from "./schedule-appointment/usePromptFollowUpToast";
import { EventTypeEnum } from "../segment/types";
import { useWaitingRoomTrackingEvent } from "../hooks/useWaitingRoomTrackingEvent";
import { useSyncMoodCheckin } from "./measures/useSyncMoodCheckin";
import { RecordingControl } from "./session-recording/RecordingControl";
import { RemoteParticipant } from "./RemoteParticipant";
import { TranscriptionPillWrapper } from "./session-recording/TranscriptionPill";
import toast from "react-hot-toast";
import { AvsCtaPillWrapper as AvsCtaPill } from "./after-visit-summary/AvsCtaPill";

function Background() {
  return (
    <RebrandBackground
      className="pointer-events-none bg-neutral_rebrand-900 absolute w-screen h-screen"
      aria-hidden
    />
  );
}

export function Stage() {
  const inSession = !!useAtomValue(participantCountAtom);
  const isScreenShareMode = useAtomValue(isScreenShareModeAtom);
  const { value: isTranscriptionGateEnabled } = useGate(
    telehealthTranscriptionGate,
  );
  const thumbnail = useMemo(
    () => (
      <SelfThumbnailVideo
        showExpandVideoToggle={false}
        className={classNames({
          "flex-1 max-w-[15rem] min-w-[10rem] sm:min-w-[unset] max-h-[8.5rem] min-h-[7.875rem] w-auto h-full sm:mb-2":
            isScreenShareMode,
        })}
        videoClassName="max-w-[15rem] max-h-[8.5rem] min-w-[unset] w-auto h-full [&>video]:relative mb-2 mr-2 sm:mr-0 flex-1 border border-neutral-700 rounded-2xl rebrand:border-neutral-800 rebrand:border"
      />
    ),
    [isScreenShareMode],
  );

  return (
    <StageContainer className="@container" rounded>
      {inSession ? (
        <>
          <SessionGrid
            remoteParticipantComponent={RemoteParticipant}
            thumbnail={thumbnail}
          />
          <InSessionTimer />
          {!isScreenShareMode && (
            <SelfThumbnailVideo
              className="absolute bottom-14"
              hideSelfViewClassName="bottom-14"
              videoClassName="max-w-[8rem]"
              showSetPositionMenu
              showExpandVideoToggle={false}
            />
          )}
        </>
      ) : (
        <>
          <Background />
          <SelfThumbnailVideo
            showExpandVideoToggle={false}
            className="hidden @sm:flex absolute top-2 right-2"
          />
          <div className="relative h-full w-full flex flex-col overflow-y-auto overflow-x-hidden p-8">
            <ProviderAvatar />
            <SelfThumbnailVideo
              className="self-center @sm:hidden relative my-10"
              videoClassName="min-h-[14rem] max-h-[18rem] min-w-[25rem] max-w-screen-sm"
            />
            <div className="flex flex-col flex-grow items-center justify-center">
              <ProviderGreeting className="mb-8" />
              <SchedulePreview />
              {isTranscriptionGateEnabled && <AvsCtaPill className="mt-6" />}
            </div>
          </div>
        </>
      )}
    </StageContainer>
  );
}

function PageEffects() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  useInitLocalTracks();
  useHandleDeviceChanges();
  useInitTwilioSyncClient();
  useVisitorPresenceMap();
  useSyncMoodCheckin();
  useProviderTwilioRoomListeners();
  useRecordUnaccompaniedProviderInterval();
  useNetworkQualityLogging({
    onOpenSettings: () => setDrawerState(DrawerState.SETTINGS),
  });
  useSessionFocus();
  useInitProviderGlobalDDLoggerContext();
  useInitProviderUserTracking();
  useResetVideoOnMobileNavigation();
  useMaxDurationWarning();
  useDetectStaleVersionInterval(!useAtomValue(twilioRoomAtom));
  useShowNotificationInFaviconAndTitle();
  useInitProviderChatClient();
  useUpdateTrackStatsInterval();
  useAnnouncementToast();

  useInactiveTabPerformanceOptimization();
  const providerShortId = useAtomValue(providerShortIdAtom);
  useWaitingRoomTrackingEvent(
    EventTypeEnum.PROVIDER_WAITING_ROOM,
    providerShortId,
  );

  return null;
}

export function Page() {
  const [drawerState, setDrawerState] = useAtom(drawerStateAtom);
  const inSessionPatientInformation = useAtomValue(
    inSessionPatientInformationAtom,
  );
  const providerShortId = useAtomValue(providerShortIdAtom);
  const inSession = !!useAtomValue(participantCountAtom);
  const openClientInformationDrawer = useOpenClientInformationCallback();

  usePromptFollowUpToast(
    providerShortId,
    inSessionPatientInformation?.patientShortId,
  );

  useEffect(
    function sendLoggingEventsOnPageLoad() {
      if (inSession) {
        // TODO migrate to segment
        sendLoggingEvents(TrackingEvents.PROVIDER_ENTER_SESSION_ROOM);
      }
    },
    [inSession],
  );

  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const toggleDrawerState = (drawer: DrawerState): void => {
    if (drawerState === drawer) {
      setDrawerState(null);
      return;
    }
    setDrawerState(drawer);
  };

  return (
    <>
      <PageEffects />
      <ThemeProvider theme={Theme.DARK}>
        <PageContainer>
          <HelpModal
            isOpen={isHelpModalOpen}
            onClose={() => setIsHelpModalOpen(false)}
          />
          <Navbar
            onClickSettingsButton={() =>
              toggleDrawerState(DrawerState.SETTINGS)
            }
            onClickNeedHelpButton={() => {
              sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
              sendLoggingEvents(TrackingEvents.PROVIDER_HELP_REQUEST);
              setIsHelpModalOpen(true);
            }}
            TranscriptionPillComponent={TranscriptionPillWrapper}
            {...nonEssentialElementProps}
          />
          <PushDrawerContainer drawer={<PushDrawer />}>
            <Stage />
          </PushDrawerContainer>
          <VideoControls
            onOpenHelpModal={() => {
              sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
              sendLoggingEvents(TrackingEvents.PROVIDER_HELP_REQUEST);
              setIsHelpModalOpen(true);
            }}
            recordingControlComponent={RecordingControl}
            additionalLeftControls={<ClientsDrawerButton />}
            additionalRightControls={inSession && <CompleteSessionButton />}
            chatButtonComponent={<ChatDrawerButton />}
            clientInformationButtonComponent={<ClientInformationButton />}
            additionalOptionsComponent={
              <AdditionalOptionsMenu
                onClickSettingsButton={() =>
                  toggleDrawerState(DrawerState.SETTINGS)
                }
                onClickClientsButton={
                  inSession
                    ? () => {
                        toggleDrawerState(DrawerState.CLIENTS);
                        toast.dismiss();
                      }
                    : undefined
                }
                onClickClientInformationButton={() =>
                  openClientInformationDrawer(
                    inSessionPatientInformation?.visitorUuid ?? "",
                  )
                }
              />
            }
            {...nonEssentialElementProps}
          />
        </PageContainer>
        <StartNewSessionConfirmationModal />
      </ThemeProvider>
    </>
  );
}
