import { atom } from "jotai";
import { ConsentStatus } from "../../twilio/types";
import { createDerivedWritableAtom } from "../../utils";

export type VisitorSessionRecordingAtom = {
  showTranscriptionConsentModal: boolean;
  providerTranscriptionConsent: ConsentStatus;
  patientTranscriptionConsent: ConsentStatus;
};

export const visitorSessionRecordingAtom = atom<VisitorSessionRecordingAtom>({
  showTranscriptionConsentModal: false,
  providerTranscriptionConsent: ConsentStatus.PENDING,
  patientTranscriptionConsent: ConsentStatus.PENDING,
});

export const showTranscriptionConsentModalAtom = createDerivedWritableAtom(
  visitorSessionRecordingAtom,
  "showTranscriptionConsentModal",
);

export const providerTranscriptionConsentAtom = createDerivedWritableAtom(
  visitorSessionRecordingAtom,
  "providerTranscriptionConsent",
);

export const patientTranscriptionConsentAtom = createDerivedWritableAtom(
  visitorSessionRecordingAtom,
  "patientTranscriptionConsent",
);
