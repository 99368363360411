export function scrollToBottom(node: HTMLElement): void {
  node.scrollTop = node.scrollHeight;
}

export function detectLinks(text: string | null = "") {
  const urlWithProtocolRegex = /https?:\/\/[^\s]+/gi;
  const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+\.[^\s]+)/gi;

  return text?.split(urlRegex).map((part, index) => {
    if (part.match(urlRegex)) {
      const isMissingProtocol = !part.match(urlWithProtocolRegex);

      let href = part;
      if (isMissingProtocol) {
        href = `//${part}`;
      }

      return (
        <a key={index} href={href} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
}
