import { useAtomValue } from "jotai";
import { AdditionalOptionsMenu } from "../components/AdditionalOptionsMenu";
import { useChatDrawerButtonUtils } from "./chat/useChatDrawerButtonUtils";
import { hasAnyNewFormsAtom } from "./client-information/state";
import { numWaitingVisitorsAtom } from "./state";

export function AdditionalOptionsMenuWrapper({
  onClickSettingsButton,
  onClickClientInformationButton,
  onClickClientsButton,
}: {
  onClickSettingsButton: () => void;
  onClickClientInformationButton?: () => void;
  onClickClientsButton?: () => void;
}) {
  const { unreadMessageCount, toggleChatDrawer } = useChatDrawerButtonUtils();
  const numberOfWaitingClients = useAtomValue(numWaitingVisitorsAtom);
  const hasNewForms = useAtomValue(hasAnyNewFormsAtom);

  return (
    <AdditionalOptionsMenu
      onClickSettingsButton={onClickSettingsButton}
      unreadMessageCount={unreadMessageCount}
      onClickChatButton={toggleChatDrawer}
      onClickClientInformationButton={onClickClientInformationButton}
      hasNewClientInfo={!!onClickClientInformationButton && hasNewForms}
      numberOfWaitingClients={numberOfWaitingClients}
      onClickClientsButton={onClickClientsButton}
    />
  );
}
