import { Button, ButtonUse, Link, Text } from "@grow-therapy-team/sprout-ui";
import { useAtomValue } from "jotai";
import {
  LocalTrackState,
  localAudioTrackAtom,
  localVideoTrackAtom,
  permissionDeniedAtom,
} from "../../twilio";
import { BlockedPermissionsActionItemWrapper as BlockedPermissionsActionItem } from "./BlockedPermissionsActionItem";
import { HelpModalItem } from "./HelpModalItem";
import { AudioControlWrapper as AudioControl } from "../../twilio/audio-video-controls/AudioControl";
import { VideoControlWrapper as VideoControl } from "../../twilio/audio-video-controls/VideoControl";

const CLIENT_AUDIO_VIDEO_TROUBLESHOOT_ARTICLE =
  "https://help.growtherapy.com/clients/en/articles/8482385-grow-therapy-telehealth-faq#h_f12c851172";

type AudioVideoTroubleshootActionItemProps = {
  permissionIsBlocked: boolean;
  isVideoEnabled?: boolean;
  isAudioEnabled?: boolean;
  onOpenSettings?: () => void;
};

export function AudioVideoTroubleshootActionItem({
  permissionIsBlocked,
  isVideoEnabled,
  isAudioEnabled,
  onOpenSettings,
}: AudioVideoTroubleshootActionItemProps) {
  if (permissionIsBlocked) {
    return <BlockedPermissionsActionItem />;
  }

  return (
    <>
      <HelpModalItem
        heading="My camera and microphone aren't working"
        description={
          <div className="flex flex-col gap-4">
            <Text className="text-neutral-800 rebrand:font-normal">
              Make sure your camera and microphone are on:
            </Text>
            <div className="grid md:grid-cols-3 grid-cols-2">
              <div className="flex flex-row items-center gap-4">
                <VideoControl />
                <Text>Camera: {isVideoEnabled ? "On" : "Off"}</Text>
              </div>
              <div className="flex flex-row items-center gap-4">
                <AudioControl />
                Microphone: {isAudioEnabled ? "On" : "Off"}
              </div>
            </div>
            <Text variant="xs" className="text-neutral-700">
              If you&apos;re experiencing camera or microphone issues after
              turning them on, find more troubleshooting tips{" "}
              <Link
                external
                href={CLIENT_AUDIO_VIDEO_TROUBLESHOOT_ARTICLE}
                className="text-neutral-700 hover:text-neutral-900 text-xsmall font-normal"
              >
                here
              </Link>
            </Text>
          </div>
        }
      />
      <HelpModalItem
        heading="My video or audio quality is poor"
        description={
          <>
            Connectivity issues may affect your video or audio quality. Try the
            following:
            <ul className="list-disc ml-4">
              <li>Turn your video background off, if enabled.</li>
              <li>Turn your camera off.</li>
              <li>
                In{" "}
                <Button
                  inline
                  className="font-normal rebrand:font-medium text-inherit rebrand:text-inherit"
                  use={ButtonUse.Link}
                  onClick={() => {
                    onOpenSettings?.();
                  }}
                >
                  Settings
                </Button>
                , change your video quality to &ldquo;Optimize for
                performance&rdquo;.
              </li>
              <li>Get closer to the Wifi router.</li>
              <li>Close browser tabs that aren&apos;t in use.</li>
              <li>Ensure your device has a good battery level.</li>
            </ul>
          </>
        }
      />
    </>
  );
}

export function AudioVideoTroubleshootActionItemWrapper(
  props: Pick<AudioVideoTroubleshootActionItemProps, "onOpenSettings">,
) {
  const blockedPermissions = useAtomValue(permissionDeniedAtom);
  const { videoIsBlocked, microphoneIsBlocked } = blockedPermissions;

  const { track: localVideoTrackValue, state: localVideoTrackState } =
    useAtomValue(localVideoTrackAtom);
  const { track: localAudioTrackValue, state: localAudioTrackState } =
    useAtomValue(localAudioTrackAtom);
  const isVideoEnabled =
    localVideoTrackValue && localVideoTrackState === LocalTrackState.READY;
  const isAudioEnabled =
    localAudioTrackValue && localAudioTrackState === LocalTrackState.READY;

  return (
    <AudioVideoTroubleshootActionItem
      {...props}
      permissionIsBlocked={videoIsBlocked || microphoneIsBlocked}
      isVideoEnabled={isVideoEnabled}
      isAudioEnabled={isAudioEnabled}
    />
  );
}
