import {
  Button,
  ButtonUse,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { HelpModalItem } from "../components/help-modal/HelpModalItem";
import { AudioVideoTroubleshootActionItemWrapper as BaseAudioVideoTroubleshootActionItem } from "../components/help-modal/AudioVideoTroubleshootActionItem";
import {
  CLIENT_SESSION_NOT_STARTED_HEADING,
  CLIENT_TELEHEALTH_HELP_ARTICLE,
} from "../components/help-modal/constants";
import { useInitAvailableDevices } from "../twilio/devices/useDevices";
import { Appointment } from "../types";
import { ReactNode } from "react";
import { VisitorDrawerState, visitorDrawerStateAtom } from "./state";
import { useSetAtom } from "jotai";
import { ProviderNotHereDescriptionWrapper as ProviderNotHereDescription } from "./ProviderNotHereDescription";
import { useRecordProviderNoShowReportCallback } from "./waiting-room/useRecordProviderNoShowReportCallback";
import { getAppConfig } from "../config";

type HelpModalProps = {
  isOpen: boolean;
  onClose: () => void;
  appointment?: Pick<Appointment, "hasProviderNoShowReport">;
  audioVideoTroubleshootActionItem?: ReactNode;
  shouldShowProviderNoShowSection?: boolean;
  isPastNoShowThreshold: boolean;
  clientJoinedAfterWindowToReport: boolean;
  recordProviderNoShowAndRedirect: () => void;
};

export function HelpModal({
  isOpen,
  onClose,
  appointment,
  audioVideoTroubleshootActionItem: AudioVideoTroubleshootActionItem,
  shouldShowProviderNoShowSection,
  isPastNoShowThreshold,
  clientJoinedAfterWindowToReport,
  recordProviderNoShowAndRedirect,
}: HelpModalProps) {
  const marketplaceOrigin = getAppConfig().marketplaceOrigin;

  const supportText = (
    <Text variant="sm">
      If you need additional support, reach out to us{" "}
      <Link
        href={`${marketplaceOrigin}/wp/contact-us/`}
        className="font-regular text-neutral-700 rebrand:font-medium rebrand:hover:text-neutral-900"
        size="sm"
        target="_blank"
        external={true}
      >
        here
      </Link>
      .
    </Text>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Need help?</ModalHeader>
      <ModalBody className="sm:py-8 flex flex-col gap-6">
        <Text
          className="text-neutral-800 rebrand:font-medium font-semibold rebrand:text-medium"
          as="h2"
        >
          Common issues
        </Text>
        <ol className="list-style-none flex flex-col gap-6">
          {AudioVideoTroubleshootActionItem}
          {shouldShowProviderNoShowSection && (
            <HelpModalItem
              heading={CLIENT_SESSION_NOT_STARTED_HEADING}
              description={
                <ProviderNotHereDescription
                  onClose={onClose}
                  clientHasAppointment={!!appointment}
                  isPastNoShowThreshold={isPastNoShowThreshold}
                  clientJoinedAfterWindowToReport={
                    clientJoinedAfterWindowToReport
                  }
                  recordProviderNoShowAndRedirect={
                    recordProviderNoShowAndRedirect
                  }
                  appointmentHasProviderNoShowReport={
                    appointment?.hasProviderNoShowReport ?? false
                  }
                />
              }
            />
          )}
          <HelpModalItem
            heading="I need help with a different issue"
            description={
              <>
                See our{" "}
                <Link
                  external
                  className="text-neutral-800 rebrand:font-medium rebrand:hover:text-neutral-900"
                  href={CLIENT_TELEHEALTH_HELP_ARTICLE}
                >
                  Telehealth guide
                </Link>{" "}
                for additional help.
              </>
            }
          />
        </ol>
      </ModalBody>

      <ModalFooter
        actions={
          <Button onClick={onClose} use={ButtonUse.Secondary}>
            Done
          </Button>
        }
      >
        {supportText}
      </ModalFooter>
    </Modal>
  );
}

export function HelpModalWrapper(
  props: Omit<
    HelpModalProps,
    | "audioVideoTroubleshootActionItem"
    | "isPastNoShowThreshold"
    | "clientJoinedAfterWindowToReport"
    | "recordProviderNoShowAndRedirect"
  >,
) {
  useInitAvailableDevices();
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);
  const openSettings = () => {
    props.onClose();
    setDrawerState(VisitorDrawerState.SETTINGS);
  };
  const {
    isPastNoShowThreshold,
    clientJoinedAfterWindowToReport,
    recordProviderNoShowAndRedirect,
  } = useRecordProviderNoShowReportCallback();

  return (
    <HelpModal
      isPastNoShowThreshold={isPastNoShowThreshold}
      clientJoinedAfterWindowToReport={clientJoinedAfterWindowToReport}
      recordProviderNoShowAndRedirect={recordProviderNoShowAndRedirect}
      audioVideoTroubleshootActionItem={
        <BaseAudioVideoTroubleshootActionItem onOpenSettings={openSettings} />
      }
      {...props}
    />
  );
}
