import { THEME_CONTEXT_DEFAULT_VALUE, Theme, ThemeContext } from "../state";

export function ThemeProvider({
  children,
  theme,
}: {
  children: React.ReactNode;
  theme?: Theme;
}) {
  return (
    <ThemeContext.Provider
      value={{
        ...THEME_CONTEXT_DEFAULT_VALUE,
        ...(theme && { theme }),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
