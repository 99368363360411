import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { twilioAtom, twilioRoomAtom } from "./state";
import { toast } from "@grow-therapy-team/sprout-ui";
import { Room } from "twilio-video";
import { logger } from "../datadog/logger";
import useScreenShareCallbacks from "./audio-video-controls/useScreenShareCallbacks";
import { useRevertVideoQualityCallback } from "./audio-video-controls/useVideoQualityCallbacks";

export type DisconnectTwilioRoomCallback = (options?: {
  onError?: (error: unknown) => void;
}) => Room | undefined;

/**
 * Disconnects from the Twilio room and clears relevant Twilio Jotai state.
 */
export function useDisconnectTwilioRoomCallback(): DisconnectTwilioRoomCallback {
  const setTwilioContext = useSetAtom(twilioAtom);
  const getTwilioRoom = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom), []),
  );
  const { stopScreenShare } = useScreenShareCallbacks();
  const revertVideoQuality = useRevertVideoQualityCallback();

  return useCallback(
    function disconnectFromRoom(options) {
      const room = getTwilioRoom();
      if (!room) return;
      try {
        stopScreenShare();
        room.disconnect();
        setTwilioContext((prev) => ({
          ...prev,
          room: undefined,
          participants: [],
          connectedAt: undefined,
          remoteTracksByParticipantId: {},
          shouldOverrideSuggestedRoomVideoQuality: false,
        }));
        revertVideoQuality();
        return room;
      } catch (error) {
        toast.error("There was an issue disconnecting from the room.");
        logger.error(
          "Unable to disconnect from room",
          {
            roomSid: room.sid,
            localParticipantSid: room.localParticipant.sid,
          },
          error as Error,
        );
        options?.onError?.(error);
        return;
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
}
