import { useEffect, useState } from "react";
import { SyncMap, SyncMapItem } from "twilio-sync";
import { connectSyncMap, fetchMapItems } from "../utils";
import { SyncMapEvent, SyncMapEventHandler } from "../types";
import { logger } from "../../datadog/logger";
import { syncClientStateAtom, twilioSyncClientAtom } from "..";
import { useAtomValue } from "jotai";

export type OnInitializeItemsCallback = (items: SyncMapItem[]) => void;

export function useSyncMap({
  mapSid,
  onInitializeItems,
  onItemAdded,
  onItemUpdated,
  onItemRemoved,
}: {
  mapSid?: string | null;
  onInitializeItems?: (items: SyncMapItem[]) => void;
  onItemAdded?: SyncMapEventHandler;
  onItemUpdated?: SyncMapEventHandler;
  onItemRemoved?: SyncMapEventHandler;
}) {
  const [syncMap, setSyncMap] = useState<SyncMap>();
  const client = useAtomValue(twilioSyncClientAtom);
  const syncClientState = useAtomValue(syncClientStateAtom);

  useEffect(
    function initializeMap() {
      if (!client || !mapSid || syncClientState !== "connected") return;

      (async () => {
        const map = await connectSyncMap(client, { id: mapSid });
        if (!map) return;
        if (onInitializeItems) {
          try {
            const items: SyncMapItem[] = await fetchMapItems(map);
            onInitializeItems(items);
            logger.info("Initialized sync map items", {
              mapSid,
              items: items.length,
            });
          } catch (error) {
            logger.error(
              "Unable to fetch map items",
              { mapSid },
              error as Error,
            );
          }
        }
        setSyncMap(map);
      })();

      return function cleanup() {
        setSyncMap((prevMap) => {
          if (!prevMap) return prevMap;
          prevMap.close();
          logger.info("Closed sync map", { mapSid });
          return undefined;
        });
      };
    },
    [client, mapSid, onInitializeItems, setSyncMap, syncClientState],
  );

  useEffect(
    function handleMapEvents() {
      if (!syncMap) return;

      const eventToCallbackMap: Record<SyncMapEvent, SyncMapEventHandler> = {
        itemAdded: (eventData) => {
          if (!onItemAdded) return;
          onItemAdded(eventData);
          logger.info("Item added", {
            mapSid: syncMap.sid,
            key: eventData.item.key,
            dateExpires: eventData.item.dateExpires,
            dateUpdated: eventData.item.dateUpdated,
          });
        },
        itemUpdated: (eventData) => {
          if (!onItemUpdated) return;
          onItemUpdated(eventData);
          logger.info("Item updated", {
            mapSid: syncMap.sid,
            key: eventData.item.key,
            dateExpires: eventData.item.dateExpires,
            dateUpdated: eventData.item.dateUpdated,
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        itemRemoved: (eventData: any) => {
          if (!onItemRemoved) return;
          onItemRemoved(eventData.item);
          logger.info("Item removed", {
            mapSid: syncMap.sid,
            key: eventData.item.key,
            dateExpires: eventData.item.dateExpires,
            dateUpdated: eventData.item.dateUpdated,
          });
        },
      };

      Object.entries(eventToCallbackMap).forEach(([event, callback]) => {
        syncMap.on(event as SyncMapEvent, callback);
      });

      return function cleanup() {
        Object.entries(eventToCallbackMap).forEach(([event, callback]) => {
          syncMap.off(event as SyncMapEvent, callback);
        });
      };
    },
    [syncMap, onItemAdded, onItemUpdated, onItemRemoved],
  );

  return syncMap;
}
