import { Icon, Link, Text } from "@grow-therapy-team/sprout-ui";
import { ThemeProvider, PageContainer, GrowLogo } from "../../components";
import { Theme } from "../../state";
import { useInitVisitorGlobalDDLoggerContext } from "../useInitVisitorGlobalDDLoggerContext";
import { useInitVisitorUserTracking } from "../useInitVisitorUserTracking";
import { useParams } from "react-router-dom";
import { NoShowActionList } from "./NoShowActionList";

export function Page() {
  useInitVisitorGlobalDDLoggerContext();
  useInitVisitorUserTracking();
  const { providerShortId, patientShortId } = useParams();

  return (
    <ThemeProvider theme={Theme.LIGHT}>
      <PageContainer className="rebrand:bg-neutral-300 flex flex-col overflow-y-auto">
        <div className="sticky top-0 flex justify-start border-b border-neutral-800 py-6 px-3 bg-neutral-300">
          <GrowLogo />
        </div>
        <div className="flex items-center flex-col gap-8 py-12">
          <div className="flex justify-center items-center shrink-0 bg-lilac-100 w-20 h-20 rounded-[7.5rem] border-2 outline-neutral-800 shadow-flat-left-shallow">
            <Icon name="hand-holding-heart" size="2xl" />
          </div>
          <Text
            as={"h1"}
            variant="2xl"
            className="font-medium text-center px-2"
          >
            Thank you for letting us know.
          </Text>
          <Text variant="md" className="text-center px-2 max-w-[31rem]">
            Your time is valuable to us and we&apos;ll review this issue to
            prevent this from happening again.
          </Text>
          <NoShowActionList />
          <Text className="px-2 text-center text-neutral-700" variant="sm">
            Is your provider now available?{" "}
            <Link
              href={`/${providerShortId}/${patientShortId}`}
              className="rebrand:font-normal text-sm"
            >
              Rejoin waiting room <Icon name="arrow-right" />
            </Link>
          </Text>
        </div>
      </PageContainer>
    </ThemeProvider>
  );
}
