import { useAtomValue } from "jotai";
import { useState } from "react";
import { EditAppointmentFormValues } from "../EditAppointmentDrawer";
import { scheduledPatientInformationAtom } from "../../schedule-preview/state";
import {
  checkRecurrenceValidity,
  getSuggestedNextStartAndEndTimes,
  getTimeInputValidationMessage,
} from "../utils";
import { AddAppointmentDrawerHeaderWrapper as AddAppointmentDrawerHeader } from "./AddAppointmentDrawerHeader";
import { AddAppointmentDrawerFooterWrapper as AddAppointmentDrawerFooter } from "./AddAppointmentDrawerFooter";
import { AddAppointmentDrawerBodyWrapper as AddAppointmentDrawerBody } from "./AddAppointmentDrawerBody";
import { AppointmentLocation } from "../../schedule-preview/types";
import { useLocalStorage } from "react-use";
import {
  ADD_APPOINTMENT_FORM_ERROR,
  DEFAULT_APPOINTMENT_RECURRING_KEY,
} from "../constants";
import { selectedClientInfoAtom } from "../../state";
import { useGetPatientByShortId } from "../../useGetPatientByShortId";
import { Card, Icon, Text } from "@grow-therapy-team/sprout-ui";

export function AddAppointmentDrawer({
  formValues,
  onUpdateValues,
  timeInputErrorMessage,
  recurrenceIsInvalid,
  isLoading,
  providerId,
  patientCustomerId,
  patientShortId,
  isError,
}: {
  formValues: EditAppointmentFormValues;
  onUpdateValues: (values: Partial<EditAppointmentFormValues>) => void;
  timeInputErrorMessage?: string;
  recurrenceIsInvalid: boolean;
  isLoading?: boolean;
  providerId?: string;
  patientCustomerId?: string;
  patientShortId?: string;
  isError?: boolean;
}) {
  const providerOrPatientIdIsMissing =
    (!providerId || !patientCustomerId) && !isLoading;
  const isValid = !isError && !providerOrPatientIdIsMissing;
  const timeInputIsInvalid = !!timeInputErrorMessage;
  const isSaveDisabled = timeInputIsInvalid || recurrenceIsInvalid;
  return (
    <>
      <AddAppointmentDrawerHeader />
      {isValid ? (
        <AddAppointmentDrawerBody
          formValues={formValues}
          onUpdateValues={onUpdateValues}
          timeInputErrorMessage={timeInputErrorMessage}
        />
      ) : (
        <Card
          data-testid="add-appointment.body.error"
          className="flex flex-row items-center mx-4"
        >
          <Icon name="warning" className="mr-2" color="#CC6F52" />
          <Text variant="xs" className="text-left fs-exclude">
            {ADD_APPOINTMENT_FORM_ERROR}
          </Text>
        </Card>
      )}
      {isValid && (
        <AddAppointmentDrawerFooter
          isSaveDisabled={isSaveDisabled}
          formValues={formValues}
          isLoading={isLoading}
          providerId={providerId || ""}
          patientShortId={patientShortId || ""}
          patientCustomerId={patientCustomerId || ""}
        />
      )}
    </>
  );
}

export function AddAppointmentDrawerWrapper() {
  const appointmentData = useAtomValue(scheduledPatientInformationAtom);
  const [formValues, setFormValues] = useState<
    Partial<EditAppointmentFormValues>
  >({});

  const scheduledPatientInformation = useAtomValue(selectedClientInfoAtom);
  const {
    loading: isPatientLoading,
    data: patientChartData,
    error,
  } = useGetPatientByShortId(scheduledPatientInformation?.patientShortId);

  const patientCustomerId = patientChartData?.patientChart?.customerId;
  const patientShortId = patientChartData?.patientChart?.shortId;
  const providerId = patientChartData?.patientChart?.provider?.id;
  const appointmentStartDate = appointmentData?.appointmentStartTime
    ? new Date(appointmentData.appointmentStartTime)
    : undefined;

  const appointmentEndDate = appointmentData?.appointmentEndTime
    ? new Date(appointmentData.appointmentEndTime)
    : undefined;

  const { suggestedStartDate, suggestedEndDate } =
    getSuggestedNextStartAndEndTimes(appointmentStartDate, appointmentEndDate);
  const [defaultRecurrence] = useLocalStorage(
    DEFAULT_APPOINTMENT_RECURRING_KEY,
    "true",
  );
  const values = {
    startDate: formValues.startDate || suggestedStartDate,
    endDate: formValues.endDate || suggestedEndDate,
    weeksBetween: formValues.weeksBetween || 1,
    numRecurrences: formValues.numRecurrences || 1,
    indefinite: formValues.indefinite || false,
    appointmentLocation:
      formValues.appointmentLocation || AppointmentLocation.Virtual,
    isRecurring: formValues.isRecurring ?? defaultRecurrence === "true" ?? true,
  };
  const updateValues = (updates: Partial<EditAppointmentFormValues>): void => {
    setFormValues((prevValues) => ({
      ...prevValues,
      ...updates,
    }));
  };
  const { isFrequencyValid, isRecurrencesValid } =
    checkRecurrenceValidity(values);

  const recurrenceIsInvalid = !isFrequencyValid || !isRecurrencesValid;

  const timeInputErrorMessage = getTimeInputValidationMessage(
    values.startDate,
    values.endDate,
  );

  return (
    <AddAppointmentDrawer
      formValues={values}
      onUpdateValues={updateValues}
      timeInputErrorMessage={timeInputErrorMessage}
      recurrenceIsInvalid={recurrenceIsInvalid}
      isLoading={isPatientLoading}
      providerId={providerId}
      patientShortId={patientShortId}
      patientCustomerId={patientCustomerId}
      isError={!!error && !isPatientLoading}
    />
  );
}
