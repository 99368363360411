import {
  ButtonSize,
  IconButton,
  LoadingScreen as VideoThumbnailLoadingBackdrop,
  Text,
  InitialsAvatar,
  InitialsAvatarSize,
  AvatarAccent,
} from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";
import {
  DetailedHTMLProps,
  FC,
  MutableRefObject,
  VideoHTMLAttributes,
  useState,
} from "react";
import classNames from "classnames";
import { nameToInitials } from "../utils";
import { ActiveSpeakerOutline, NameBadge } from ".";
import { nonEssentialElementProps } from "../hooks";
import { useAtom } from "jotai";
import { thumbnailPositionAtom } from "../twilio";
import { SetPositionMenu } from "./SetPositionMenu";
import { HideSelfViewButton } from "./HideSelfViewButton";
import {
  faArrowDownLeftAndArrowUpRightToCenter,
  faArrowUpRightAndArrowDownLeftFromCenter,
} from "@fortawesome/pro-solid-svg-icons";

export type VideoThumbnailProps = {
  name: string;
  pronouns?: string[];
  className?: string;
  videoClassName?: string;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  audioTransmissionEnabled?: boolean;
  videoComponent: FC<
    DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>
  >;
  videoTransmissionEnabled?: boolean;
  showExpandVideoToggle?: boolean;
  isSettingsThumbnail?: boolean;
  showSetPositionMenu?: boolean;
  loadingVideo?: boolean;
  isMirrored?: boolean;
  helpText?: string;
  isSelfViewHidden?: boolean;
  setIsSelfViewHidden: () => void;
  hideSelfViewClassName?: string;
  isMobile?: boolean;
  isSpeaking?: boolean;
  shouldShowHideSelf?: boolean;
  videoOffContent?: JSX.Element;
};

function ExpandVideoToggle({
  isExpanded,
  onToggle,
}: {
  isExpanded: boolean;
  onToggle: () => void;
}) {
  return (
    <IconButton
      aria-label="Maximize or minimize video"
      iconDefinition={
        isExpanded
          ? faArrowDownLeftAndArrowUpRightToCenter
          : faArrowUpRightAndArrowDownLeftFromCenter
      }
      className="top-2 left-2 absolute hidden sm:flex group-hover:opacity-75 focus:opacity-100 opacity-0 px-2 py-0.5 h-7 items-center rebrand:shadow-border-none rebrand:bg-neutral-800 rebrand:hover:bg-neutral-800 rebrand:hover:opacity-100 rebrand:active:bg-neutral-800 rebrand:active:text-neutral-300 rebrand:text-neutral-300 rebrand:focus-visible:outline-none rebrand:focus-visible:outline-lilac-700 focus-visible:shadow-border-none rebrand:focus-visible:shadow-border-none"
      size={ButtonSize.Small}
      onClick={onToggle}
    />
  );
}

function VideoThumbnailContent({
  expandedVideo,
  name,
  setExpandedVideo,
  showExpandVideoToggle = true,
  videoComponent: VideoComponent,
  videoTransmissionEnabled,
  isMirrored,
  helpText,
  videoOffContent,
}: Pick<
  VideoThumbnailProps,
  | "name"
  | "showExpandVideoToggle"
  | "videoComponent"
  | "videoTransmissionEnabled"
  | "isMirrored"
  | "helpText"
  | "videoOffContent"
> & {
  expandedVideo: boolean;
  setExpandedVideo: (setter: (expanded: boolean) => boolean) => void;
}) {
  const displayInitials = nameToInitials(name);
  let videoOffText: JSX.Element | null = null;
  if (helpText) {
    videoOffText = (
      <Text
        variant={"md"}
        className="relative text-neutral-300 fs-exclude text-center p-4"
        data-dd-privacy="mask"
      >
        {helpText}
      </Text>
    );
  } else if (videoOffContent) {
    videoOffText = videoOffContent;
  } else if (displayInitials) {
    videoOffText = (
      <InitialsAvatar
        initials={displayInitials}
        size={InitialsAvatarSize.sm}
        className="text-neutral-300 text-center"
        accent={AvatarAccent.Green}
      />
    );
  }

  return videoTransmissionEnabled ? (
    <>
      <VideoComponent
        className={twMerge(
          classNames("absolute w-full h-full fs-exclude", {
            "scale-x-[-1]": isMirrored,
          }),
        )}
        muted
      />
      {showExpandVideoToggle && (
        <ExpandVideoToggle
          isExpanded={expandedVideo}
          onToggle={() => {
            setExpandedVideo((current) => !current);
          }}
        />
      )}
    </>
  ) : (
    videoOffText
  );
}

export function VideoThumbnail({
  name,
  pronouns,
  className,
  videoClassName,
  audioTransmissionEnabled,
  containerRef,
  videoComponent,
  videoTransmissionEnabled,
  shouldShowHideSelf = true,
  showExpandVideoToggle = true,
  showSetPositionMenu,
  loadingVideo,
  isMirrored,
  isSettingsThumbnail,
  helpText,
  isSelfViewHidden,
  setIsSelfViewHidden,
  isMobile,
  isSpeaking,
  videoOffContent,
}: VideoThumbnailProps) {
  const [expandedVideo, setExpandedVideo] = useState(false);
  const [thumbnailPosition, setThumbnailPosition] = useAtom(
    thumbnailPositionAtom,
  );

  const shouldExpandVideo = expandedVideo && videoTransmissionEnabled;

  return (
    <div
      className={twMerge(
        classNames("group h-fit w-fit z-10 relative", className, {
          [thumbnailPosition]: showSetPositionMenu,
        }),
      )}
      ref={containerRef}
    >
      <div
        className={twMerge(
          classNames(
            "relative flex justify-center overflow-hidden rounded-2xl items-center transition-all rebrand:border-0 rebrand:bg-neutral_rebrand-900",
            {
              "min-w-[14rem] min-h-[7.875rem] max-w-[14rem] max-h-[7.875rem]":
                !shouldExpandVideo,
              "min-w-[40rem] min-h-[22rem] max-w-[40rem] max-h-[22rem]":
                shouldExpandVideo,
              "border border-neutral-700 rounded-2xl bg-neutral-900":
                !videoTransmissionEnabled,
            },
          ),
          videoClassName,
        )}
        data-testid="video-thumbnail"
      >
        {loadingVideo ? (
          <VideoThumbnailLoadingBackdrop
            className="absolute w-full h-full"
            size="md"
          />
        ) : (
          <VideoThumbnailContent
            {...{
              expandedVideo,
              name,
              setExpandedVideo,
              showExpandVideoToggle,
              videoTransmissionEnabled,
              isMirrored,
              helpText,
              videoComponent,
              videoOffContent,
            }}
          />
        )}
        {!audioTransmissionEnabled && !isSettingsThumbnail && (
          <NameBadge
            className={`absolute bottom-0 left-0 ${nonEssentialElementProps.className}`}
            audioMuted={!audioTransmissionEnabled}
          />
        )}
        {isSettingsThumbnail && name && (
          <NameBadge
            className={`absolute bottom-0 left-0 ${nonEssentialElementProps.className}`}
            name={name}
            pronouns={pronouns}
            audioMuted={!audioTransmissionEnabled}
          />
        )}
        <ActiveSpeakerOutline
          data-testid="video-thumbnail.active-speaker-outline"
          isOutlined={!!isSpeaking}
        />
      </div>
      {shouldShowHideSelf && videoTransmissionEnabled && (
        <HideSelfViewButton
          isMobile={!!isMobile}
          onClick={setIsSelfViewHidden}
          isSelfViewHidden={!!isSelfViewHidden}
        />
      )}
      {showSetPositionMenu && (
        <SetPositionMenu
          isMobile={!!isMobile}
          position={thumbnailPosition}
          setPosition={setThumbnailPosition}
        />
      )}
    </div>
  );
}
