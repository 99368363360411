import {
  faPause,
  faPenLine,
  faPencilSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { Icon, Tag, Tooltip } from "@grow-therapy-team/sprout-ui";
import { ConsentStatus } from "./types";

type TranscriptionPillProps = {
  isRecording: boolean;
  patientConsent: ConsentStatus;
};

export function TranscriptionPill({
  isRecording,
  patientConsent,
}: TranscriptionPillProps) {
  if (patientConsent === ConsentStatus.PENDING) {
    return (
      <Tooltip
        className={"rebrand:bg-neutral-900"}
        text={
          <div className="max-w-[80vw] sm:max-w-[24.5rem]">
            To transcribe this session, press the{" "}
            <Icon icon={faPenLine} aria-label="request transcription consent" />{" "}
            button in the action bar below to request client consent for
            transcription.
          </div>
        }
      >
        <Tag className="rebrand:bg-neutral-900 rebrand:text-neutral-500 gap-2 flex items-center">
          <Icon icon={faPencilSlash} />
          Not transcribing
        </Tag>
      </Tooltip>
    );
  }

  if (isRecording) {
    return (
      <Tag className="rebrand:bg-neutral-900 rebrand:text-neutral-500 gap-2 flex items-center">
        <Icon icon={faPenLine} className="text-lilac-500" />
        Transcribing
      </Tag>
    );
  }

  return (
    <Tag className="rebrand:bg-neutral-900 rebrand:text-neutral-500 gap-2 flex items-center">
      <Icon icon={faPause} className="text-lilac-500" />
      Transcription paused
    </Tag>
  );
}
