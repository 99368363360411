import { useLayer } from "statsig-react";

import { EnhancedPageContentWrapper as EnhancedPageContent } from "./EnhancedPageContent";
import { LoadingPageContent } from "./LoadingPageContent";
import { MinimalPageContent } from "./MinimalPageContent";
import { ExperimentLayers } from "../../../statsig/experiments";

interface PageContentProps {
  isLoading: boolean;
  clientIntroStateV2IsEnabled: boolean;
  className?: string;
}
export function PageContentComponent({
  isLoading,
  clientIntroStateV2IsEnabled,
  className,
}: PageContentProps) {
  if (isLoading) return <LoadingPageContent />;

  if (clientIntroStateV2IsEnabled)
    return <EnhancedPageContent className={className} />;

  return <MinimalPageContent className={className} />;
}

export function PageContent({ className }: { className?: string }) {
  const [layerName, layerConfig] = ExperimentLayers.ClientWaitingRoom;
  const { isLoading, layer } = useLayer(layerName);
  const shouldShowAVSetup = layer.get<boolean>(
    layerConfig.ShouldShowAvSetup,
    false,
  );

  return (
    <PageContentComponent
      isLoading={isLoading}
      clientIntroStateV2IsEnabled={shouldShowAVSetup}
      className={className}
    />
  );
}
