import {
  Button,
  ButtonUse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@grow-therapy-team/sprout-ui";

export function TakeOverScreenShareModal({
  isModalOpen,
  onCloseModal,
  onConfirmShare,
}: {
  isModalOpen: boolean;
  onCloseModal: () => void;
  onConfirmShare: () => void;
}) {
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onCloseModal}
      onOutsideClick={onCloseModal}
    >
      <ModalHeader>Stop other’s screen share?</ModalHeader>
      <ModalBody>
        Sharing your screen will stop the other person’s screen share. Do you
        want to continue?
      </ModalBody>
      <ModalFooter
        actions={
          <>
            <Button use={ButtonUse.SecondaryTransparent} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button onClick={onConfirmShare}>Yes, share my screen</Button>
          </>
        }
      />
    </Modal>
  );
}
