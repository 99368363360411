import { Alert, AlertUse } from "@grow-therapy-team/sprout-ui";
import { PageComponentProps } from "../../components";
import { useGetClientMeasureBundles } from "./useGetClientMeasureBundles";
import { DrawerState, drawerStateAtom, selectedClientInfoAtom } from "../state";
import { sessionInfoAtom } from "../../state";
import { useAtomValue, useSetAtom } from "jotai";
import { nameToInitials } from "../../utils";
import { MeasureSchemaAndAnswers } from "../../components/measures/types";
import { MeasuresActions } from "../../components/measures/MeasuresActions";
import { MeasuresContent } from "../../components/measures/MeasuresContent";
import { MeasuresDrawer } from "../../components/measures/MeasuresDrawer";
import { useSubmitMeasureResponseCallback } from "../../hooks/measures/useSubmitMeasureResponseCallback";

function MeasuresContentWrapper(
  props: PageComponentProps<MeasureSchemaAndAnswers>,
) {
  const clientName = useAtomValue(selectedClientInfoAtom)?.name;

  return (
    <MeasuresContent {...props}>
      {clientName && (
        <Alert
          use={AlertUse.InfoActive}
          title={`You're submitting this on behalf of ${nameToInitials(
            clientName,
          )}`}
        >
          Please confirm with your client that this information is accurate.
        </Alert>
      )}
    </MeasuresContent>
  );
}

function MeasuresActionsWrapper(
  props: PageComponentProps<MeasureSchemaAndAnswers>,
) {
  const submitMeasureResponse = useSubmitMeasureResponseCallback();
  const clientUserShortId =
    useAtomValue(sessionInfoAtom)?.appointment?.clientUserShortId;
  const setDrawerState = useSetAtom(drawerStateAtom);
  return (
    <MeasuresActions
      {...props}
      submitMeasure={async () => {
        const { status } = await submitMeasureResponse({
          ...props,
          clientUserShortId,
          completionCampaign: "CLIENT_INFO_IN_SESSION",
        });
        if (status === "success" && props.isLastPage) {
          setDrawerState(DrawerState.PENDING_FORMS_COMPLETED);
        }
      }}
    />
  );
}

export function PendingMeasuresDrawerWrapper() {
  const { data } = useGetClientMeasureBundles();
  const mostRecentBundle =
    data?.clientSentMeasureBundles?.[0]?.measureBundleItems;
  const pages = mostRecentBundle?.map((item) => ({
    schema: item?.measureSchema,
    answers: {},
    errors: [] as string[],
    hasSubmitted: false,
  })) as MeasureSchemaAndAnswers[];

  return (
    <MeasuresDrawer
      pages={pages}
      contentComponent={MeasuresContentWrapper}
      actionsComponent={MeasuresActionsWrapper}
    />
  );
}
