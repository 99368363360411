import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import {
  Button,
  ButtonUse,
  CalloutBanner,
  CalloutBannerTheme,
  Icon,
} from "@grow-therapy-team/sprout-ui";

export function RefreshAppointmentsCallout({
  onClickRefresh,
}: {
  onClickRefresh: () => void;
}) {
  return (
    <CalloutBanner
      heading="Cancelled an appointment?"
      headingVariant="md"
      theme={CalloutBannerTheme.Lilac}
      className="bg-neutral-100"
      compact
      actionGroup={
        <Button
          use={ButtonUse.Secondary}
          aria-label="Refresh appointment list"
          className="flex gap-x-2 items-center"
          onClick={onClickRefresh}
        >
          Refresh
          <Icon icon={faArrowsRotate} />
        </Button>
      }
    >
      You may need to refresh this list to see the update.
    </CalloutBanner>
  );
}
