import {
  CalloutBanner,
  CalloutBannerTheme,
} from "@grow-therapy-team/sprout-ui";

import { faVideo } from "@fortawesome/pro-solid-svg-icons";

export function AVPermissionCallout() {
  return (
    <CalloutBanner compact theme={CalloutBannerTheme.Lilac} icon={faVideo}>
      You may see a pop-up requesting access to your camera and microphone once
      you enter the waiting room.
    </CalloutBanner>
  );
}
