import {
  BackgroundAnimationOverlay as BackgroundAnimation,
  Navbar,
  PageContainer,
  PushDrawerContainer,
  StageContainer,
  ThemeProvider,
} from "../../components";
import { PushDrawerWrapper as PushDrawer } from "../PushDrawer";
import { useSetAtom } from "jotai";
import { visitorClientShortIdAtom } from "../state";
import { Theme } from "../../state";
import { useParams } from "react-router-dom";
import { useGetTelehealthSessionInfo } from "../../hooks";
import { PageContent } from "./page-content/PageContent";

export function Page() {
  const { providerShortId, patientShortId } = useParams();

  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const appointment = data?.telehealthSessionInfo?.appointment;
  const setClientShortId = useSetAtom(visitorClientShortIdAtom);

  setClientShortId(appointment?.clientUserShortId ?? "");
  return (
    <>
      <ThemeProvider theme={Theme.LIGHT}>
        <PageContainer className="relative flex flex-col overflow-auto sm:overflow-hidden bg-neutral-100 rebrand:bg-neutral-300">
          <BackgroundAnimation autoplay={false} />
          <div className="relative flex flex-col h-full w-full sm:overflow-auto">
            <Navbar />

            <PushDrawerContainer
              drawer={<PushDrawer className="sm:mt-2 sm:h-[calc(100%-16px)]" />}
            >
              <StageContainer className="bg-transparent overflow-auto pb-24 pt-12 justify-normal">
                <PageContent />
              </StageContainer>
            </PushDrawerContainer>
          </div>
        </PageContainer>
      </ThemeProvider>
    </>
  );
}
