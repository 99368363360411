import { parseIdentity } from "../utils";
import { twMerge } from "tailwind-merge";
import { ActiveSpeakerOutline, NameBadge } from "../../components";
import { nonEssentialElementProps } from "../../hooks";
import {
  AvatarAccent,
  InitialsAvatar,
  InitialsAvatarSize,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { nameToInitials } from "../../utils";
import { useAtomValue } from "jotai";
import {
  activelySpeakingParticipantIdsAtom,
  isOwnAudioOffAtom,
  isOwnVideoOffAtom,
  isScreenShareModeAtom,
  trackStatusByParticipantIdAtom,
  userDataFamily,
} from "..";
import { UserType } from "../../types";
import classNames from "classnames";
import { UseRemoteTracksProps, useRemoteTracks } from "../useRemoteTracks";
import { useTriggerPictureInPicture } from "./useTriggerPictureInPicture";
import { RefObject } from "react";
import { useMatch } from "react-router-dom";
import { PROVIDER_PATH } from "../../config";

export type NameBadgeWrapperProps = {
  className?: string;
  participantIdentity: string;
};

function NameBadgeWrapper({
  className,
  participantIdentity,
}: NameBadgeWrapperProps) {
  const { name: participantName } = parseIdentity(participantIdentity ?? "");
  const audioTrackStatus = useAtomValue(trackStatusByParticipantIdAtom)[
    participantIdentity ?? ""
  ];
  const userData = useAtomValue(userDataFamily(participantIdentity));

  return (
    <NameBadge
      audioMuted={audioTrackStatus?.isAudioMuted ?? true}
      name={participantName}
      pronouns={userData?.pronouns}
      className={className}
    />
  );
}

export type RemoteParticipantProps = {
  className?: string;
  isProviderView?: boolean;
  isVideoOff?: boolean;
  isOwnAudioOff?: boolean;
  isOwnVideoOff?: boolean;
  isScreenShareMode?: boolean;
  isSpeaking?: boolean;
  videoRef: RefObject<HTMLVideoElement>;
  audioRef: RefObject<HTMLAudioElement>;
  nameBadgeComponent: typeof NameBadgeWrapper;
} & UseRemoteTracksProps;

export type RemoteParticipantWrapperProps = Pick<
  RemoteParticipantProps,
  "className"
> &
  UseRemoteTracksProps;

function getVideoOffMessaging(
  subject: string,
  isOwnVideoOff?: boolean,
  isOwnAudioOff?: boolean,
) {
  const tokens = [];
  if (!isOwnVideoOff) tokens.push(" see");
  if (!isOwnAudioOff) tokens.push(" hear");
  const videoOffAction = tokens.join(" and");
  const sentenceEnding =
    isOwnVideoOff && isOwnAudioOff
      ? "."
      : ` but they can still ${videoOffAction} you.`;

  return `${subject} has their camera off${sentenceEnding}`;
}

export function RemoteParticipant({
  audioRef,
  className,
  isOwnAudioOff,
  isOwnVideoOff,
  isProviderView,
  isScreenShareMode,
  isSpeaking,
  isVideoOff,
  nameBadgeComponent: NameBadgeComponent,
  remoteParticipant,
  videoRef,
}: RemoteParticipantProps) {
  const {
    name: participantName,
    id,
    userType,
  } = parseIdentity(remoteParticipant?.identity ?? "");
  const remoteParticipantIsProvider = userType === UserType.PROVIDER;
  const displayInitials = participantName
    ? nameToInitials(participantName)
    : "";

  let videoOffMessageSubject = remoteParticipantIsProvider
    ? "Your provider"
    : "This person";

  if (isProviderView) {
    videoOffMessageSubject = "Your client";
  }
  const showVideoOffMessaging = !isScreenShareMode;
  const videoOffMessaging = getVideoOffMessaging(
    videoOffMessageSubject,
    isOwnVideoOff,
    isOwnAudioOff,
  );

  return (
    <div
      className={twMerge(
        "relative overflow-hidden bg-neutral-900 rebrand:bg-neutral_rebrand-900 rounded-2xl flex h-full w-full justify-center items-center",
        className,
      )}
      data-testid={`remote-audio-video:${id}`}
    >
      {isVideoOff && (
        <div className="absolute flex flex-col items-center">
          <InitialsAvatar
            initials={displayInitials}
            size={
              isScreenShareMode ? InitialsAvatarSize.sm : InitialsAvatarSize.md
            }
            className={classNames(
              "z-50 text-neutral-300 text-center flex items-center justify-center",
              {
                "w-20 h-20": !isScreenShareMode,
              },
            )}
            accent={AvatarAccent.Green}
          />
          {showVideoOffMessaging && (
            <Text
              variant="sm"
              className="text-neutral-500 p-4 text-center"
              data-testid="video-off-messaging"
            >
              {videoOffMessaging}
            </Text>
          )}
        </div>
      )}
      {/* TODO: Add closed captioning */}
      <video // eslint-disable-line  jsx-a11y/media-has-caption
        className="relative w-screen sm:w-full h-full fs-exclude"
        data-testid={`remote-video:${id}`}
        muted
        ref={videoRef}
      />
      <audio // eslint-disable-line  jsx-a11y/media-has-caption
        data-testid={`remote-audio:${id}`}
        className="remote-audio"
        ref={audioRef}
      />
      {participantName && (
        <NameBadgeComponent
          participantIdentity={remoteParticipant.identity}
          className={`absolute bottom-0 left-0 ${nonEssentialElementProps.className}`}
        />
      )}
      <ActiveSpeakerOutline isOutlined={!!isSpeaking} />
    </div>
  );
}

export function RemoteParticipantWrapper({
  remoteParticipant,
  className,
  ...listeners
}: RemoteParticipantWrapperProps) {
  const { audioRef, videoRef } = useRemoteTracks({
    remoteParticipant,
    ...listeners,
  });
  const isOwnAudioOff = useAtomValue(isOwnAudioOffAtom);
  const isOwnVideoOff = useAtomValue(isOwnVideoOffAtom);
  const trackStatusByParticipantId = useAtomValue(
    trackStatusByParticipantIdAtom,
  );
  const videoTrackStatus =
    trackStatusByParticipantId[remoteParticipant.identity ?? ""];
  const isVideoOff = videoTrackStatus?.isVideoOff ?? false;
  const isScreenShareMode = useAtomValue(isScreenShareModeAtom);
  const isSpeaking = useAtomValue(activelySpeakingParticipantIdsAtom).has(
    remoteParticipant.identity,
  );
  const isProviderView = !!useMatch(PROVIDER_PATH);

  useTriggerPictureInPicture(remoteParticipant?.identity ?? "", videoRef);

  return (
    <RemoteParticipant
      audioRef={audioRef}
      className={className}
      isOwnAudioOff={isOwnAudioOff}
      isOwnVideoOff={isOwnVideoOff}
      isProviderView={isProviderView}
      isScreenShareMode={isScreenShareMode}
      isSpeaking={isSpeaking}
      isVideoOff={isVideoOff}
      nameBadgeComponent={NameBadgeWrapper}
      remoteParticipant={remoteParticipant}
      videoRef={videoRef}
    />
  );
}
