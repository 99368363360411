import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";

export function NameBadge({
  name,
  className,
  pronouns,
  audioMuted = false,
}: {
  name?: string;
  className?: string;
  pronouns?: string[];
  audioMuted?: boolean;
}) {
  return (
    <div
      className={twMerge(
        "bg-ivy-500 inline-flex justify-center items-center rounded-tr-2xl h-10 px-4 max-w-full box-border",
        className,
      )}
      data-testid="name-badge"
    >
      {audioMuted && (
        <Icon
          aria-label="Microphone off"
          name="microphone-slash"
          className={twMerge("text-neutral-000", name && "mr-2")}
          data-testid="audio-muted-badge"
        />
      )}
      {name && (
        <Text
          title={name}
          className="inline-block text-neutral-000 font-semibold rebrand:font-medium text-ellipsis overflow-hidden whitespace-nowrap fs-exclude"
          data-dd-privacy="mask"
          data-dd-action-name="Click on name badge"
          data-testid="name-badge.name"
        >
          {name}
          {(pronouns?.length ?? 0) > 0 && (
            <span className="font-normal" data-testid="name-badge.pronouns">
              {" "}
              ({pronouns![0]})
            </span>
          )}
        </Text>
      )}
    </div>
  );
}
