import { Button, ButtonUse } from "@grow-therapy-team/sprout-ui";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type Messages = {
  content: string;
  render?: ReactNode;
}[];

export type CannedMessagesProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "onSelect"
> & {
  messages: Messages;
  disabled?: boolean;
  onSelect: (message: string) => void;
};

export function CannedMessages({
  messages,
  onSelect,
  disabled,
  className,
  ...otherProps
}: CannedMessagesProps) {
  return (
    <div className={twMerge("flex gap-2", className)} {...otherProps}>
      {messages.map(({ content, render }) => (
        <Button
          key={content}
          disabled={disabled}
          className="text-small mx-0 min-w-[2.25rem] px-3 py-2 flex items-center justify-center"
          use={ButtonUse.Secondary}
          onClick={() => onSelect(content)}
        >
          {render ?? content}
        </Button>
      ))}
    </div>
  );
}
