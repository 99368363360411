import { Icon, Link, Tag, TagUse, Text } from "@grow-therapy-team/sprout-ui";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { getAppConfig } from "../../config";
import { twMerge } from "tailwind-merge";
import { useGetReadyAvsCountQuery } from "./useGetReadyAvsCountQuery";

type AvsCtaPillProps = {
  numSummaries: number;
  className?: string;
};

const providerDashboardUri = `${getAppConfig().providerOrigin}/dashboard`;

export function AvsCtaPill({ numSummaries, className }: AvsCtaPillProps) {
  if (!numSummaries) return;

  const sessionSummaryText =
    numSummaries === 1
      ? "session summary is available"
      : "session summaries are available";

  return (
    <Tag
      data-testid="avs-cta-pill"
      use={TagUse.Neutral}
      className={twMerge(
        "normal-case rebrand:bg-neutral-900 gap-2 w-fit flex items-center",
        className,
      )}
    >
      <Icon className="rebrand:text-lilac-400" icon={faSparkles} />
      <Text variant="sm" className="rebrand:text-neutral-000">
        {numSummaries} {sessionSummaryText} in
      </Text>{" "}
      <Link
        className="rebrand:text-neutral-000 text-sm"
        href={providerDashboardUri}
        external
      >
        your portal
      </Link>
    </Tag>
  );
}

export function AvsCtaPillWrapper(props: Pick<AvsCtaPillProps, "className">) {
  const { data, loading } = useGetReadyAvsCountQuery();
  const numSummaries = data?.afterVisitSummariesReady?.totalReadyForReview ?? 0;

  if (loading) return;

  return <AvsCtaPill {...props} numSummaries={numSummaries} />;
}
