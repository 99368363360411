import { Tag, Text, TileButton } from "@grow-therapy-team/sprout-ui";
import {
  AppointmentLocation,
  AppointmentType,
  ScheduledAppointment,
} from "./types";
import { getAppointmentTagColor, getAppointmentTypeText } from "./utils";
import { IN_PERSON_SUFFIX } from "./constants";
import { useOpenClientInformationCallback } from "../client-information";
import { useSetAtom } from "jotai";
import { scheduledPatientInformationAtom } from "./state";
import classNames from "classnames";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";

export function SchedulePreviewAppointment({
  appointment,
  onOpenClientInformation,
}: {
  appointment: NonNullable<ScheduledAppointment>;
  onOpenClientInformation: () => void;
}) {
  const { patient, timeStart, appointmentType, inOffice } = appointment;
  const appointmentIsInPerson = inOffice === AppointmentLocation.InOffice;
  const appointmentIsConsultation =
    appointmentType === AppointmentType.Consultation;
  return (
    <>
      <TileButton
        compact
        className="sm:min-w-[25rem]"
        onClick={() => onOpenClientInformation()}
      >
        <Text variant="label" className="text-neutral-600">
          {new Date(timeStart).toLocaleString([], {
            hour: "numeric",
            minute: "numeric",
          })}
          {appointmentIsInPerson ? IN_PERSON_SUFFIX : ""}
        </Text>
        <div className="mt-1 flex items-center justify-between">
          <Text
            className="fs-exclude pr-3"
            data-dd-privacy="mask"
            data-dd-action-name="Click on client name"
          >
            {appointmentType === AppointmentType.Consultation
              ? "Consultation"
              : "Appointment"}{" "}
            with {patient.preferredFullName}
          </Text>
          <Tag
            excludeMargin
            use={getAppointmentTagColor(appointmentType)}
            className={classNames("flex gap-x-2", {
              "hidden sm:flex": appointmentIsConsultation,
            })}
          >
            {getAppointmentTypeText(appointmentType)}
          </Tag>
          {/* Consultation has shortened text on mobile to preserve layout */}
          <Tag
            excludeMargin
            use={getAppointmentTagColor(appointmentType)}
            className={classNames("gap-x-2 hidden", {
              "flex sm:hidden": appointmentIsConsultation,
            })}
          >
            Consult
          </Tag>
        </div>
      </TileButton>
    </>
  );
}

export function SchedulePreviewAppointmentWrapper({
  appointment,
  appointmentPosition,
}: {
  appointment: ScheduledAppointment;
  appointmentPosition: number;
}) {
  const setScheduledPatientInfo = useSetAtom(scheduledPatientInformationAtom);
  const { userClicked } = useTrackEvent();
  const openClientInformation = useOpenClientInformationCallback();
  const patientShortId = appointment?.patient.shortId;
  const patientName = appointment?.patient.preferredFullName;
  if (!patientShortId || !patientName || !appointment) return;
  const onOpenClientInformation = () => {
    setScheduledPatientInfo({
      name: patientName,
      patientShortId: patientShortId,
      appointmentType: appointment.appointmentType,
      appointmentStartTime: appointment.timeStart,
      appointmentEndTime: appointment.timeEnd,
      recurringAppointmentGrouping: appointment.recurringAppointmentGrouping,
      appointmentLocation: appointment.inOffice,
      appointmentId: appointment.id,
      appointmentShortId: appointment.appointmentShortId,
      patientId: appointment.patient.id,
    });
    openClientInformation(patientShortId);
    userClicked(EventTypeEnum.WAITING_ROOM_SCHEDULE, {
      appointmentPosition,
      appointmentShortId: appointment.appointmentShortId,
    });
  };
  return (
    <SchedulePreviewAppointment
      appointment={appointment}
      onOpenClientInformation={() => onOpenClientInformation()}
    />
  );
}
