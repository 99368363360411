import toast from "react-hot-toast";
import { useState, useCallback } from "react";
import { secondsToMilliseconds } from "date-fns";
import { ClientDeclinedTranscriptionToast } from "./ClientDeclinedTranscriptionToast";

export function useClientDeclinedTranscriptionToastCallback() {
  const [showingToast, setShowingToast] = useState(false);
  return useCallback(() => {
    if (!showingToast) {
      toast.custom(ClientDeclinedTranscriptionToast, {
        position: "bottom-center",
        duration: secondsToMilliseconds(30),
      });
      setShowingToast(true);
    }
  }, [showingToast]);
}
