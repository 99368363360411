import {
  Text,
  Button,
  ButtonUse,
  Icon,
  Card,
  CardSection,
} from "@grow-therapy-team/sprout-ui";
import { faPause } from "@fortawesome/pro-solid-svg-icons";

type RecordingNoticeProps = {
  onClose: () => void;
};

export function RecordingNotice({ onClose }: RecordingNoticeProps) {
  return (
    <Card className="sm:max-w-[25rem]">
      <CardSection className="pb-4">
        <Text variant="sm" className="text-neutral-800">
          The session is being transcribed. You can pause transcription from the{" "}
          <Icon icon={faPause} /> button.
        </Text>
      </CardSection>
      <CardSection className="pb-4">
        <Button onClick={onClose} use={ButtonUse.Primary} className="w-full">
          Got it
        </Button>
      </CardSection>
    </Card>
  );
}
