import { faPenLine } from "@fortawesome/pro-solid-svg-icons";
import { FormListItemBadge } from "./FormListItemBadge";
import { FormListItemLayout } from "./FormListItemLayout";
import { FormListItemContent } from "./FormListItemContent";
import { HTMLAttributes } from "react";
import { FormType, GroupedFormData } from "../types";
import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { FormListItemBadgeVariant } from "./types";
import { useLayer } from "statsig-react";
import { useAtomValue, useSetAtom } from "jotai";
import { sortedPendingFormsForSelectedClientAtom } from "../state";
import {
  DrawerState,
  drawerStateAtom,
  isInSessionWithSelectedClientAtom,
} from "../../state";
import { ExperimentLayers } from "../../../statsig/experiments";

type FormListTaskItemProps = {
  pendingForms: GroupedFormData[];
  onClick: HTMLAttributes<HTMLButtonElement>["onClick"];
};

function pendingFormToTitle(pendingForm: GroupedFormData) {
  switch (pendingForm.formType) {
    case FormType.Gad7:
    case FormType.Phq9:
      return pendingForm.formType;
    default:
      return pendingForm.formTitle;
  }
}

export function FormListTaskItem({
  pendingForms: [firstPendingForm, secondPendingForm],
  onClick,
}: FormListTaskItemProps) {
  const hasNoPendingForms = !firstPendingForm;
  const onlyHasOnePendingForm = !secondPendingForm;

  if (hasNoPendingForms) return null;

  return (
    <FormListItemLayout
      data-testid="form-list-item-task"
      buttonProps={{
        onClick,
      }}
    >
      <FormListItemBadge variant={FormListItemBadgeVariant.Task}>
        <Icon icon={faPenLine} />
      </FormListItemBadge>
      <FormListItemContent
        title={
          <Text
            variant="sm"
            className="text-neutral-600 rebrand:text-neutral_rebrand-700"
          >
            Task
          </Text>
        }
        subtitle={
          <Text>
            {`Complete ${
              onlyHasOnePendingForm
                ? pendingFormToTitle(firstPendingForm)
                : "measures"
            } together`}
          </Text>
        }
      />
    </FormListItemLayout>
  );
}

export function FormListTaskItemWrapper() {
  const [layerName, layerConfig] = ExperimentLayers.Measures;
  const { layer } = useLayer(layerName);
  const isFeatureEnabled = layer.get<boolean>(
    layerConfig.ShouldShowProviderMeasures,
    false,
  );
  const pendingForms = useAtomValue(sortedPendingFormsForSelectedClientAtom);
  const setDrawerState = useSetAtom(drawerStateAtom);
  const isInSessionWithSelectedClient = useAtomValue(
    isInSessionWithSelectedClientAtom,
  );

  if (!isFeatureEnabled || !isInSessionWithSelectedClient) return null;

  return (
    <FormListTaskItem
      pendingForms={pendingForms}
      onClick={() => {
        setDrawerState(DrawerState.PENDING_FORMS);
      }}
    />
  );
}
