import { useAtomValue } from "jotai";
import { TranscriptionPill } from "../../twilio/TranscriptionPill";
import { useRecordingControl } from "../../twilio/audio-video-controls/useRecordingControl";
import { ConsentStatus } from "../../twilio/types";
import {
  patientTranscriptionConsentAtom,
  providerTranscriptionConsentAtom,
} from "./state";
import { useParams } from "react-router-dom";

export function TranscriptionPillWrapper() {
  const { providerShortId, patientShortId } = useParams();
  const patientConsent = useAtomValue(patientTranscriptionConsentAtom);
  const providerConsent = useAtomValue(providerTranscriptionConsentAtom);

  const { isRecording, isVisible } = useRecordingControl(
    providerShortId,
    patientShortId,
  );

  const bothPartiesConsented =
    patientConsent === ConsentStatus.OPTED_IN &&
    providerConsent === ConsentStatus.OPTED_IN;

  if (!isVisible || !(bothPartiesConsented || isRecording)) {
    return null;
  }

  return (
    <TranscriptionPill
      isRecording={isRecording}
      patientConsent={patientConsent}
    />
  );
}
