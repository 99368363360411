import {
  ButtonUse,
  IconButton,
  IconButtonProps,
} from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";
import { VisualIndicator } from "./VisualIndicator";
import { useTheme } from "../hooks";
import classNames from "classnames";
import { Theme } from "../state";

/** Wraps IconButton component to ensure consistent color and sizing. **/
export function ControlPanelButton({
  className,
  containerClassName,
  showVisualIndicator = false,
  ...props
}: IconButtonProps & {
  showVisualIndicator?: boolean;
  containerClassName?: string;
}) {
  const { theme } = useTheme();
  return (
    <div className={twMerge("relative", containerClassName)}>
      <IconButton
        use={ButtonUse.Secondary}
        className={twMerge(
          classNames(
            {
              "rebrand:bg-neutral_rebrand-900 rebrand:text-neutral-100 rebrand:hover:bg-[#000000]":
                theme === Theme.DARK,
            },
            "min-w-12 max-w-12 min-h-12 max-h-12 justify-center p-0",
            className,
          ),
        )}
        {...props}
      />
      {showVisualIndicator && (
        <VisualIndicator className="absolute -top-1 -right-1" />
      )}
    </div>
  );
}
