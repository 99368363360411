import { useAtomValue, useAtom, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import toast from "react-hot-toast";
import {
  currentConversationSidAtom,
  unreadMessagesCountFamily,
  unreadMessageCountByConversationSidAtom,
  currentConversationAtom,
} from "../../twilio";
import { gracefullySetAllMessagesRead } from "../../twilio/chat/utils";
import {
  activeConversationSidAtom,
  visitorDrawerStateAtom,
  VisitorDrawerState,
} from "../state";

export function useChatDrawerButtonUtils() {
  const activeConversationSid = useAtomValue(activeConversationSidAtom);
  const [currentConversationSid, setCurrentConversationSid] = useAtom(
    currentConversationSidAtom,
  );
  const setVisitorDrawerState = useSetAtom(visitorDrawerStateAtom);
  const unreadMessagesCount = useAtomValue(
    unreadMessagesCountFamily(activeConversationSid ?? ""),
  );
  const setUnreadMessageCount = useSetAtom(
    unreadMessageCountByConversationSidAtom,
  );
  const getCurrentConversation = useAtomCallback(
    useCallback((get) => get(currentConversationAtom), []),
  );

  const toggleChatDrawer = () => {
    if (currentConversationSid) {
      setCurrentConversationSid("");
      setVisitorDrawerState(null);
    } else {
      setCurrentConversationSid(activeConversationSid);
      setUnreadMessageCount((prev) =>
        activeConversationSid ? { [activeConversationSid]: 0 } : prev,
      );
      setVisitorDrawerState(VisitorDrawerState.CHAT);
      toast.dismiss();

      gracefullySetAllMessagesRead(getCurrentConversation());
    }
  };

  return {
    unreadMessagesCount,
    toggleChatDrawer,
    isChatDrawerDisabled: !activeConversationSid,
  };
}
