import {
  Text,
  Icon,
  IconName,
  Button,
  ButtonUse,
  ButtonSize,
} from "@grow-therapy-team/sprout-ui";
import { ReactNode, useMemo } from "react";
import { HelpfulTipsDrawerStates, VisitorDrawerState } from "../state";
import { TrackingEvents, sendLoggingEvents } from "../../events";

export type DrawerButton = {
  text: string | ReactNode;
  icon: IconName;
  onClick: () => void;
  testId?: string;
};

export function DrawerButtonMenu({
  toggleHelpfulTipsDrawer,
}: {
  toggleHelpfulTipsDrawer: (drawerState: HelpfulTipsDrawerStates) => void;
}) {
  const drawerButtons: DrawerButton[] = useMemo(
    () => [
      {
        text: <>Tips for before your session</>,
        icon: "hourglass-start",
        testId: "drawer-button-menu.before-session",

        onClick: () =>
          toggleHelpfulTipsDrawer(
            VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION,
          ),
      },
      {
        text: <>Tips for after your session</>,
        icon: "hourglass-end",
        testId: "drawer-button-menu.after-session",

        onClick: () =>
          toggleHelpfulTipsDrawer(
            VisitorDrawerState.HELPFUL_TIPS_AFTER_SESSION,
          ),
      },
    ],
    [toggleHelpfulTipsDrawer],
  );

  return (
    <ul className="flex flex-col gap-4">
      {drawerButtons.map(({ text, icon, onClick, testId }) => (
        <li key={icon}>
          <Button
            data-testid={testId}
            onClick={() => {
              sendLoggingEvents(TrackingEvents.CLIENT_PRE_SESSION_TIP_OPEN);
              onClick();
            }}
            use={ButtonUse.Secondary}
            className="rounded-lg p-4 sm:py-6 sm:px-8 shadow-border-neutral-300 border-0 hover:shadow-border-ivy-500 hover:bg-ivy-100 hover:border-ivy-500 flex gap-4 w-full justify-between align-middle items-center"
            size={ButtonSize.Large}
          >
            <Icon
              variant="regular"
              name={icon}
              className="fa-fw text-marigold-500 rebrand:text-lilac-600"
            />
            <Text className="text-neutral-900 font-medium relative flex-1 text-left">
              {text}
            </Text>
            <Icon
              variant="regular"
              name={"chevron-right"}
              className="fa-fw text-ivy-500"
              size="sm"
            />
          </Button>
        </li>
      ))}
    </ul>
  );
}
