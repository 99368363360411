import { Icon, Link } from "@grow-therapy-team/sprout-ui";
import { Toast, ToastProps, ToastVariant } from "../Toast";
import Markdown, { Components } from "react-markdown";
import { twMerge } from "tailwind-merge";
import { omit } from "remeda";

export type AnnouncementToastProps = {
  contentMarkdown: string;
  onClose: () => void;
} & Omit<ToastProps, "children">;

const customMarkdownComponents: Components = {
  a: ({ children, href, ...otherLinkProps }) => {
    if (!href) return <>{children}</>;
    return (
      <Link
        className="no-underline"
        target="_blank"
        externalIcon={false}
        href={href}
        external
        {...omit(otherLinkProps, ["contentEditable"])}
      >
        {children}
      </Link>
    );
  },
};

export function AnnouncementToast({
  contentMarkdown,
  onClose,
  className,
  ...otherProps
}: AnnouncementToastProps) {
  return (
    <Toast
      variant={ToastVariant.Neutral}
      data-testid="announcement-toast"
      className={twMerge("gap-3 max-w-[35rem]", className)}
      onClose={onClose}
      onCleanup={onClose}
      {...otherProps}
    >
      <Icon variant="solid" className="text-large" name="info-circle" />
      <Markdown components={customMarkdownComponents}>
        {contentMarkdown}
      </Markdown>
    </Toast>
  );
}
