import { useOpenClientInformationCallback } from ".";
import { participantCountAtom } from "../../twilio";
import { useAtomValue, useSetAtom } from "jotai";
import {
  DrawerState,
  drawerStateAtom,
  inSessionPatientInformationAtom,
} from "../state";
import { faMemo } from "@fortawesome/pro-solid-svg-icons";
import { ControlPanelButton } from "../../components";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";
import {
  hasNewFormsAtomFamily,
  inSessionClientHasPendingFormsAtom,
} from "./state";
import { useClientMood } from "../../hooks/measures/useClientMood";
import { Tooltip } from "@grow-therapy-team/sprout-ui";
import { useState } from "react";

export function ClientInformationButton({
  onClientInformationButtonClick,
  showVisualIndicator,
}: {
  onClientInformationButtonClick: () => void;
  showVisualIndicator?: boolean;
}) {
  return (
    <Tooltip
      text="Client Information"
      className="z-0"
      containerClassName="hidden sm:inline-block"
    >
      <ControlPanelButton
        containerClassName="hidden sm:block"
        aria-label="View client information"
        iconDefinition={faMemo}
        onClick={onClientInformationButtonClick}
        showVisualIndicator={showVisualIndicator}
      />
    </Tooltip>
  );
}

export function ClientInformationButtonWrapper() {
  const openClientInformationDrawer = useOpenClientInformationCallback();
  const inSessionClientInformation = useAtomValue(
    inSessionPatientInformationAtom,
  );

  const { userClicked } = useTrackEvent();
  const setDrawerState = useSetAtom(drawerStateAtom);
  const drawerState = useAtomValue(drawerStateAtom);
  const clientInfoDrawerIsOpen = drawerState === DrawerState.CLIENT_INFORMATION;
  const isInSession = useAtomValue(participantCountAtom) > 0;
  const hasInSessionClientInformation = useAtomValue(
    inSessionClientHasPendingFormsAtom,
  );
  const hasNewForms = useAtomValue(
    hasNewFormsAtomFamily(inSessionClientInformation?.patientShortId ?? ""),
  );
  const hasRecordedMeasure = !!useClientMood(
    inSessionClientInformation?.visitorUuid,
  );
  const [hasOpenedDrawer, setHasOpenedDrawer] = useState(false);
  const showVisualIndicator =
    !hasOpenedDrawer &&
    (hasInSessionClientInformation || hasNewForms || hasRecordedMeasure);

  if (!isInSession || !inSessionClientInformation?.visitorUuid) {
    return;
  }

  const onClientInformationButtonClick = () => {
    openClientInformationDrawer(
      // @ts-ignore - visitorUuid is not undefined as it is checked in the early return above
      inSessionClientInformation.visitorUuid,
    );
    setHasOpenedDrawer(true);
    userClicked(EventTypeEnum.CLIENT_INFO, {
      patientShortId: inSessionClientInformation.patientShortId,
    });
  };
  return (
    <ClientInformationButton
      showVisualIndicator={showVisualIndicator}
      onClientInformationButtonClick={
        clientInfoDrawerIsOpen
          ? () => setDrawerState(null)
          : () => onClientInformationButtonClick()
      }
    />
  );
}
