import classnames from "classnames";
import { GrowLogo } from "./GrowLogo";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../hooks";
import { Theme } from "../state";
import { Button, ButtonUse, IconButton } from "@grow-therapy-team/sprout-ui";
import { HTMLAttributes } from "react";
import { faGear } from "@fortawesome/pro-solid-svg-icons";

export function Navbar({
  className,
  onClickSettingsButton,
  onClickNeedHelpButton,
  TranscriptionPillComponent,
  ...props
}: {
  className?: string;
  onClickSettingsButton?: () => void;
  onClickNeedHelpButton?: () => void;
  TranscriptionPillComponent?: React.FC;
} & HTMLAttributes<HTMLDivElement>): JSX.Element {
  const { theme } = useTheme();
  const showNeedHelp = !!onClickNeedHelpButton;

  return (
    <div
      className={twMerge(
        classnames(
          {
            "bg-neutral-300": theme === Theme.LIGHT,
            "bg-neutral-800": theme === Theme.DARK,
          },
          "relative flex items-center justify-between px-6 py-7 h-18",
        ),
        className,
      )}
      {...props}
    >
      <div className={"flex content-start items-center gap-4"}>
        <GrowLogo />
        {TranscriptionPillComponent && <TranscriptionPillComponent />}
      </div>
      <div className="flex flex-row gap-6">
        {showNeedHelp && (
          <Button
            use={ButtonUse.LinkOnDark}
            className="px-0 py-0"
            onClick={onClickNeedHelpButton}
          >
            <span className="hidden sm:block">Need help?</span>
            <span className="block sm:hidden">?</span>
          </Button>
        )}
        {!!onClickSettingsButton && (
          <IconButton
            className="hidden sm:flex ml-0"
            aria-label="Open settings"
            iconDefinition={faGear}
            onClick={onClickSettingsButton}
          />
        )}
      </div>
    </div>
  );
}
