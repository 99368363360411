import { useCallback } from "react";
import { useGetCurrentCachedProviderCallback } from "../useGetCurrentCachedProviderCallback";
import { useCreateTwilioProviderMeetingAccessMutation } from "./useCreateTwilioProviderMeetingAccessMutation";
import { logger } from "../../datadog/logger";

export function useGetTwilioRoomTokenCallback() {
  const getCurrentCachedProviderCallback =
    useGetCurrentCachedProviderCallback();
  const [createTwilioProviderMeetingAccess] =
    useCreateTwilioProviderMeetingAccessMutation();

  return useCallback(
    async (patientShortId?: string) => {
      try {
        return (
          await createTwilioProviderMeetingAccess({
            variables: { patientShortId },
          })
        )?.data?.createTwilioProviderMeetingAccess?.jwt;
      } catch (error) {
        logger.error(
          "Unable to get a new Twilio room token for provider",
          {
            providerShortId: (await getCurrentCachedProviderCallback())
              ?.shortId,
          },
          error as Error,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
