import { useCallback, useEffect } from "react";
import {
  activeConversationSidAtom,
  providerIsOnlineAtom,
  providerParticipantAtom,
} from "../../state";
import { useAtomCallback } from "jotai/utils";
import { useAtomValue, useSetAtom } from "jotai";
import { useConversationCallbacks } from "../../chat";

export function useWaitingRoomConversation() {
  const waitingRoomConversationSid = useAtomValue(activeConversationSidAtom);
  const setProviderIsOnline = useSetAtom(providerIsOnlineAtom);
  const getProviderParticipant = useAtomCallback(
    useCallback((get) => get(providerParticipantAtom), []),
  );

  const { joinConversation, leaveConversation } = useConversationCallbacks();

  useEffect(
    function handleInitWaitingRoomConversation() {
      async function joinWaitingRoomConversation() {
        if (waitingRoomConversationSid) {
          await joinConversation(waitingRoomConversationSid);

          const providerUser = await getProviderParticipant()?.getUser();
          setProviderIsOnline(!!providerUser?.isOnline);
          providerUser?.on("updated", ({ user: updatedUser }) => {
            setProviderIsOnline(updatedUser.isOnline);
          });
        }
      }
      joinWaitingRoomConversation();
      return () => {
        if (!waitingRoomConversationSid) return;
        leaveConversation(waitingRoomConversationSid);

        getProviderParticipant()
          ?.getUser()
          .then((updatedUser) => updatedUser?.removeAllListeners("updated"));
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [waitingRoomConversationSid],
  );
}
