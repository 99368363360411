import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import {
  chatClientStateAtom,
  twilioChatClientAtom,
  twilioIdentityAtom,
  usedTwilioWebsocketsSuccessfullyAtom,
  usingWebsocketsReverseProxyAtom,
} from "../state";
import { useCallback, useEffect } from "react";
import { parseJwt } from "../../auth/utils";
import { reverseProxyWebsockets } from "../utils";
import { logger } from "../../datadog/logger";
import { Client as ChatClient } from "@twilio/conversations";
import { ConnectionState } from "../types";

export function useTeardownTwilioChatClientCallback() {
  const setTwilioChatClient = useSetAtom(twilioChatClientAtom);
  const getTwilioChatClient = useAtomCallback(
    useCallback((get) => get(twilioChatClientAtom), []),
  );

  return useCallback(
    function teardownTwilioSyncClient() {
      const client = getTwilioChatClient();
      if (!client) return;
      client.removeAllListeners();
      client.shutdown();
      setTwilioChatClient(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export function useInitTwilioChatClientCallback() {
  const setTwilioChatClient = useSetAtom(twilioChatClientAtom);
  const setChatClientState = useSetAtom(chatClientStateAtom);
  const setTwilioIdentity = useSetAtom(twilioIdentityAtom);
  const teardownTwilioChatClient = useTeardownTwilioChatClientCallback();
  const setUsingWebsocketsReverseProxy = useSetAtom(
    usingWebsocketsReverseProxyAtom,
  );
  const setUsedTwilioWsSuccessfully = useSetAtom(
    usedTwilioWebsocketsSuccessfullyAtom,
  );
  const getUsingWebsocketsReverseProxy = useAtomCallback(
    useCallback((get) => get(usingWebsocketsReverseProxyAtom), []),
  );
  const getUsedTwilioWsSuccessfully = useAtomCallback(
    useCallback((get) => get(usedTwilioWebsocketsSuccessfullyAtom), []),
  );

  return useCallback(
    function initTwilioChatClient(twilioChatToken?: string) {
      if (!twilioChatToken) return;

      teardownTwilioChatClient();

      if (getUsingWebsocketsReverseProxy()) {
        reverseProxyWebsockets();
      }

      const client = new ChatClient(twilioChatToken);
      logger.info("Initializing twilio chat client");

      client.on("tokenExpired", () => {
        logger.warn("Twilio chat token expired");
      });

      client.on("tokenAboutToExpire", () => {
        logger.warn("Twilio chat token about to expire");
      });

      client.on("connectionError", (connectionError) => {
        if (
          !getUsedTwilioWsSuccessfully() &&
          !getUsingWebsocketsReverseProxy()
        ) {
          setUsingWebsocketsReverseProxy(true);
        } else {
          logger.error(
            `Twilio chat connection error: ${connectionError.message}`,
          );
          setChatClientState("error");
        }
      });
      client.on("connectionStateChanged", (newState) => {
        logger.debug("Twilio chat connection state change", {
          twilioChatState: newState,
        });
        if (newState === ConnectionState.CONNECTED) {
          setUsedTwilioWsSuccessfully(true);
        }
        setChatClientState(newState);
      });

      const identity = parseJwt<{ grants: { identity: string } }>(
        twilioChatToken,
      )?.grants?.identity;
      setTwilioChatClient(client);
      setTwilioIdentity(identity);
      return client;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export function useInitTwilioChatClient({
  twilioChatToken,
}: {
  twilioChatToken?: string;
}) {
  const initTwilioChatClient = useInitTwilioChatClientCallback();
  const teardownTwilioChatClient = useTeardownTwilioChatClientCallback();
  const usingWebsocketsReverseProxy = useAtomValue(
    usingWebsocketsReverseProxyAtom,
  );

  useEffect(
    function handleInitTwilioChatClient() {
      if (!twilioChatToken) return;
      initTwilioChatClient(twilioChatToken);
      return teardownTwilioChatClient;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [twilioChatToken, usingWebsocketsReverseProxy],
  );
}
