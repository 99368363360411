import { PrimitiveAtom, atom } from "jotai";
import { atomFamily } from "jotai/utils";
import {
  LocalAudioTrack,
  LocalAudioTrackStats,
  LocalVideoTrack,
  LocalDataTrack,
  RemoteAudioTrack,
  RemoteDataTrack,
  RemoteAudioTrackStats,
  RemoteParticipant,
  RemoteVideoTrack,
  Room,
} from "twilio-video";
import {
  Client as ChatClient,
  Conversation,
  Message,
  Participant as ChatParticipant,
} from "@twilio/conversations";
import {
  capitalize,
  createDerivedWritableAtom,
  filterNonNullable,
} from "../utils";
import {
  chatParticipantToName,
  getChatParticipants,
  parseIdentity,
} from "./utils";
import {
  ConnectionState,
  ConnectionStateStatus,
  LocalAVTrack,
  TrackKind,
  AudioVideoTrackKind,
} from "./types";
import { SyncClient } from "twilio-sync";
import {
  QualityMode,
  getStoredAudioVideoSettings,
  parseTrackName,
} from "./config";
import { UserData } from "../types";

export enum LocalTrackState {
  LOADING = "loading",
  READY = "ready",
  DISABLED = "disabled",
  ERROR = "error",
}

export interface LocalAVTrackAtom {
  state?: LocalTrackState;
  track?: LocalAVTrack;
}

export interface RecordingAtom {
  isRecording: boolean;
}
export interface LocalTrackAtom {
  state?: LocalTrackState;
  track?: LocalAVTrack | LocalDataTrack;
}

export interface LocalAudioTrackAtom extends LocalAVTrackAtom {
  track?: LocalAudioTrack;
}

export interface LocalVideoTrackAtom extends LocalAVTrackAtom {
  track?: LocalVideoTrack;
}

export interface LocalDataTrackAtom {
  state?: LocalTrackState;
  track?: LocalDataTrack;
}

export type ConversationsBySid = Record<string, Conversation>;
export type MessagesByConversationSid = Record<string, Message[]>;
export type ChatParticipantsById = Record<string, ChatParticipant>;
export type ParticipantIdsByConversationSid = Record<string, Set<string>>;
export type UnreadMessageCountByConversationSid = Record<string, number>;
export type TrackStatusByParticipantId = Record<
  string,
  { isAudioMuted: boolean; isVideoOff: boolean }
>;
export type RemoteTracksByParticipantId = Record<
  string,
  {
    video?: RemoteVideoTrack[];
    audio?: RemoteAudioTrack[];
    data?: RemoteDataTrack[];
  }
>;
export type userDataByParticipantId = Record<string, UserData>;
export type RemoteAudioTrackStatsByTrackSid = Record<
  string,
  RemoteAudioTrackStats
>;

export type AvailableDevices = {
  audioInputDevices: MediaDeviceInfo[];
  videoInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
};

export enum ThumbnailPosition {
  TOP_LEFT = "top-2 left-2",
  TOP_RIGHT = "top-2 right-2",
  BOTTOM_RIGHT = "right-2",
  BOTTOM_LEFT = "bottom-12 left-2",
}

export type TwilioAtom = {
  availableDevices: AvailableDevices | null;
  room?: Room;
  roomToken?: string;
  localAudioTrack: LocalAudioTrackAtom;
  localVideoTrack: LocalVideoTrackAtom;
  localDataTrack: LocalDataTrackAtom;
  localScreenShareTrack?: MediaStreamTrack | null;
  localScreenShareAudioTrack?: MediaStreamTrack | null;
  participants: RemoteParticipant[];
  currentMicrophoneId?: string;
  currentOutputDeviceId?: string;
  currentCameraId?: string;
  videoWasOnAtScreenLeave: boolean | null;
  thumbnailPosition: ThumbnailPosition;
  connectedAt?: number;
  remoteScreenShareParticipant?: RemoteParticipant;
  remoteScreenShareTrack?: RemoteVideoTrack;
  remoteScreenShareAudioTrack?: RemoteAudioTrack;
  isAudioPlaybackRestricted?: boolean;
  twilioChatClient?: ChatClient;
  twilioSyncClient?: SyncClient;
  syncClientState?: ConnectionStateStatus;
  chatClientState?: ConnectionStateStatus;
  conversationsBySid: ConversationsBySid;
  messagesByConversationSid: MessagesByConversationSid;
  chatParticipantsById: ChatParticipantsById;
  participantIdsByConversationSid: ParticipantIdsByConversationSid;
  unreadMessageCountByConversationSid: Record<string, number>;
  twilioIdentity?: string;
  currentConversationSid?: string;
  trackStatusByParticipantId: TrackStatusByParticipantId;
  hasBackgroundProcessorFailure: boolean;
  remoteTracksByParticipantId: RemoteTracksByParticipantId;
  shouldOverrideSuggestedRoomVideoQuality: boolean;
  videoQuality: QualityMode;
  userDataByParticipantId: userDataByParticipantId;
  remoteAudioTrackStatsBySid: RemoteAudioTrackStatsByTrackSid;
  localAudioTrackStats: LocalAudioTrackStats | null;
  shouldTriggerPictureInPicture: boolean;
  pictureInPictureParticipantId?: string;
  dominantSpeakerId?: string;
  usingWebsocketsReverseProxy?: boolean;
  usedTwilioWebsocketsSuccessfully?: boolean;
  isRecording: boolean;
};

export const DEFAULT_LOCAL_TRACK_VALUE = {};

export const DEFAULT_TWILIO_ATOM_VALUE = {
  availableDevices: null,
  participants: [],
  localAudioTrack: DEFAULT_LOCAL_TRACK_VALUE,
  localVideoTrack: DEFAULT_LOCAL_TRACK_VALUE,
  localDataTrack: DEFAULT_LOCAL_TRACK_VALUE,
  videoWasOnAtScreenLeave: null,
  thumbnailPosition: ThumbnailPosition.TOP_RIGHT,
  conversationsBySid: {},
  messagesByConversationSid: {},
  chatParticipantsById: {},
  participantIdsByConversationSid: {},
  unreadMessageCountByConversationSid: {},
  trackStatusByParticipantId: {},
  hasBackgroundProcessorFailure: false,
  remoteTracksByParticipantId: {},
  shouldOverrideSuggestedRoomVideoQuality: false,
  videoQuality: getStoredAudioVideoSettings().videoQualityMode,
  userDataByParticipantId: {},
  remoteAudioTrackStatsBySid: {},
  localAudioTrackStats: null,
  shouldTriggerPictureInPicture: false,
  usingWebsocketsReverseProxy: false,
  usedTwilioWebsocketsSuccessfully: false,
  isRecording: false,
};

export const twilioAtom = atom<TwilioAtom>(DEFAULT_TWILIO_ATOM_VALUE);

export const twilioRoomAtom = createDerivedWritableAtom(twilioAtom, "room");

export const twilioRoomTokenAtom = createDerivedWritableAtom(
  twilioAtom,
  "roomToken",
);

// This is its own atom because opening it in Jotai's dev tools causes the dev
// tools/page to freeze up
export const twilioChatClientAtom = atom<ChatClient | null>(null);

export const twilioIdentityAtom = createDerivedWritableAtom(
  twilioAtom,
  "twilioIdentity",
);

export const availableDevicesAtom = createDerivedWritableAtom(
  twilioAtom,
  "availableDevices",
);

export const participantsAtom = createDerivedWritableAtom(
  twilioAtom,
  "participants",
);

export const participantSidsAtom = atom(
  (get) => get(participantsAtom)?.map((p) => p.sid) ?? [],
);

export const participantIdsAtom = atom(
  (get) => get(participantsAtom)?.map((p) => p.identity) ?? [],
);

export const participantsByIdAtom = atom<Record<string, RemoteParticipant>>(
  (get) =>
    get(participantsAtom)?.reduce(
      (agg, p) => ({ ...agg, [parseIdentity(p.identity).id]: p }),
      {},
    ) ?? {},
);

export const participantCountAtom = atom(
  (get) => get(participantsAtom)?.length ?? 0,
);

export const conversationsBySidAtom = createDerivedWritableAtom(
  twilioAtom,
  "conversationsBySid",
);

export const messagesByConversationSidAtom = createDerivedWritableAtom(
  twilioAtom,
  "messagesByConversationSid",
);

export const chatParticipantsByIdAtom = createDerivedWritableAtom(
  twilioAtom,
  "chatParticipantsById",
);

export const localAudioTrackAtom = createDerivedWritableAtom(
  twilioAtom,
  "localAudioTrack",
);

export const localVideoTrackAtom = createDerivedWritableAtom(
  twilioAtom,
  "localVideoTrack",
);

export const localDataTrackAtom = createDerivedWritableAtom(
  twilioAtom,
  "localDataTrack",
);

export const localVideoTrackStateAtom = createDerivedWritableAtom(
  localVideoTrackAtom,
  "state",
);

export const localAudioTrackStateAtom = createDerivedWritableAtom(
  localAudioTrackAtom,
  "state",
);

export const isOwnVideoOffAtom = atom((get) => {
  const { track, state } = get(localVideoTrackAtom);
  return !track || state !== LocalTrackState.READY;
});

export const isOwnAudioOffAtom = atom((get) => {
  const { track, state } = get(localAudioTrackAtom);
  return !track || state !== LocalTrackState.READY;
});

export const currentMicrophoneIdAtom = atom(
  (get) =>
    get(localAudioTrackAtom).track?.mediaStreamTrack.getSettings().deviceId,
);

export const currentCameraIdAtom = atom(
  (get) =>
    get(localVideoTrackAtom).track?.mediaStreamTrack.getSettings().deviceId,
);

export const localTrackFamily = atomFamily((trackKind: TrackKind) => {
  const trackKey = `local${capitalize(trackKind)}Track` as
    | "localAudioTrack"
    | "localVideoTrack"
    | "localDataTrack";
  return createDerivedWritableAtom(
    twilioAtom,
    trackKey,
  ) as PrimitiveAtom<LocalTrackAtom>;
});

export const localAudioVideoTrackFamily = atomFamily(
  (trackKind: AudioVideoTrackKind) => {
    const trackKey = `local${capitalize(trackKind)}Track` as
      | "localAudioTrack"
      | "localVideoTrack";
    return createDerivedWritableAtom(
      twilioAtom,
      trackKey,
    ) as PrimitiveAtom<LocalAVTrackAtom>;
  },
);

export const permissionDeniedAtom = atom((get) => {
  const audioInputDevices = get(availableDevicesAtom)?.audioInputDevices;
  const videoDevices = get(availableDevicesAtom)?.videoInputDevices;

  // The existence of devices with device IDs is the best indicator we have for
  // browser permission status
  // TODO: consider returning `undefined` if we can't determine the status to
  // allow the UI to handle it better
  return {
    microphoneIsBlocked:
      !audioInputDevices ||
      audioInputDevices?.filter((device) => device.deviceId).length === 0,
    videoIsBlocked:
      !videoDevices ||
      videoDevices?.filter((device) => device.deviceId).length === 0,
  };
});

export const currentOutputDeviceIdAtom = createDerivedWritableAtom(
  twilioAtom,
  "currentOutputDeviceId",
);

export const videoWasOnAtScreenLeaveAtom = createDerivedWritableAtom(
  twilioAtom,
  "videoWasOnAtScreenLeave",
);

export const thumbnailPositionAtom = createDerivedWritableAtom(
  twilioAtom,
  "thumbnailPosition",
);

export const connectedAtAtom = createDerivedWritableAtom(
  twilioAtom,
  "connectedAt",
);

export const localScreenShareTrackAtom = createDerivedWritableAtom(
  twilioAtom,
  "localScreenShareTrack",
);

export const localScreenShareAudioTrackAtom = createDerivedWritableAtom(
  twilioAtom,
  "localScreenShareAudioTrack",
);

export const remoteScreenShareParticipantAtom = createDerivedWritableAtom(
  twilioAtom,
  "remoteScreenShareParticipant",
);

export const remoteScreenShareTrackAtom = createDerivedWritableAtom(
  twilioAtom,
  "remoteScreenShareTrack",
);

export const remoteScreenShareAudioTrackAtom = createDerivedWritableAtom(
  twilioAtom,
  "remoteScreenShareAudioTrack",
);

export const isScreenShareModeAtom = atom((get) => {
  const localScreenShareTrack = get(localScreenShareTrackAtom);
  const remoteScreenShareTrack = get(remoteScreenShareTrackAtom);
  return !!localScreenShareTrack || !!remoteScreenShareTrack;
});

export const isAudioPlaybackRestrictedAtom = createDerivedWritableAtom(
  twilioAtom,
  "isAudioPlaybackRestricted",
);

export const participantIdsByConversationSidAtom = createDerivedWritableAtom(
  twilioAtom,
  "participantIdsByConversationSid",
);

export const unreadMessageCountByConversationSidAtom =
  createDerivedWritableAtom(twilioAtom, "unreadMessageCountByConversationSid");

export const unreadMessagesCountFamily = atomFamily(
  (conversationSid: string) => {
    return atom((get) => {
      const unreadMessageCountByConversationSid = get(
        unreadMessageCountByConversationSidAtom,
      );
      return unreadMessageCountByConversationSid[conversationSid] ?? 0;
    });
  },
);

export const currentConversationSidAtom = createDerivedWritableAtom(
  twilioAtom,
  "currentConversationSid",
);

export const currentConversationAtom = atom((get) => {
  const currentConversationSid = get(currentConversationSidAtom);
  if (!currentConversationSid) return;
  const conversations = get(conversationsBySidAtom);
  return conversations[currentConversationSid];
});

export const currentConversationMessagesAtom = atom((get) => {
  const currentConversationSid = get(currentConversationSidAtom);
  if (!currentConversationSid) return [];
  return get(messagesByConversationSidAtom)[currentConversationSid] ?? [];
});

export const currentConversationParticipantIdsAtom = atom((get) => {
  const currentConversationSid = get(currentConversationSidAtom);
  if (!currentConversationSid) return new Set<string>();
  return (
    get(participantIdsByConversationSidAtom)[currentConversationSid] ??
    new Set<string>()
  );
});

export const currentConversationParticipantsAtom = atom((get) => {
  const participantIds = get(currentConversationParticipantIdsAtom);
  const chatParticipants = get(chatParticipantsByIdAtom);
  const twilioIdentity = get(twilioIdentityAtom);
  return getChatParticipants(participantIds, chatParticipants, twilioIdentity);
});

export const currentConversationParticipantNamesAtom = atom((get) => {
  const currentConversationParticipants = get(
    currentConversationParticipantsAtom,
  );
  return Array.from(currentConversationParticipants).map(chatParticipantToName);
});

export const twilioSyncClientAtom = createDerivedWritableAtom(
  twilioAtom,
  "twilioSyncClient",
);

export const syncClientStateAtom = createDerivedWritableAtom(
  twilioAtom,
  "syncClientState",
);

export const chatClientStateAtom = createDerivedWritableAtom(
  twilioAtom,
  "chatClientState",
);

export const usingWebsocketsReverseProxyAtom = createDerivedWritableAtom(
  twilioAtom,
  "usingWebsocketsReverseProxy",
);

export const usedTwilioWebsocketsSuccessfullyAtom = createDerivedWritableAtom(
  twilioAtom,
  "usedTwilioWebsocketsSuccessfully",
);

export const trackStatusByParticipantIdAtom = createDerivedWritableAtom(
  twilioAtom,
  "trackStatusByParticipantId",
);

export const hasBackgroundProcessorFailureAtom = atom(false);

export const remoteTracksByParticipantIdAtom = createDerivedWritableAtom(
  twilioAtom,
  "remoteTracksByParticipantId",
);

export function removeTrackFromParticipant(
  tracksByParticipantId: RemoteTracksByParticipantId,
  participantId: string,
  track: RemoteAudioTrack | RemoteVideoTrack | RemoteDataTrack,
) {
  const { kind } = track;
  const existingTracks = tracksByParticipantId[participantId] ?? {};
  const updatedTracks = {
    ...existingTracks,
    [kind]: existingTracks[kind]?.filter((t) => t.sid !== track.sid),
  };
  return {
    ...tracksByParticipantId,
    [participantId]: updatedTracks,
  };
}

export function addTrackToParticipant(
  tracksByParticipantId: RemoteTracksByParticipantId,
  participantId: string,
  track: RemoteAudioTrack | RemoteVideoTrack | RemoteDataTrack,
) {
  const uniqueTracksByParticipantId = removeTrackFromParticipant(
    tracksByParticipantId,
    participantId,
    track,
  );
  const { kind } = track;
  const existingTracks = uniqueTracksByParticipantId[participantId] ?? {};
  const updatedTracks = {
    ...existingTracks,
    [kind]: [...(existingTracks[kind] ?? []), track],
  };
  return {
    ...tracksByParticipantId,
    [participantId]: updatedTracks,
  };
}

export const videoQualityAtom = createDerivedWritableAtom(
  twilioAtom,
  "videoQuality",
  {
    defaultGetValue: QualityMode.High,
  },
);

export const shouldOverrideSuggestedRoomVideoQualityAtom =
  createDerivedWritableAtom(
    twilioAtom,
    "shouldOverrideSuggestedRoomVideoQuality",
  );

export const remoteVideoTracksAtom = atom((get) => {
  const remoteTracksByParticipantId = get(remoteTracksByParticipantIdAtom);
  return Object.values(remoteTracksByParticipantId).flatMap(
    (tracks) => tracks.video ?? [],
  );
});

export const remoteAudioTracksAtom = atom((get) => {
  const remoteTracksByParticipantId = get(remoteTracksByParticipantIdAtom);
  return Object.values(remoteTracksByParticipantId).flatMap(
    (tracks) => tracks.audio ?? [],
  );
});

export const remoteDataTracksAtom = atom((get) => {
  const remoteTracksByParticipantId = get(remoteTracksByParticipantIdAtom);
  return Object.values(remoteTracksByParticipantId).flatMap(
    (tracks) => tracks.data ?? [],
  );
});
export const isSomeRemoteVideoTrackLowQualityAtom = atom((get) => {
  const remoteVideoTracks = get(remoteVideoTracksAtom);
  return remoteVideoTracks.some((track) => {
    const { qualityMode } = parseTrackName(track.name, track.kind);
    return qualityMode === QualityMode.Low;
  });
});

export const shouldSuggestPerformanceModeAtom = atom((get) => {
  const shouldOverrideSuggestedRoomVideoQuality = get(
    shouldOverrideSuggestedRoomVideoQualityAtom,
  );
  const isSomeRemoteVideoTrackLowQuality = get(
    isSomeRemoteVideoTrackLowQualityAtom,
  );
  return (
    !shouldOverrideSuggestedRoomVideoQuality && isSomeRemoteVideoTrackLowQuality
  );
});

export const canOverrideSuggestedRoomVideoQualityAtom = atom((get) => {
  const hasParticipants = !!get(participantCountAtom);
  const isHighQuality = get(videoQualityAtom) === QualityMode.High;
  const shouldSuggestPerformanceMode = get(shouldSuggestPerformanceModeAtom);

  return hasParticipants && isHighQuality && shouldSuggestPerformanceMode;
});

export const userDataByParticipantIdAtom = createDerivedWritableAtom(
  twilioAtom,
  "userDataByParticipantId",
);

export const userDataFamily = atomFamily((participantId: string) => {
  return atom((get) => {
    const userDataByParticipantId = get(userDataByParticipantIdAtom);
    return userDataByParticipantId[participantId];
  });
});

export const shouldTriggerPictureInPictureAtom = createDerivedWritableAtom(
  twilioAtom,
  "shouldTriggerPictureInPicture",
);

export const pictureInPictureParticipantIdAtom = createDerivedWritableAtom(
  twilioAtom,
  "pictureInPictureParticipantId",
);

export const dominantSpeakerIdAtom = createDerivedWritableAtom(
  twilioAtom,
  "dominantSpeakerId",
);

const badChatStates = new Set<ConnectionStateStatus>([
  ConnectionState.ERROR,
  ConnectionState.DISCONNECTED,
  ConnectionState.DENIED,
  ConnectionState.UNKNOWN,
]);
export const isChatErrorAtom = atom((get) => {
  const chatClientState = get(chatClientStateAtom);
  return !!chatClientState && badChatStates.has(chatClientState);
});

export const allAudioTracksAtom = atom((get) => {
  const localAudioTrack = get(localAudioTrackAtom);
  const remoteAudioTracks = get(remoteAudioTracksAtom);
  return filterNonNullable([localAudioTrack.track, ...remoteAudioTracks]);
});

export const isRecordingAtom = createDerivedWritableAtom(
  twilioAtom,
  "isRecording",
);
