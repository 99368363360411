import { PushDrawer, SettingsDrawer } from "../components";
import { ClientsDrawerWrapper as ClientsDrawer } from "./clients-drawer";
import { useAtom } from "jotai";
import { currentConversationSidAtom } from "../twilio";
import { ChatDrawerWrapper as ChatDrawer } from "./chat";
import { AllClientsChatDrawerWrapper as AllClientsChatDrawer } from "./chat/AllClientsChatDrawer";
import { ClientInformationDrawerWrapper as ClientInformationDrawer } from "./client-information/ClientInformationDrawer";
import { ClientInformationFormResponseDrawerWrapper as ClientInformationFormResponseDrawer } from "./client-information/ClientInformationFormResponseDrawer";
import { ClientInformationProgressNotesDrawerWrapper as ClientInformationNotesDrawer } from "./client-information/ClientInformationProgressNotesDrawer";
import { DrawerState, drawerStateAtom } from "./state";
import { tabMap } from "./settingsTabMap";
import { PendingFormsDrawer } from "./client-information/pending-forms/PendingFormsDrawer";
import { PendingFormsCompletedDrawer } from "./client-information/pending-forms/PendingFormsCompletedDrawer";
import { EditAppointmentDrawerWrapper as EditAppointmentDrawer } from "./schedule-appointment/EditAppointmentDrawer";
import { AddAppointmentDrawerWrapper as AddAppointmentDrawer } from "./schedule-appointment/add-appointment-drawer/AddAppointmentDrawer";
import { PendingMeasuresDrawerWrapper as PendingMeasuresDrawer } from "./measures/PendingMeasuresDrawer";
import { MeasureResponsesDrawer } from "./measures/MeasureResponsesDrawer";

function DrawerContent({ drawerState }: { drawerState: DrawerState | null }) {
  switch (drawerState) {
    case DrawerState.SETTINGS:
      return <SettingsDrawer tabMap={tabMap} />;
    case DrawerState.CLIENTS:
      return <ClientsDrawer />;
    case DrawerState.SESSION_CHAT:
    case DrawerState.WAITING_ROOM_CHAT:
    case DrawerState.PARTICIPANT_DISCONNECTED_CHAT:
      return <ChatDrawer />;
    case DrawerState.ALL_CLIENTS_CHAT_LIST:
      return <AllClientsChatDrawer />;
    case DrawerState.CLIENT_INFORMATION:
      return <ClientInformationDrawer />;
    case DrawerState.CLIENT_FORM_RESPONSE:
      return <ClientInformationFormResponseDrawer />;
    case DrawerState.PROGRESS_NOTES:
      return <ClientInformationNotesDrawer />;
    case DrawerState.EDIT_APPOINTMENT:
      return <EditAppointmentDrawer />;
    case DrawerState.PENDING_FORMS:
      return <PendingFormsDrawer />;
    case DrawerState.PENDING_FORMS_COMPLETED:
      return <PendingFormsCompletedDrawer />;
    case DrawerState.ADD_APPOINTMENT:
      return <AddAppointmentDrawer />;
    case DrawerState.PENDING_MEASURES:
      return <PendingMeasuresDrawer />;
    case DrawerState.MEASURE_RESPONSES:
      return <MeasureResponsesDrawer />;
    default:
      return null;
  }
}

export function PushDrawerWrapper({ className }: { className?: string }) {
  const [drawerState, setDrawerState] = useAtom(drawerStateAtom);
  const [currentConversationSid, setCurrentConversationSid] = useAtom(
    currentConversationSidAtom,
  );
  const onCloseDrawer = (): void => {
    setDrawerState(null);
    if (currentConversationSid) {
      setCurrentConversationSid(undefined);
    }
  };

  return (
    <PushDrawer
      isOpen={!!drawerState}
      onClose={onCloseDrawer}
      className={className}
    >
      <DrawerContent drawerState={drawerState} />
    </PushDrawer>
  );
}
