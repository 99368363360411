import { Icon, Tag, TagUse, Text } from "@grow-therapy-team/sprout-ui";
import { Toast } from "../../components";
import { twMerge } from "tailwind-merge";

export function LeaveSessionNudgeToast({
  onClose,
  className,
}: {
  onClose: () => void;
  className: string;
}) {
  return (
    <Toast
      className={twMerge("p-4 w-fit", className)}
      onClose={onClose}
      closeButtonClassName="self-start mt-1"
    >
      <div className="flex gap-4 align-start flex-col">
        <div className="flex justify-between">
          <Tag
            use={TagUse.Green}
            className="flex flex-row justify-start items-center gap-2 px-2 py-1"
          >
            <Icon
              variant="solid"
              name="lightbulb"
              className="text-neutral-800 font-medium"
            />
            <Text variant="xs" className="text-neutral-800 font-medium">
              Grow Tip
            </Text>
          </Tag>
        </div>
        <Text variant="sm" className="text-left sm:w-80">
          Nearing the end? Use the &ldquo;Leave Session&rdquo; button and
          we&apos;ll guide you through next steps.
        </Text>
      </div>
    </Toast>
  );
}
