import { atom } from "jotai";
import { isRecordingAtom, participantCountAtom } from "../../twilio";
import { createDerivedWritableAtom } from "../../utils";
import { providerAtom } from "../state";

export const shouldRecordAtom = atom((get) => {
  const isRecordingEnabled = get(isRecordingAtom);
  const hasParticipants = !!get(participantCountAtom);
  return isRecordingEnabled && hasParticipants;
});

export const audioRecordingEntitiesAtom = createDerivedWritableAtom(
  providerAtom,
  "audioRecordingEntities",
);

export const recordingAudioContextAtom = atom(
  (get) => get(audioRecordingEntitiesAtom)?.audioContext,
);

export const recordingAudioDestinationNodeAtom = atom(
  (get) => get(audioRecordingEntitiesAtom)?.audioDestinationNode,
);

export const audioRecorderAtom = atom(
  (get) => get(audioRecordingEntitiesAtom)?.audioRecorder,
);

export const requestingClientConsentAtom = atom<boolean>(false);
