import {
  Button,
  ButtonUse,
  Heading,
  Tag,
  TagUse,
  Text,
  toast,
} from "@grow-therapy-team/sprout-ui";
import { ClientActionsMenu } from "./ClientActionsMenu";
import { useCallback } from "react";
import { logger } from "../../datadog/logger";
import { secondsToMilliseconds } from "date-fns";

import {
  participantsAtom,
  unreadMessagesCountFamily,
} from "../../twilio/state";
import { useAtomCallback } from "jotai/utils";
import { useOpenConversationCallback } from "../chat/useOpenConversationCallback";
import {
  DrawerState,
  sessionConversationSidAtom,
  inSessionPatientInformationAtom,
} from "../state";
import { parseIdentity } from "../../twilio/utils";
import { useAtomValue } from "jotai";
import { ClientActions } from "./ClientActions";

import { RemoteParticipant } from "twilio-video";
import { useRemoveClientCallback } from "./useRemoveClientCallback";
import { sendLoggingEvents, TrackingEvents } from "../../events";
import { useOpenClientInformationCallback } from "../client-information";
import { ClientMoodWrapper as ClientMood } from "../client-information/ClientMood";

type ClientsInSessionActionsProps = {
  onRemove: () => void;
  isConversationLoading?: boolean;
  loading?: boolean;
  clientName: string;
  clientNameAnnotation?: React.ReactNode | null;
  unreadMessagesCount?: number;
  patientShortId?: string;
  onChat?: () => void;
  onOpenClientInformationDrawer: () => void;
};

function ClientsInSessionActions({
  onRemove,
  loading,
  clientName,
  clientNameAnnotation,
  patientShortId,
  unreadMessagesCount,
  isConversationLoading,
  onChat,
  onOpenClientInformationDrawer,
}: ClientsInSessionActionsProps) {
  return (
    <ClientActions
      clientName={clientName}
      clientNameAnnotation={clientNameAnnotation}
      actions={
        <div className="flex gap-2 shrink-0">
          <Button
            data-testid={`client-actions.remove:${clientName}`}
            loading={loading}
            use={ButtonUse.Destructive}
            onClick={onRemove}
          >
            Remove
          </Button>
          <ClientActionsMenu
            visitorName={clientName}
            loading={loading}
            isConversationLoading={isConversationLoading}
            unreadMessagesCount={unreadMessagesCount}
            onChat={onChat}
            patientShortId={patientShortId}
            onOpenClientInformationDrawer={onOpenClientInformationDrawer}
          />
        </div>
      }
    />
  );
}

function ClientsInSessionActionsWrapper({
  participantSid,
  clientName,
  visitorUuid,
}: { participantSid: string; visitorUuid: string } & Pick<
  ClientsInSessionActionsProps,
  "clientName"
>) {
  const { callback: removeClient, loading } = useRemoveClientCallback();

  const getSessionConversationSid = useAtomCallback(
    useCallback((get) => get(sessionConversationSidAtom), []),
  );

  const inSessionVisitor = useAtomValue(inSessionPatientInformationAtom);
  const patientShortId = inSessionVisitor?.patientShortId;
  const sessionConversationSid = getSessionConversationSid();

  const unreadMessagesCount = useAtomValue(
    unreadMessagesCountFamily(sessionConversationSid ?? ""),
  );
  const openConversation = useOpenConversationCallback();
  const openClientInformation = useOpenClientInformationCallback();

  const openSessionConversation = () => {
    if (!sessionConversationSid) {
      logger.error("sessionConversationSid not found", {
        participantSid,
        clientName,
      });
      toast.error("Unable to open chat", {
        duration: secondsToMilliseconds(10),
      });
      return;
    }
    openConversation(DrawerState.SESSION_CHAT, sessionConversationSid);
  };

  const openClientInformationSection = useCallback(() => {
    openClientInformation(visitorUuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorUuid]);

  return (
    <ClientsInSessionActions
      onRemove={() => {
        sendLoggingEvents(TrackingEvents.PROVIDER_CLIENT_REMOVE);
        removeClient(participantSid);
      }}
      clientName={clientName}
      clientNameAnnotation={
        <ClientMood variant="xs" visitorUuid={visitorUuid} />
      }
      unreadMessagesCount={unreadMessagesCount}
      onChat={openSessionConversation}
      loading={loading}
      patientShortId={patientShortId}
      onOpenClientInformationDrawer={openClientInformationSection}
    />
  );
}

export function ClientsInSession({
  clientActionsComponent: ClientActionsComponent,
  participants,
}: {
  clientActionsComponent: typeof ClientsInSessionActionsWrapper;
  participants: RemoteParticipant[];
}) {
  return (
    <>
      <div className="flex items-center gap-2">
        <Heading variant="md">In session</Heading>
        <Tag use={TagUse.Mauve} data-testid="in-session.count">
          {participants.length}
        </Tag>
      </div>
      {participants.length ? (
        participants.map((participant) => {
          const { name: clientName, id } = parseIdentity(participant.identity);
          return (
            <ClientActionsComponent
              key={participant.sid}
              clientName={clientName}
              participantSid={participant.sid}
              visitorUuid={id}
            />
          );
        })
      ) : (
        <Text>
          Once you admit a client into your room, you will begin a session and
          see all participating clients here.
        </Text>
      )}
    </>
  );
}

export function ClientsInSessionWrapper() {
  const participants = useAtomValue(participantsAtom);

  return (
    <ClientsInSession
      clientActionsComponent={ClientsInSessionActionsWrapper}
      participants={participants}
    />
  );
}
