import { Toast, ToastVariant } from "../../components";
import toast from "react-hot-toast";
import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { faCirclePause } from "@fortawesome/pro-solid-svg-icons";

export function RequestingClientConsentToast({ id: toastId }: { id: string }) {
  return (
    <Toast
      className="bottom-center-toast"
      variant={ToastVariant.Neutral}
      onClose={() => {
        toast.remove(toastId);
      }}
    >
      <Icon icon={faCirclePause} />
      <Text variant="sm">Requesting client consent for transcription...</Text>
    </Toast>
  );
}
