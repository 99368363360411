import { useAtomValue, useSetAtom } from "jotai";
import {
  DrawerState,
  drawerStateAtom,
  inSessionPatientInformationAtom,
} from "../state";
import { useMemo, useState } from "react";
import {
  DrawerBody,
  MICMeasureBundleResponses,
  MICMeasureBundlesList,
  MICTypes,
} from "@grow-therapy-team/sprout-ui";
import { ClientInformationDrawerHeader } from "../client-information/ClientInformationDrawerHeader";
import { useGetClientMeasureBundles } from "./useGetClientMeasureBundles";

export function MeasureResponsesDrawer() {
  const name = useAtomValue(inSessionPatientInformationAtom)?.name;
  const setDrawerState = useSetAtom(drawerStateAtom);

  const [selectedBundle, setSelectedBundle] =
    useState<MICTypes.MeasureBundle>();

  const { data } = useGetClientMeasureBundles();

  const onClickBackButtonWrapped = () => {
    if (selectedBundle) {
      setSelectedBundle(null);
      return;
    }

    setDrawerState((prev) => {
      if (prev === DrawerState.MEASURE_RESPONSES) {
        return DrawerState.CLIENT_INFORMATION;
      }

      return DrawerState.MEASURE_RESPONSES;
    });
  };

  const drawerTitle = useMemo(() => {
    if (selectedBundle?.sentAt) {
      return `Check-in from ${new Date(
        selectedBundle.sentAt,
      ).toLocaleDateString(undefined, {
        month: "long",
        day: "numeric",
        year: "numeric",
      })}`;
    }
    if (name) {
      return `${name}'s Measures`;
    }
    return "Measures";
  }, [selectedBundle, name]);

  if (!data) return;

  return (
    <>
      <ClientInformationDrawerHeader
        drawerTitle={drawerTitle}
        onClickBackButton={onClickBackButtonWrapped}
      />
      <DrawerBody>
        {selectedBundle ? (
          <MICMeasureBundleResponses measureBundle={selectedBundle} />
        ) : (
          <MICMeasureBundlesList
            measureBundles={data.clientSentMeasureBundles}
            onClickBundle={(bundle) => {
              setSelectedBundle(bundle);
            }}
          />
        )}
      </DrawerBody>
    </>
  );
}
