import { useCallback } from "react";
import {
  DataTrackMessageTypeEnum,
  DataTrackMessage,
  ConsentStatus,
} from "../../twilio/types";
import { useRecordAudioCallbacks } from "../session-recording/useRecordAudioCallbacks";
import { Mutex } from "async-mutex";
import { useRecordClientTranscriptionConsentMutation } from "../session-recording/useRecordClientTranscriptionConsent";
import { requestingClientConsentAtom } from "../session-recording/state";
import { useSetAtom } from "jotai";
import { useClientDeclinedTranscriptionToastCallback } from "../session-recording/useClientDeclinedTranscriptionToastCallback";

export type MessageEventListeners = {
  [DataTrackMessageTypeEnum.RECORDING_STATUS_UPDATED]: (
    message: DataTrackMessage,
  ) => void;
};

const handleReceivedMessageMutex = new Mutex();

/**
 * Returns a callback that handles received messages on the data track for the
 * provider
 */
export function useHandleReceivedMessageCallback() {
  const { startRecording, stopRecording } = useRecordAudioCallbacks();
  const recordClientTranscriptionConsent =
    useRecordClientTranscriptionConsentMutation();
  const setRequestingClientConsent = useSetAtom(requestingClientConsentAtom);
  const popClientDeclinedTranscriptionToast =
    useClientDeclinedTranscriptionToastCallback();
  return useCallback(
    (message: DataTrackMessage) => {
      return handleReceivedMessageMutex.runExclusive(async () => {
        switch (message.messageType) {
          case DataTrackMessageTypeEnum.RECORDING_STATUS_UPDATED:
            if (message.details.isRecording) {
              await startRecording();
            } else {
              await stopRecording();
            }
            break;
          case DataTrackMessageTypeEnum.CLIENT_TRANSCRIPTION_CONSENT_UPDATED:
            if (message.details.clientConsent !== ConsentStatus.OPTED_IN) {
              popClientDeclinedTranscriptionToast();
            }
            if (message.details.clientConsent !== ConsentStatus.PENDING) {
              await recordClientTranscriptionConsent(
                message.details.clientConsent === ConsentStatus.OPTED_IN,
              );
            }
            setRequestingClientConsent(false);
            break;
          default:
            break;
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
