import { Toast, ToastVariant } from "../../components";
import toast from "react-hot-toast";
import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons";

export function ClientDeclinedTranscriptionToast({
  id: toastId,
}: {
  id: string;
}) {
  return (
    <Toast
      className="bottom-center-toast sm:max-w-fit"
      variant={ToastVariant.Neutral}
      onClose={() => {
        toast.remove(toastId);
      }}
    >
      <div className="flex flex-row gap-2 items-center">
        <Icon icon={faCircleX} />
        <Text variant="sm">
          Your client does not consent to transcription. Your session will not
          be transcribed.
        </Text>
      </div>
    </Toast>
  );
}
