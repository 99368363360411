import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { Icon, IconButton } from "@grow-therapy-team/sprout-ui";
import { secondsToMilliseconds } from "date-fns";
import { useState } from "react";

export function CopyButton({
  copyText,
  label,
}: {
  copyText: string;
  label?: string;
}) {
  const [copied, setCopied] = useState(false);

  return copied ? (
    <Icon name="check" size="sm" aria-label="Copied" variant="solid" />
  ) : (
    <IconButton
      aria-label={label ? label : "Copy"}
      iconDefinition={faCopy}
      className="inline rebrand:shadow-border-none px-0 py-0"
      onClick={(): void => {
        setCopied(true);
        navigator.clipboard
          .writeText(copyText)
          .then(() =>
            setTimeout(() => setCopied(false), secondsToMilliseconds(1.5)),
          );
      }}
    />
  );
}
