import {
  ButtonUse,
  Link,
  ActionList,
  ActionItem,
} from "@grow-therapy-team/sprout-ui";

import { getAppConfig } from "../../config";

export function NoShowActionList() {
  const marketplaceOrigin = getAppConfig().marketplaceOrigin;

  const supportText = (
    <>
      If you need additional support, please reach out to support via the{" "}
      <Link
        href={`${marketplaceOrigin}/client-dashboard`}
        className="text-neutral-700 rebrand:font-normal text-sm"
      >
        client portal
      </Link>
    </>
  );

  return (
    <div className="flex w-fit max-w-3xl">
      <ActionList
        title="We're here to help when you're ready:"
        footer={supportText}
      >
        <ActionItem
          title="Chat with your provider"
          description="Message your provider with any questions or feedback"
          action={
            <Link
              buttonUse={ButtonUse.Primary}
              href={`${marketplaceOrigin}/client-dashboard/messages`}
              className="w-full"
            >
              Message provider
            </Link>
          }
        />
        <ActionItem
          title="Find someone new"
          description="Find a new provider that meets your needs"
          action={
            <Link
              buttonUse={ButtonUse.Secondary}
              href={`${marketplaceOrigin}/filters?utm_source=telehealth-booking`}
              className="w-full"
            >
              Find new provider
            </Link>
          }
        />
      </ActionList>
    </div>
  );
}
