import {
  StatsigProvider as BaseStatsigProvider,
  StatsigUser,
} from "statsig-react";
import { getCurrentHost, getEnvFromHostName } from "../utils";
import { datadogRum } from "@datadog/browser-rum";

const statsigOptions = {
  environment: {
    tier: getEnvFromHostName(getCurrentHost()),
  },
  // https://docs.datadoghq.com/real_user_monitoring/guide/setup-feature-flag-data-collection/?tab=browser#statsig-integration
  gateEvaluationCallback(key: string, value: boolean) {
    datadogRum.addFeatureFlagEvaluation(key, value);
  },
};

export type StatsigProviderProps = {
  user: StatsigUser;
  children: React.ReactNode;
};
export function StatsigProvider({ user, children }: StatsigProviderProps) {
  return (
    <BaseStatsigProvider
      sdkKey="client-y2YXcN60n7VU4EkzyZABzzKCDe6gCuQtb70uLgf3U1u"
      user={user}
      waitForInitialization
      options={statsigOptions}
    >
      {children}
    </BaseStatsigProvider>
  );
}
