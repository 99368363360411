import { ChatDrawerButton } from "../../components";
import { useEffect } from "react";
import { useChatDrawerButtonUtils } from "./useChatDrawerButtonUtils";
import { setFaviconAndTitleBasedOnUnreadMessagesCount } from "./utils";
import { PROVIDER_NO_SHOW_TITLE } from "../waiting-room/useClearProviderNoShowTabTitle";

export function ChatDrawerButtonWrapper() {
  const { unreadMessagesCount, toggleChatDrawer, isChatDrawerDisabled } =
    useChatDrawerButtonUtils();

  useEffect(
    function showUnreadChatNotificationInFaviconAndTitle() {
      // Provider no show title takes priority over chat notifcations
      if (document.title === PROVIDER_NO_SHOW_TITLE) return;
      setFaviconAndTitleBasedOnUnreadMessagesCount(unreadMessagesCount);
    },
    [unreadMessagesCount],
  );

  return (
    <ChatDrawerButton
      onClick={toggleChatDrawer}
      disabled={isChatDrawerDisabled}
      messageCount={unreadMessagesCount}
    />
  );
}
