import { useAtomValue } from "jotai";
import { LocalTrackState, localVideoTrackAtom } from "../state";
import { usePublishLocalTrackCallback } from "./usePublishLocalTrackCallback";
import { useUnpublishLocalTrackCallback } from "./useUnpublishLocalTrackCallback";
import classNames from "classnames";
import toast from "react-hot-toast";
import { secondsToMilliseconds } from "date-fns";
import { NoAccessToast, NoAccessVariant } from "./NoAccessToast";
import { Toast, ToastVariant, ControlPanelButton } from "../../components";
import { faVideo, faVideoSlash } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@grow-therapy-team/sprout-ui";

export function VideoControl({
  isEnabled,
  loading,
  onToggle,
}: {
  isEnabled?: boolean;
  loading?: boolean;
  onToggle: (enable: boolean) => void;
}) {
  return (
    <Tooltip text={`Toggle video ${isEnabled ? "off" : "on"}`} className="z-0">
      <ControlPanelButton
        aria-label={`Toggle video ${isEnabled ? "off" : "on"}`}
        loading={loading}
        className={classNames({ "rebrand:text-coral-600": !isEnabled })}
        iconDefinition={isEnabled ? faVideo : faVideoSlash}
        data-testid="video-control"
        onClick={() => onToggle(!isEnabled)}
      />
    </Tooltip>
  );
}

export function VideoControlWrapper({
  onOpenHelpModal,
}: {
  onOpenHelpModal?: () => void;
}) {
  const { track: localVideoTrackValue, state: localVideoTrackState } =
    useAtomValue(localVideoTrackAtom);
  const publishLocalTrack = usePublishLocalTrackCallback("video");
  const unpublishLocalTrack = useUnpublishLocalTrackCallback("video");
  const isLoading = localVideoTrackState === LocalTrackState.LOADING;
  const isEnabled =
    localVideoTrackValue && localVideoTrackState === LocalTrackState.READY;

  return (
    <VideoControl
      isEnabled={isEnabled}
      loading={isLoading}
      onToggle={async (enable) => {
        if (enable) {
          if (await publishLocalTrack()) return;
          if (!onOpenHelpModal) return;
          toast.custom(
            (t) => (
              <NoAccessToast
                className="bottom-center-toast"
                onClose={() => toast.remove(t.id)}
                onOpenHelpModal={onOpenHelpModal}
                variant={NoAccessVariant.Camera}
              />
            ),
            {
              duration: secondsToMilliseconds(10),
              position: "bottom-center",
              id: `no-access-${NoAccessVariant.Camera}`,
            },
          );
          return;
        }
        if (await unpublishLocalTrack()) return;
        toast.custom(
          (t) => (
            <Toast
              variant={ToastVariant.Error}
              onClose={() => toast.remove(t.id)}
              className="bottom-center-toast max-w-full w-auto"
            >
              An unexpected error occurred while trying to disable your camera.
            </Toast>
          ),
          {
            duration: secondsToMilliseconds(10),
            position: "bottom-center",
          },
        );
      }}
    />
  );
}
