import { useCallback } from "react";
import { useGetPresignedUrlCallback } from "./useGetPresignedUrlCallback";
import { useAtomCallback } from "jotai/utils";
import { inSessionPatientInformationAtom } from "../state";
import { Mutex } from "async-mutex";
import { logger } from "../../datadog/logger";
import { CallbackFailure, CallbackSuccess } from "../../types";
import { RecordingMetadata } from "./types";

type UploadAudioRecordingToS3Response =
  | CallbackSuccess<Response, UploadAudioCallbackStatusCode.UPLOADED>
  | CallbackFailure<Error, UploadAudioCallbackStatusCode.FAILED_TO_UPLOAD>;

export async function gracefullyUploadAudioRecordingToS3({
  audioBlob,
  presignedUrl,
  patientShortId,
  recordingMetadata,
}: {
  audioBlob: Blob;
  presignedUrl: string;
  patientShortId: string;
  recordingMetadata?: RecordingMetadata;
}): Promise<UploadAudioCallbackResponse> {
  try {
    const uploadResponse = await fetch(presignedUrl, {
      method: "PUT",
      body: audioBlob,
    });
    return {
      status: "success",
      code: UploadAudioCallbackStatusCode.UPLOADED,
      value: uploadResponse,
    };
  } catch (error) {
    logger.error(
      "Failed to upload audio recording to S3",
      {
        recordingMetadata,
        patientShortId,
      },
      error as Error,
    );
    return {
      status: "failure",
      code: UploadAudioCallbackStatusCode.FAILED_TO_UPLOAD,
      value: error as Error,
    };
  }
}

const uploadAudioMutex = new Mutex();

export enum UploadAudioCallbackStatusCode {
  UPLOADED = "uploaded",
  MISSING_PATIENT_SHORT_ID = "missing-patient-short-id",
  FAILED_TO_FETCH_PRE_SIGNED_URL = "failed-to-fetch-pre-signed-url",
  FAILED_TO_UPLOAD = "failed-to-upload",
  SKIPPED = "skipped",
}

type UploadAudioCallbackResponse =
  | UploadAudioRecordingToS3Response
  | CallbackFailure<
      null,
      UploadAudioCallbackStatusCode.MISSING_PATIENT_SHORT_ID
    >
  | CallbackSuccess<null, UploadAudioCallbackStatusCode.SKIPPED>
  | CallbackFailure<
      Error,
      UploadAudioCallbackStatusCode.FAILED_TO_FETCH_PRE_SIGNED_URL
    >;

/**
 * Returns a thread safe callback that uploads an audio blob to S3.
 */
export function useUploadAudioCallback() {
  const getPresignedUrl = useGetPresignedUrlCallback();
  const getPatientShortId = useAtomCallback(
    (get) => get(inSessionPatientInformationAtom)?.patientShortId,
  );

  return useCallback(
    async ({
      audioBlob,
      patientShortId: givenPatientShortId,
      recordingMetadata,
    }: {
      audioBlob: Blob;
      patientShortId?: string;
      recordingMetadata?: RecordingMetadata;
    }): Promise<UploadAudioCallbackResponse> => {
      let patientShortId: string | undefined;
      return await uploadAudioMutex.runExclusive(async () => {
        patientShortId = givenPatientShortId ?? getPatientShortId();
        if (!patientShortId) {
          logger.error("Missing patient short ID", {
            audioRecordingMetadata: recordingMetadata,
          });
          return {
            status: "failure",
            code: UploadAudioCallbackStatusCode.MISSING_PATIENT_SHORT_ID,
            value: null,
          };
        }
        const presignedUrlResponse = await getPresignedUrl(patientShortId);
        if (presignedUrlResponse.status === "failure") {
          logger.error("Failed to fetch pre-signed S3 URL", {
            patientShortId,
            audioRecordingMetadata: recordingMetadata,
          });
          return {
            ...presignedUrlResponse,
            code: UploadAudioCallbackStatusCode.FAILED_TO_FETCH_PRE_SIGNED_URL,
          };
        }

        logger.debug("Uploading audio recording to S3", {
          audioBlobUrl: URL.createObjectURL(audioBlob),
          audioRecordingMetadata: recordingMetadata,
        });
        const presignedUrl = presignedUrlResponse.value.getPresignedUrl?.url;
        if (!presignedUrl) {
          return {
            status: "success",
            code: UploadAudioCallbackStatusCode.SKIPPED,
            value: null,
          };
        }

        return await gracefullyUploadAudioRecordingToS3({
          audioBlob,
          presignedUrl,
          recordingMetadata,
          patientShortId,
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
