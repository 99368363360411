import {
  Button,
  ButtonUse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@grow-therapy-team/sprout-ui";
import {
  VisitorDrawerState,
  providerIsOnlineAtom,
  visitorDrawerStateAtom,
  visitorIsHelpModalOpenAtom,
} from "../state";
import { useAtomCallback } from "jotai/utils";
import { useState, useCallback, useEffect } from "react";
import { useRecordProviderNoShowReportCallback } from "./useRecordProviderNoShowReportCallback";
import { useAtomValue } from "jotai";
import { useChatDrawerButtonUtils } from "../chat/useChatDrawerButtonUtils";
import { useClearProviderNoShowTabTitleCallback } from "./useClearProviderNoShowTabTitle";

export function ProviderNotHereModal({
  isOpen,
  onClose,
  onReportProviderNoShow,
  onMessageProvider,
  isProviderOnline,
}: {
  isOpen: boolean;
  onClose: () => void;
  onReportProviderNoShow: () => void;
  isProviderOnline: boolean;
  onMessageProvider: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Is your provider not here? Let us know.</ModalHeader>
      <ModalBody aria-live="polite">
        {isProviderOnline
          ? "Your provider appears to be online but hasn't admitted you to the session. Please message your provider to be admitted."
          : "We noticed your provider hasn't shown up and it's past their 10-minute grace period. Let us know and we will guide you through next steps."}
      </ModalBody>
      <ModalFooter
        actions={
          <div
            className={
              "flex sm:flex-row flex-col gap-4 justify-center w-full sm:justify-end"
            }
          >
            <Button onClick={onClose} use={ButtonUse.Secondary}>
              Continue waiting
            </Button>
            <Button
              onClick={
                isProviderOnline ? onMessageProvider : onReportProviderNoShow
              }
              use={ButtonUse.Primary}
              aria-live="polite"
            >
              {isProviderOnline
                ? "Message provider"
                : "Yes, my provider isn't here"}
            </Button>
          </div>
        }
      />
    </Modal>
  );
}

export function ProviderNotHereModalWrapper() {
  const [isProviderNotHereModalOpen, setIsProviderNotHereModalOpen] =
    useState<boolean>(false);

  const { isPastNoShowThreshold, recordProviderNoShowAndRedirect } =
    useRecordProviderNoShowReportCallback();
  const { toggleChatDrawer } = useChatDrawerButtonUtils();
  const clearProviderNoShowTabTitle = useClearProviderNoShowTabTitleCallback();

  const getIsHelpModalOpen = useAtomCallback(
    useCallback((get) => get(visitorIsHelpModalOpenAtom), []),
  );
  const isProviderOnline = useAtomValue(providerIsOnlineAtom);
  const getVisitorDrawerState = useAtomCallback(
    useCallback((get) => get(visitorDrawerStateAtom), []),
  );

  useEffect(
    function popModalWhenNoShowThresholdIsMet() {
      if (isPastNoShowThreshold && !getIsHelpModalOpen()) {
        setIsProviderNotHereModalOpen(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPastNoShowThreshold, getIsHelpModalOpen],
  );

  return (
    <ProviderNotHereModal
      isOpen={isProviderNotHereModalOpen}
      onClose={() => {
        clearProviderNoShowTabTitle();
        setIsProviderNotHereModalOpen(false);
      }}
      onReportProviderNoShow={recordProviderNoShowAndRedirect}
      onMessageProvider={() => {
        clearProviderNoShowTabTitle();
        if (getVisitorDrawerState() !== VisitorDrawerState.CHAT) {
          toggleChatDrawer();
        }
        setIsProviderNotHereModalOpen(false);
      }}
      isProviderOnline={!!isProviderOnline}
    />
  );
}
