import {
  Alert,
  AlertUse,
  Icon,
  InputControl,
  Link,
  Radio,
  RadioGroup,
  Text,
  TimeRangeInput,
} from "@grow-therapy-team/sprout-ui";
import {
  AppointmentLocation,
  AppointmentType,
  RecurringAppointmentGrouping,
} from "../schedule-preview/types";
import { EditAppointmentFormValues } from "./EditAppointmentDrawer";
import { getBrowserTimezoneText, getRecurringAppointmentText } from "./utils";
import {
  CALENDAR_LINK_TEXT,
  IN_OFFICE_LABEL,
  VIRTUAL_LABEL,
} from "./constants";
import { RecurrenceInputWrapper as RecurrenceInput } from "./RecurrenceInput";

interface ScheduleAppointmentFormProps {
  formValues: EditAppointmentFormValues;
  onUpdateValues: (values: Partial<EditAppointmentFormValues>) => void;
  appointmentType: AppointmentType;
  recurringAppointmentGrouping?: RecurringAppointmentGrouping | null;
  conflictMessage?: string;
  timeInputErrorMessage?: string;
  isRecurrenceEditable?: boolean;
  calendarLink?: string;
}

export function ScheduleAppointmentForm({
  formValues,
  onUpdateValues,
  appointmentType,
  recurringAppointmentGrouping,
  conflictMessage,
  timeInputErrorMessage,
  isRecurrenceEditable,
  calendarLink,
}: ScheduleAppointmentFormProps) {
  const { startDate, endDate } = formValues;

  return (
    <form>
      <div className="pb-2">
        <InputControl
          className="mb-1"
          icon="clock"
          iconClassName="text-lilac-700"
          required
          aria-label="Appointment time"
          error={timeInputErrorMessage}
        >
          <TimeRangeInput
            data-testid="schedule-event-time"
            minDate={new Date()}
            onUpdateValues={onUpdateValues}
            values={{
              startDate,
              endDate,
            }}
            includeEndDate={appointmentType !== AppointmentType.Consultation}
            preserveDuration
            disableManualEntry
          />
        </InputControl>
        <div className="px-8 text-sm text-neutral-600">
          {getBrowserTimezoneText()}
        </div>
      </div>
      {calendarLink && (
        <div className="flex items-center gap-4">
          <Link
            href={calendarLink}
            size="sm"
            className="text-center w-full"
            target="_blank"
          >
            {CALENDAR_LINK_TEXT}
          </Link>
        </div>
      )}
      <div className="flex items-center gap-4 mb-4 pt-4">
        {isRecurrenceEditable ? (
          <RecurrenceInput
            values={formValues}
            onUpdateValues={onUpdateValues}
          />
        ) : (
          <>
            <Icon name="repeat" className="text-lilac-700" />
            <Text variant="sm">
              {getRecurringAppointmentText(recurringAppointmentGrouping)}
            </Text>
          </>
        )}
      </div>
      <InputControl
        icon="location-dot"
        iconClassName="text-lilac-700"
        required
        aria-label="Appointment location"
      >
        <RadioGroup
          onChange={({ target: { value } }): void =>
            onUpdateValues({
              appointmentLocation: value as AppointmentLocation,
            })
          }
          name="location"
          value={formValues.appointmentLocation ?? AppointmentLocation.Virtual}
        >
          <Radio label={VIRTUAL_LABEL} value={AppointmentLocation.Virtual} />
          <Radio label={IN_OFFICE_LABEL} value={AppointmentLocation.InOffice} />
        </RadioGroup>
      </InputControl>
      {conflictMessage && (
        <Alert use={AlertUse.Warning} icon={null}>
          {conflictMessage}
        </Alert>
      )}
    </form>
  );
}
