import { NewMessageToast } from "../../components/NewMessageToast";
import { Message } from "@twilio/conversations";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useRef } from "react";
import {
  chatParticipantsByIdAtom,
  twilioIdentityAtom,
  unreadMessageCountByConversationSidAtom,
  localScreenShareTrackAtom,
} from "../../twilio/state";
import { logger } from "../../datadog/logger";
import { chatParticipantToName } from "../../twilio/utils";
import { useOpenConversationCallback } from "./useOpenConversationCallback";
import { ChatDrawerState, DrawerState, drawerStateAtom } from "../state";
import { toast } from "react-hot-toast";
import { minutesToMilliseconds } from "date-fns";
import { omit } from "remeda";
import { NewWaitingRoomMessageToast } from "./NewWaitingRoomMessageToast";
import { MessageSuccessToast } from "./MessageSuccessToast";
import { useSetAtom } from "jotai";
import { TrackingEvents, sendLoggingEvents } from "../../events";

export function useShowNewMessageToastCallback() {
  const setUnreadMessageCount = useSetAtom(
    unreadMessageCountByConversationSidAtom,
  );
  const getChatParticipants = useAtomCallback(
    useCallback((get) => get(chatParticipantsByIdAtom), []),
  );
  const openConversation = useOpenConversationCallback();
  const getDrawerState = useAtomCallback(
    useCallback((get) => get(drawerStateAtom), []),
  );
  const getTwilioIdentity = useAtomCallback(
    useCallback((get) => get(twilioIdentityAtom), []),
  );

  const getIsLocalScreenShareMode = useAtomCallback(
    useCallback((get) => get(localScreenShareTrackAtom), []),
  );

  const toastsByConversationSidRef = useRef<Record<string, string>>({});
  const removeToast = (conversationSid: string, givenToastId?: string) => {
    const toastId =
      givenToastId ?? toastsByConversationSidRef.current[conversationSid];
    if (!toastId) return;
    toast.remove(toastId);
    toastsByConversationSidRef.current = omit(
      toastsByConversationSidRef.current,
      [conversationSid],
    );
  };

  return useCallback(
    (message: Message, conversationKind: ChatDrawerState) => {
      const isConversationOpen = getDrawerState() === conversationKind;
      const isSelf = message.author === getTwilioIdentity();
      const isLocalScreenShareMode = getIsLocalScreenShareMode();

      if (isSelf || isConversationOpen || !!isLocalScreenShareMode) return;

      const participants = getChatParticipants();
      const participant = message.author ? participants[message.author] : null;
      const conversationSid = message.conversation.sid;
      let senderName = "New message";
      if (participant) {
        senderName = chatParticipantToName(participant);
      } else {
        const { author, sid } = message;
        logger.error("Could not find participant for message", {
          author,
          messageSid: sid,
          conversationSid,
          chatParticipantSids: Object.keys(participants),
        });
      }

      const existingUnreadMessageToastForConversation =
        toastsByConversationSidRef.current[conversationSid];
      if (existingUnreadMessageToastForConversation) {
        removeToast(conversationSid);
      }

      const showWaitingRoomToast = () =>
        toast.custom(
          ({ id: toastId }) => (
            <NewWaitingRoomMessageToast
              className={"bottom-left-toast"}
              senderName={senderName}
              messageContent={message.body ?? ""}
              onClose={() => {
                removeToast(conversationSid, toastId);
              }}
              onOpenChatDrawer={() => {
                toast.remove();
                openConversation(conversationKind, message.conversation.sid);
              }}
              onSend={(newMessage) =>
                message.conversation.sendMessage(newMessage).then(() => {
                  sendLoggingEvents(TrackingEvents.ANY_MESSAGE_SEND);
                  sendLoggingEvents(
                    TrackingEvents.ANY_WAITING_ROOM_MESSAGE_SEND,
                  );
                  setUnreadMessageCount((prev) => ({
                    ...prev,
                    [conversationSid]: 0,
                  }));
                  toast.remove(toastId);
                  toast.custom(
                    ({ id: successToastId }) => (
                      <MessageSuccessToast
                        className={`bottom-left-toast`}
                        senderName={senderName}
                        onClose={() => toast.remove(successToastId)}
                      />
                    ),
                    {
                      position: "bottom-left",
                    },
                  );
                })
              }
            />
          ),
          {
            position: "bottom-left",
            duration: minutesToMilliseconds(1),
          },
        );

      const toastId =
        conversationKind === DrawerState.WAITING_ROOM_CHAT
          ? showWaitingRoomToast()
          : toast.custom(
              ({ id: toastId }) => (
                <NewMessageToast
                  className={"bottom-center-toast hover:cursor-pointer"}
                  senderName={senderName}
                  messageContent={message.body ?? ""}
                  onClick={() => {
                    toast.remove();
                    openConversation(
                      conversationKind,
                      message.conversation.sid,
                    );
                  }}
                  onClose={() => {
                    removeToast(conversationSid, toastId);
                  }}
                />
              ),
              { position: "bottom-center", duration: minutesToMilliseconds(1) },
            );
      toastsByConversationSidRef.current = {
        ...toastsByConversationSidRef.current,
        [conversationSid]: toastId,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
