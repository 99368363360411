import { Icon, IconProps } from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";

export type IconBadgeProps = {
  className?: string;
  iconClassName?: string;
} & IconProps;

export function IconBadge({
  className,
  iconClassName,
  ...iconProps
}: IconBadgeProps) {
  return (
    <div
      className={twMerge(
        "bg-neutral-000 rounded-full p-3 flex justify-center items-center",
        className,
      )}
    >
      <Icon
        size="lg"
        className={twMerge("text-inherit", iconClassName)}
        {...iconProps}
      />
    </div>
  );
}
