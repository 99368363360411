import {
  Modal,
  ModalBody,
  Text,
  Button,
  Heading,
  ButtonUse,
} from "@grow-therapy-team/sprout-ui";
import HandHoldingPencil from "../../assets/images/hand-holding-pencil.svg?react";
import { ConsentModalText } from "./constants";

type ConsentModalProps = {
  declineConsent: () => void;
  recordConsent: () => void;
  isOpen: boolean;
};

export function ConsentModal({
  declineConsent,
  recordConsent,
  isOpen,
}: ConsentModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      className="shadow-none sm:max-w-[31rem] md:max-w-[31rem] sm:top-[25%]"
    >
      <ModalBody className="flex flex-col items-center gap-8 p-8 text-center">
        <HandHoldingPencil />
        <div className="flex flex-col gap-2 items-center">
          <Heading variant="md">{ConsentModalText.HEADING}</Heading>
        </div>
        <div className="w-full flex  flex-col gap-4 items-center text-center">
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 w-full">
            <Button
              onClick={declineConsent}
              use={ButtonUse.Secondary}
              className="w-full"
            >
              {ConsentModalText.DONT_ENABLE_BUTTON}
            </Button>
            <Button
              onClick={recordConsent}
              use={ButtonUse.Primary}
              className="w-full m-0"
            >
              {ConsentModalText.ENABLE_BUTTON}
            </Button>
          </div>
          <Text variant="sm">{ConsentModalText.FOOTER}</Text>
        </div>
      </ModalBody>
    </Modal>
  );
}
