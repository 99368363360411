import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { DataTrackMessageTypeEnum } from "../../twilio/types";
import { useSendDataTrackMessageCallback } from "../../twilio/messages/useSendDataTrackMessageCallback";
import { useGetProviderPatientConsent } from "./useGetProviderPatientConsent";
import { participantCountAtom } from "../../twilio/state";

export function useSendClientTranscriptionConsents(
  providerShortId?: string,
  patientShortId?: string,
  featureEnabled?: boolean,
) {
  const { providerConsent, patientConsent } = useGetProviderPatientConsent(
    providerShortId,
    patientShortId,
  );
  const sendMessage = useSendDataTrackMessageCallback();
  const numParticipants = useAtomValue(participantCountAtom);

  /**
   * These useEffects make sure visitors get up-to-date consent information
   * We re-send the consents when the number of participants changes so that
   * new participants have access to the consent status
   */
  useEffect(
    function sendClientTranscriptionConsentUpdates() {
      if (!featureEnabled) {
        return;
      }
      sendMessage(
        DataTrackMessageTypeEnum.CLIENT_TRANSCRIPTION_CONSENT_UPDATED,
        {
          clientConsent: patientConsent,
        },
      );
    },
    [patientConsent, sendMessage, numParticipants, featureEnabled],
  );

  useEffect(
    function sendProviderTranscriptionConsentUpdates() {
      if (!featureEnabled) {
        return;
      }
      sendMessage(
        DataTrackMessageTypeEnum.PROVIDER_TRANSCRIPTION_CONSENT_UPDATED,
        {
          providerConsent,
          requestClientConsent: false,
        },
      );
    },
    [providerConsent, sendMessage, numParticipants, featureEnabled],
  );
}
