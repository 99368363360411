import { useAtomValue } from "jotai";
import { activeConversationSidAtom } from "../state";
import { useEffect, useRef } from "react";
import { useConversationCallbacks } from "../chat";
import { useInitTwilioChatClient } from "../../twilio/chat/useInitTwilioChatClient";
import { twilioChatClientAtom, twilioRoomTokenAtom } from "../../twilio/state";

export function useSessionConversation() {
  useInitTwilioChatClient({
    twilioChatToken: useAtomValue(twilioRoomTokenAtom),
  });
  const joinedConversationSidRef = useRef<string>();
  const chatClient = useAtomValue(twilioChatClientAtom);
  const conversationId = useAtomValue(activeConversationSidAtom);
  const { joinConversation, leaveConversation } = useConversationCallbacks();

  useEffect(
    function handleSessionConversation() {
      const hasValidClientAndConvo = chatClient && conversationId;
      const isAlreadyInSessionChat =
        joinedConversationSidRef.current === conversationId;
      if (!hasValidClientAndConvo || isAlreadyInSessionChat) return;

      joinConversation(conversationId);
      joinedConversationSidRef.current = conversationId;

      return function cleanup() {
        if (!hasValidClientAndConvo || !isAlreadyInSessionChat) return;

        leaveConversation(conversationId);
        joinedConversationSidRef.current = undefined;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatClient, conversationId],
  );
}
