import {
  Navbar,
  PageContainer,
  PushDrawerContainer,
  StageContainer,
  ThemeProvider,
} from "../../components";
import { Theme } from "../../state";
import { useClientTwilioRoomListeners } from "./useClientTwilioRoomListeners";
import {
  AudioVideoControlsWrapper as VideoControls,
  isScreenShareModeAtom,
  useHandleDeviceChanges,
} from "../../twilio";
import { SelfVideoThumbnail } from "../SelfVideoThumbnail";
import { LeaveSessionButtonWrapper as LeaveSessionButton } from "./LeaveSessionButton";
import { useNetworkQualityLogging } from "../../twilio/useNetworkQualityLogging";
import { useSessionFocus, nonEssentialElementProps } from "../../hooks";
import { useResetLocalVideoOnScreenOrientationChange } from "../../twilio/useResetLocalVideoOnScreenOrientationChange";
import { useResetVideoOnMobileNavigation } from "../../twilio/useResetVideoOnMobileNavigation";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { SessionGrid } from "../../twilio/session-grid/SessionGrid";
import { ChatDrawerButtonWrapper as ChatDrawerButton } from "../chat/ChatDrawerButton";
import { VisitorDrawerState, visitorDrawerStateAtom } from "../state";
import { PushDrawerWrapper as PushDrawer } from "../PushDrawer";
import { useEffect, useMemo, useState } from "react";
import { HelpModalWrapper as HelpModal } from "../HelpModal";
import { AdditionalOptionsMenuWrapper as AdditionalOptionsMenu } from "../AdditionalOptionsMenu";
import { useLeaveSessionNudgeToast } from "./useLeaveSessionNudgeToast";
import { sendLoggingEvents, TrackingEvents } from "../../events";
import { useSessionConversation } from "./useSessionConversation";
import classNames from "classnames";
import { useSetProviderParticipantData } from "./useSetProviderParticipantData";
import { useUpdateTrackStatsInterval } from "../../twilio/stats/useUpdateTrackStatsInterval";
import { RecordingControl } from "../session-recording/RecordingControl";
import { RemoteParticipant } from "../RemoteParticipant";
import { TranscriptionPillWrapper } from "../session-recording/TranscriptionPill";

function StageEffects() {
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);
  useClientTwilioRoomListeners();
  useHandleDeviceChanges();
  useNetworkQualityLogging({
    onOpenSettings: () => {
      setDrawerState(VisitorDrawerState.SETTINGS);
    },
  });
  useSessionFocus();
  useResetLocalVideoOnScreenOrientationChange();
  useResetVideoOnMobileNavigation();
  useLeaveSessionNudgeToast();
  useSetProviderParticipantData();
  useUpdateTrackStatsInterval();

  return null;
}

function Stage() {
  const isScreenShareMode = useAtomValue(isScreenShareModeAtom);
  const thumbnail = useMemo(
    () => (
      <SelfVideoThumbnail
        showExpandVideoToggle={false}
        videoClassName={classNames({
          "max-w-[15rem] max-h-[8.5rem] min-w-[unset] w-auto h-full [&>video]:relative mb-2 flex-1 border border-neutral-700 rounded-2xl rebrand:border-neutral-800 rebrand:border":
            isScreenShareMode,
        })}
        className={classNames(
          "max-w-[15rem] max-h-[8.5rem] min-w-[unset] w-auto h-full [&>video]:relative mr-2 sm:mr-0 flex-1 border border-neutral-700 rounded-2xl rebrand:border-neutral-800 rebrand:border",
          {
            "flex-1 min-w-[10rem] sm:min-w-[unset] min-h-[7.875rem] w-auto h-full sm:mb-2":
              isScreenShareMode,
          },
        )}
      />
    ),
    [isScreenShareMode],
  );

  return (
    <>
      <StageEffects />
      <StageContainer rounded>
        <SessionGrid
          remoteParticipantComponent={RemoteParticipant}
          thumbnail={thumbnail}
        />
        {!isScreenShareMode && (
          <SelfVideoThumbnail
            className="absolute bottom-2"
            hideSelfViewClassName="bottom-2"
            showSetPositionMenu
            showExpandVideoToggle={false}
          />
        )}
      </StageContainer>
    </>
  );
}

export function Page() {
  useSessionConversation();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const [visitorDrawerState, setVisitorDrawerState] = useAtom(
    visitorDrawerStateAtom,
  );
  const toggleDrawerState = (drawer: VisitorDrawerState): void => {
    if (visitorDrawerState === drawer) {
      setVisitorDrawerState(null);
      return;
    }
    setVisitorDrawerState(drawer);
  };

  useEffect(function sendLoggingEventsOnPageLoad() {
    sendLoggingEvents(TrackingEvents.CLIENT_ENTER_SESSION_ROOM);
  }, []);

  return (
    <ThemeProvider theme={Theme.DARK}>
      <HelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
      />
      <PageContainer>
        <Navbar
          onClickSettingsButton={() =>
            toggleDrawerState(VisitorDrawerState.SETTINGS)
          }
          onClickNeedHelpButton={() => {
            sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
            sendLoggingEvents(TrackingEvents.CLIENT_HELP_REQUEST);
            setIsHelpModalOpen(true);
          }}
          TranscriptionPillComponent={TranscriptionPillWrapper}
          {...nonEssentialElementProps}
        />
        <PushDrawerContainer drawer={<PushDrawer />}>
          <Stage />
        </PushDrawerContainer>
        <VideoControls
          onOpenHelpModal={() => {
            sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
            sendLoggingEvents(TrackingEvents.CLIENT_HELP_REQUEST);
            setIsHelpModalOpen(true);
          }}
          recordingControlComponent={RecordingControl}
          additionalRightControls={<LeaveSessionButton />}
          chatButtonComponent={<ChatDrawerButton />}
          additionalOptionsComponent={
            <AdditionalOptionsMenu
              onClickSettingsButton={() =>
                toggleDrawerState(VisitorDrawerState.SETTINGS)
              }
            />
          }
          {...nonEssentialElementProps}
        />
      </PageContainer>
    </ThemeProvider>
  );
}
