import { gql, useMutation } from "@apollo/client";

const CREATE_TWILIO_PROVIDER_MEETING_ACCESS_MUTATION_GQL = gql`
  mutation CreateTwilioProviderMeetingAccess($patientShortId: String!) {
    createTwilioProviderMeetingAccess(patientShortId: $patientShortId) {
      jwt
    }
  }
`;

type CreateTwilioProviderMeetingAccessMutationData = {
  createTwilioProviderMeetingAccess: {
    jwt: string;
  };
};

type CreateTwilioProviderMeetingAccessVariables = {
  patientShortId?: string;
};

export function useCreateTwilioProviderMeetingAccessMutation() {
  return useMutation<
    CreateTwilioProviderMeetingAccessMutationData,
    CreateTwilioProviderMeetingAccessVariables
  >(CREATE_TWILIO_PROVIDER_MEETING_ACCESS_MUTATION_GQL);
}
