import { Alert, AlertUse } from "@grow-therapy-team/sprout-ui";

export function ProviderReadyToSeeYouCard({
  countdown,
}: {
  countdown?: string | null;
}) {
  return (
    countdown && (
      <Alert
        title="Your provider is ready to see you!"
        use={AlertUse.InfoPassive}
      >
        Automatically transferring in {countdown}
      </Alert>
    )
  );
}
