import { useAtom, useSetAtom, useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { currentConversationSidAtom } from "../../twilio/state";
import {
  drawerStateAtom,
  sessionConversationSidAtom,
  DrawerState,
  waitingVisitorsAtom,
  sessionConversationReadyAtom,
  totalUnreadMessageCountAtom,
} from "../state";
import { useOpenConversationCallback } from "./useOpenConversationCallback";

export function useChatDrawerButtonUtils() {
  const [drawerState, setDrawerState] = useAtom(drawerStateAtom);
  const setCurrentConversationSid = useSetAtom(currentConversationSidAtom);

  const getSessionConversationSid = useAtomCallback(
    useCallback((get) => get(sessionConversationSidAtom), []),
  );
  const getSessionConversationReady = useAtomCallback(
    useCallback((get) => get(sessionConversationReadyAtom), []),
  );
  const getWaitingVisitors = useAtomCallback(
    useCallback((get) => get(waitingVisitorsAtom), []),
  );
  const unreadMessageCount = useAtomValue(totalUnreadMessageCountAtom);
  const openConversation = useOpenConversationCallback();

  const toggleChatDrawer = (): void => {
    const sessionConversationSid = getSessionConversationSid();
    const sessionConversationReady = getSessionConversationReady();
    const waitingVisitors = getWaitingVisitors();
    const numWaitingVisitors = Object.values(waitingVisitors).length;
    const chatDrawerIsOpen =
      drawerState === DrawerState.SESSION_CHAT ||
      drawerState === DrawerState.ALL_CLIENTS_CHAT_LIST ||
      drawerState === DrawerState.WAITING_ROOM_CHAT;
    //close drawer and reset current conversation if drawer is open
    if (chatDrawerIsOpen) {
      setDrawerState(null);
      setCurrentConversationSid(undefined);
      return;
    }
    // open session chat if there is a session conversation
    if (sessionConversationReady) {
      openConversation(DrawerState.SESSION_CHAT, sessionConversationSid);
    } else if (numWaitingVisitors > 1) {
      // open all clients chat if there are multiple waiting visitors but no session conversation
      setDrawerState(DrawerState.ALL_CLIENTS_CHAT_LIST);
    } else if (numWaitingVisitors === 1) {
      // open waiting room chat if there is one waiting visitor but no session conversation
      openConversation(
        DrawerState.WAITING_ROOM_CHAT,
        Object.values(waitingVisitors)[0].waitingRoomConversationSid,
      );
    } else {
      setDrawerState(DrawerState.WAITING_ROOM_CHAT);
    }
  };

  return {
    unreadMessageCount,
    toggleChatDrawer,
  };
}
