import Logo from "../assets/grow-logo.svg?react";
import LogoLight from "../assets/grow-logo-light.svg?react";
import { useTheme } from "../hooks";
import { Theme } from "../state";

export function GrowLogo() {
  const { theme } = useTheme();

  return theme === Theme.DARK ? (
    <LogoLight
      className="block"
      data-testid="grow-logo-light"
      title="Grow Therapy"
    />
  ) : (
    <Logo className="block" data-testid="grow-logo" title="Grow Therapy" />
  );
}
