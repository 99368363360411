import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { Toast, ToastProps, ToastVariant } from "../../components";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";

export function ConsentDeclinedToast(props: Omit<ToastProps, "children">) {
  return (
    <Toast
      className="bottom-center-toast sm:max-w-fit"
      variant={ToastVariant.Neutral}
      {...props}
    >
      <div className="flex flex-row gap-2 items-center">
        <Icon icon={faCircleCheck} />
        <Text variant="sm">
          We&apos;ve let your provider know and saved your consent setting.
        </Text>
      </div>
    </Toast>
  );
}
