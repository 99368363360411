import {
  Button,
  ButtonUse,
  DrawerBody,
  IconBadge,
  IconBadgeUse,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { DrawerHeader } from "../../../components/DrawerHeader";
import { useSetAtom } from "jotai";
import { DrawerState, drawerStateAtom } from "../../state";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

export function PendingFormsCompletedDrawer() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const goToClientInfoDrawer = () =>
    setDrawerState(DrawerState.CLIENT_INFORMATION);

  return (
    <>
      <DrawerHeader>
        <Text className="font-medium" variant="lg" as={"h4"}>
          Measures complete
        </Text>
      </DrawerHeader>
      <DrawerBody>
        <div className="flex flex-col gap-8">
          <div className="w-full flex gap-4">
            <IconBadge use={IconBadgeUse.Green} icon={faCheck} />
            <Text>
              Your client will receive an invitation periodically to re-assess
              their symptoms.
            </Text>
          </div>
          <Button
            className="self-center"
            onClick={goToClientInfoDrawer}
            use={ButtonUse.SecondaryTransparent}
          >
            Return to client info
          </Button>
        </div>
      </DrawerBody>
    </>
  );
}
