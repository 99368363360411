import { useAtomValue } from "jotai";
import { localScreenShareTrackAtom } from "..";
import useScreenShareCallbacks from "./useScreenShareCallbacks";
import {
  Button,
  ButtonSize,
  ButtonUse,
  Icon,
  Text,
} from "@grow-therapy-team/sprout-ui";

export function StopScreenShareButton({ onClick }: { onClick: () => void }) {
  return (
    <Text
      className="flex z-10 w-full text-neutral-100 justify-center items-center pb-2"
      variant="sm"
    >
      <span className="mr-2">You are sharing screen</span>
      <Button
        onClick={onClick}
        use={ButtonUse.Destructive}
        size={ButtonSize.Small}
        className={
          "flex bg-ruby-500 text-neutral-000 shadow-none hover:bg-[#a0121a] min-w-[8.5rem]"
        }
      >
        <Icon name="square-xmark" variant="regular" className="pr-2 pt-1" />
        Stop sharing
      </Button>
    </Text>
  );
}

export function StopScreenShareButtonWrapper() {
  const isSharing = !!useAtomValue(localScreenShareTrackAtom);
  const { stopScreenShare } = useScreenShareCallbacks();

  if (!isSharing) return null;

  return <StopScreenShareButton onClick={stopScreenShare} />;
}
