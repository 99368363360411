import {
  HideSelfViewButton,
  VideoThumbnail,
  VideoThumbnailProps,
} from "../../components";
import {
  LocalTrackState,
  isScreenShareModeAtom,
  localAudioTrackAtom,
  localVideoTrackAtom,
  thumbnailPositionAtom,
} from "../state";
import { VideoHTMLAttributes, useEffect, useRef, useState } from "react";
import { useAtomValue } from "jotai";
import { useStoredAudioVideoSettings } from "../useStoredAudioVideoSettings";
import { deviceIsMobile, isElementVisible } from "../../utils";
import { isSpeakingAtom } from "../stats";

function Video(props: VideoHTMLAttributes<HTMLVideoElement>) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const localVideoTrackValue = useAtomValue(localVideoTrackAtom)?.track;

  useEffect(
    function handleTrackAttachment() {
      const element = videoRef.current;
      if (!localVideoTrackValue || !element) return;

      localVideoTrackValue.attach(element);

      return function cleanup() {
        localVideoTrackValue.detach(element);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localVideoTrackValue],
  );

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video ref={videoRef} {...props} />;
}

export type LocalAudioVideoThumbnailProps = Pick<
  VideoThumbnailProps,
  | "className"
  | "name"
  | "isSettingsThumbnail"
  | "pronouns"
  | "showExpandVideoToggle"
  | "helpText"
  | "showSetPositionMenu"
  | "videoClassName"
  | "hideSelfViewClassName"
  | "isSpeaking"
  | "shouldShowHideSelf"
  | "videoOffContent"
>;

export function LocalAudioVideoThumbnail(props: LocalAudioVideoThumbnailProps) {
  const [isVideoHidden, setIsVideoHidden] = useState<boolean>(false);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);
  const { track: localVideoTrackValue, state: localVideoTrackState } =
    useAtomValue(localVideoTrackAtom);
  const { track: localAudioTrackValue, state: localAudioTrackState } =
    useAtomValue(localAudioTrackAtom);
  const isScreenShareMode = useAtomValue(isScreenShareModeAtom);
  const thumbnailPosition = useAtomValue(thumbnailPositionAtom);
  const { storedAudioVideoSettings, setStoredAudioVideoSettings } =
    useStoredAudioVideoSettings();
  const isMirrored = storedAudioVideoSettings?.videoIsMirrored ?? false;
  const isVideoEnabled =
    localVideoTrackValue && localVideoTrackState === LocalTrackState.READY;
  const isAudioEnabled =
    localAudioTrackValue && localAudioTrackState === LocalTrackState.READY;
  const isLoading = localVideoTrackState === LocalTrackState.LOADING;
  const isSelfViewHidden = storedAudioVideoSettings?.selfViewIsHidden ?? false;
  const isMobile = deviceIsMobile();
  const isSpeaking = useAtomValue(isSpeakingAtom);

  useEffect(
    function handleVideoVisibility() {
      if (
        thumbnailContainerRef.current &&
        !isElementVisible(thumbnailContainerRef.current)
      ) {
        setIsVideoHidden(true);
      }
    },
    [thumbnailContainerRef],
  );

  useEffect(function handleVideoVisibilityOnResize() {
    function handleResize() {
      if (
        thumbnailContainerRef.current &&
        !isElementVisible(thumbnailContainerRef.current)
      ) {
        setIsVideoHidden(true);
      } else {
        setIsVideoHidden(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isSelfViewHidden && isVideoEnabled ? (
    <HideSelfViewButton
      isMobile={isMobile}
      className={props.hideSelfViewClassName}
      position={!isScreenShareMode ? thumbnailPosition : undefined}
      isSelfViewHidden={isSelfViewHidden}
      onClick={() =>
        setStoredAudioVideoSettings((settings) => ({
          ...settings,
          selfViewIsHidden: false,
        }))
      }
    />
  ) : (
    <VideoThumbnail
      audioTransmissionEnabled={isAudioEnabled}
      containerRef={thumbnailContainerRef}
      videoTransmissionEnabled={isVideoEnabled && !isVideoHidden}
      videoComponent={Video}
      loadingVideo={isLoading}
      isMirrored={isMirrored}
      isSelfViewHidden={isSelfViewHidden}
      isMobile={isMobile}
      isSpeaking={isSpeaking}
      setIsSelfViewHidden={() =>
        setStoredAudioVideoSettings((settings) => ({
          ...settings,
          selfViewIsHidden: true,
        }))
      }
      {...props}
    />
  );
}
