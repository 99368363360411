import { gql, useMutation } from "@apollo/client";

export enum MeetingAccessEventType {
  GRANTED = "GRANTED",
  DENIED = "DENIED",
}

type AdmitVisitorVariables = {
  conversationSid?: string;
  eventType: MeetingAccessEventType;
  roomName: string;
  visitorName: string;
  visitorUuid: string;
  deadline: Date;
};

type AdmitVisitorData = {
  updateTwilioVisitorMeetingAccess: {
    eventType: MeetingAccessEventType;
    updatedAt: string;
  };
};

export function useAdmitVisitorMutation() {
  return useMutation<AdmitVisitorData, AdmitVisitorVariables>(gql`
    mutation UpdateTwilioVisitorMeetingAccess(
      $conversationSid: String
      $roomName: String!
      $visitorName: String!
      $visitorUuid: String!
      $eventType: MeetingAccessEventType!
      $deadline: DateTime
    ) {
      updateTwilioVisitorMeetingAccess(
        conversationSid: $conversationSid
        roomName: $roomName
        visitorName: $visitorName
        visitorUuid: $visitorUuid
        eventType: $eventType
        deadline: $deadline
      ) {
        eventType
        updatedAt
      }
    }
  `);
}
