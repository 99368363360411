import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconButton,
  Text,
  TextInput,
} from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";
import { CannedMessages, Toast } from "../../components";
import { useState } from "react";
import { deviceIsMobile } from "../../utils";
import { CANNED_MESSAGES, MOBILE_TOAST_CANNED_MESSAGES } from "./constants";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

export function NewWaitingRoomMessageToast({
  senderName,
  messageContent,
  onClose,
  onOpenChatDrawer,
  onSend,
  className,
}: {
  senderName: string;
  messageContent: string;
  onClose: () => void;
  onOpenChatDrawer: () => void;
  onSend: (message: string) => void;
  className?: string;
}) {
  const [messageDraft, setMessageDraft] = useState("");
  const [showCustomReplyTextBox, setShowCustomReplyTextBox] = useState(false);

  return (
    <Toast className={twMerge("p-4 w-full", className)}>
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-row justify-between">
          <button
            className="flex gap-4 w-full align-start"
            onClick={onOpenChatDrawer}
            aria-label="Open chat drawer"
          >
            <Icon
              variant="solid"
              name="message"
              className="text-neutral-800 p-4 bg-lilac-100 rounded-full"
            />
            <div className="flex flex-col">
              <Text
                data-dd-privacy="mask"
                className="grow font-medium text-lilac-700 text-left fs-exclude"
              >
                {senderName}
              </Text>
              <Text
                data-dd-privacy="mask"
                variant="sm"
                className="line-clamp-3	sm:line-clamp-4 text-neutral-800 text-left fs-exclude"
              >
                {messageContent}
              </Text>
            </div>
          </button>
          <IconButton
            data-testid="new-waiting-room-message-toast.close"
            iconDefinition={faXmark}
            size={ButtonSize.Small}
            onClick={onClose}
            aria-label="Close"
            className="rebrand:w-9 rebrand:h-9"
          />
        </div>
        <hr className="w-full text-neutral_rebrand-500" />
        <Text variant="xs" className="uppercase text-neutral-600">
          Send a quick reply
        </Text>
        <div className="flex flex-row gap-2 w-full">
          <CannedMessages
            messages={
              deviceIsMobile() ? MOBILE_TOAST_CANNED_MESSAGES : CANNED_MESSAGES
            }
            onSelect={(cannedMessage: string) => {
              sendLoggingEvents(TrackingEvents.PROVIDER_TEMPLATE_MESSAGE_SEND);
              sendLoggingEvents(TrackingEvents.PROVIDER_QUICK_REPLY_CANNED);
              onSend(cannedMessage);
            }}
          />
          <Button
            disabled={showCustomReplyTextBox}
            size={ButtonSize.Small}
            className="text-small"
            onClick={() => setShowCustomReplyTextBox(true)}
          >
            Custom reply
          </Button>
        </div>
        {showCustomReplyTextBox && (
          <form
            className="flex flex-row gap-2"
            onSubmit={(event) => event.preventDefault()}
          >
            <TextInput
              onChange={(event) => setMessageDraft(event.target.value)}
              // autoFocus is okay in this example because this element will only get focus right after
              // someone clicks on "Custom reply". This gives enough context to an individual
              // about where they are on the screen and why.
              autoFocus // eslint-disable-line  jsx-a11y/no-autofocus
              placeholder="Write a custom reply"
              className="rebrand:bg-neutral-100"
              value={messageDraft}
            />
            <Button
              onClick={() => {
                sendLoggingEvents(TrackingEvents.PROVIDER_MESSAGE_SEND);
                sendLoggingEvents(TrackingEvents.PROVIDER_QUICK_REPLY_CUSTOM);
                onSend(messageDraft);
                setMessageDraft("");
              }}
              type={ButtonType.Submit}
              disabled={!messageDraft.trim()}
            >
              Send
            </Button>
          </form>
        )}
      </div>
    </Toast>
  );
}
